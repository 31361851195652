"use client";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { AnimationResource } from "../../entity/AnimationResource";
import { getUid } from "../../../../utility/videoEditorUtils";
import left_arrow_icon from "../../../../assets/icons/left_arrow.svg";
import { useStore } from "../../../../utility/hooks/UseStore";
import button_icon from "../../../../assets/icons/delete.svg";

export const AnimationsPanel = observer(() => {
  const mainStore = useStore();
  const store = mainStore.videoStore;

  const selectedElement = store.selectedElement;
  const selectedElementAnimations = store.animations.filter((animation) => {
    return animation.targetId === selectedElement?.id;
  });

  const [collapse, setCollapse] = useState("");

  const inAnimationGrp = ["fadeIn", "slideIn", "zoomIn", "bounceIn", "lightspeedIn"];
  const outAnimationGrp = ["fadeOut", "slideOut", "zoomOut", "bounceOut", "lightspeedOut"];


  const handleAddAnimation = (animationType) => {
    const animationCategory = animationType.includes("In") ? "In" : "Out";

    // Check if the animation is already applied
    const existingAnimation = selectedElementAnimations.find((animation) => animation.type === animationType);

    if (existingAnimation) {
        // Remove the animation if it's clicked again
        store.removeAnimation(existingAnimation.id);
        if (collapse === animationType) {
            setCollapse(""); // Collapse the section if it was expanded
        }
        return;
    }

    // Remove existing animation of the same category
    const existingCategoryAnimation = selectedElementAnimations.find(
        (animation) =>
            (animationCategory === "In" && animation.type.includes("In")) ||
            (animationCategory === "Out" && animation.type.includes("Out"))
    );

    if (existingCategoryAnimation) {
        store.removeAnimation(existingCategoryAnimation.id);
    }

    // Define properties conditionally
    const animationProperties =
        ["slideIn", "lightspeedIn", "slideOut", "lightspeedOut"].includes(animationType)
            ? {
                direction: "left",
                useClipPath: false,
                textType: "none",
            }
            : {};

    store.addAnimation({
        id: getUid(),
        type: animationType,
        targetId: selectedElement?.id ?? "",
        duration: 1000,
        properties: animationProperties,
    });
    setCollapse(animationType);

    // Apply logic for "In" and "Out" animations
    if (selectedElement?.timeFrame) {
        const { start, end } = selectedElement.timeFrame;

        if (animationCategory === "In" && start !== undefined) {
            store.setCurrentTimeInMs(start); 
            store.setPlaying(true);

            // Stop playing after 1 second
            setTimeout(() => {
                store.setPlaying(false);
                store.setCurrentTimeInMs(start); // Reset time to the start
            }, 1000);
        }

        if (animationCategory === "Out" && end !== undefined) {
            store.setCurrentTimeInMs(Number(end) - 1000);
            store.setPlaying(true);

            // Stop playing after 1 second
            setTimeout(() => {
                store.setPlaying(false);
                store.setCurrentTimeInMs(Number(end) - 1000);
            }, 1000);
        }
    }
};

  return (
    <>
      <div className=" p-[20px] ">
        <div className="mb-[20px]">
          <div className="flex items-center border-b pb-[10px]">
            <img
              src={left_arrow_icon}
              alt="arrow"
              className="cursor-pointer p-[10px] rounded bg-[#1C1C26]"
              onClick={() => {
                if (store.selectedElement?.type === "text") {
                  store.setSelectedMenuOption("TextPanel");
                } else {
                  store.setSelectedMenuOption("Image Edit Panel");
                }
              }}
            />
            <h1 className="font-Inter font-semibold pl-[14px] text-lg text-[#FFFFFF]">
              Animations
            </h1>
          </div>
        </div>
        <div className="font-Inter font-semibold pl-[14px] tracking-[0.32px] leading-[16.94px] text-[#FFFFFF] mb-[12px]">
          Start
        </div>
        {/* {In Animations */}

        <div>
          {inAnimationGrp.map((animationType) => {
            const selectedElementAnimations = mainStore.videoStore.animations.filter(
              (animation) => animation.targetId === mainStore.videoStore.selectedElement?.id
            );
            const hasAnimation = selectedElementAnimations.some((animation) => animation.type === animationType);
            const animationText = animationType.charAt(0).toUpperCase() + animationType.slice(1).replace(/([A-Z])/g, " $1");

            return (
              <div key={animationType}>
                <div
                  className={`font-[400px] flex items-center justify-between text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasAnimation
                    ? "text-[#FFFFFF] bg-[#3B3B4F]"
                    : "text-[#ABABC0] hover:bg-[#3B3B4F]"
                    } font-Inter cursor-pointer`}
                  onClick={() => handleAddAnimation(animationType)}
                >
                  {animationText}
                  {selectedElementAnimations &&
                    selectedElementAnimations.map((animation) =>
                      animation.type === animationType ? (
                        <button
                          key={animation.id}
                          className="bg-[rgba(0,0,0,.25)] rounded z-10 text-white font-bold text-lg outline-none"
                          onClick={() => store.removeAnimation(animation.id)}
                        >
                          <img src={button_icon} alt={`${animationType} button`} />
                        </button>
                      ) : null
                    )}
                </div>

                {collapse === animationType &&
                  selectedElementAnimations &&
                  selectedElementAnimations.map((animation) =>
                    animation.type === animationType ? (
                      <AnimationResource key={animation.id} animation={animation} />
                    ) : null
                  )}
              </div>
            );
          })}
        </div>

        <br></br>

        <div className="font-Inter font-semibold pl-[14px] tracking-[0.32px] leading-[16.94px] text-[#FFFFFF] mb-[12px]">
          End
        </div>

        {/* {out Animations*/}

        <div>
          {outAnimationGrp.map((animationType) => {
            const selectedElementAnimations = store.animations.filter(
              (animation) => animation.targetId === store.selectedElement?.id
            );
            const hasAnimation = selectedElementAnimations.some(
              (animation) => animation.type === animationType
            );
            const animationText = animationType
              .charAt(0)
              .toUpperCase() + animationType.slice(1).replace(/([A-Z])/g, " $1");

            return (
              <div key={animationType}>
                <div
                  className={`font-[400px] flex items-center justify-between text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasAnimation
                    ? "text-[#FFFFFF] bg-[#3B3B4F]"
                    : "text-[#ABABC0] hover:bg-[#3B3B4F]"
                    } font-Inter cursor-pointer`}
                  onClick={() => handleAddAnimation(animationType)}
                >
                  {animationText}
                  {selectedElementAnimations &&
                    selectedElementAnimations?.map((animation) =>
                      animation.type === animationType ? (
                        <button
                          key={animation.id}
                          className="bg-[rgba(0,0,0,.25)] rounded z-10 text-white font-bold text-lg outline-none"
                          onClick={() => store.removeAnimation(animation.id)}
                        >
                          <img src={button_icon} alt={`${animationType} button`} />
                        </button>
                      ) : null
                    )}
                </div>

                {collapse === animationType &&
                  selectedElementAnimations &&
                  selectedElementAnimations?.map((animation) =>
                    animation.type === animationType ? (
                      <AnimationResource key={animation.id} animation={animation} />
                    ) : null
                  )}
              </div>
            );
          })}
        </div>


      </div>
    </>
  );
});
