import React, { useRef, useState } from 'react';
import { useStore } from '../../../../utility/hooks/UseStore';
import useOutsideClick from '../../../../utility/hooks/useOutsideClick';
import merge_list_icon from '../../../../assets/icons/mergeMenu.svg';
import add_icon from '../../../../assets/icons/add.svg';
import { fontFamilyJson } from '../../../../configs/fontFamily';
import { fontLoader } from '../../../../utility/utils';

const TextInputPanel = ({ setVariableModal }) => {
  const ref = useRef(null);

  const mainStore = useStore();
  const store = mainStore.videoStore;
  const [inputText, setInputText] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);

  useOutsideClick(ref, () => {
    if (popupVisible) {
      setPopupVisible(false);
    }
  });

 const handleBackspace = (e) => {
  if (e.key === 'Backspace') {
    const textarea = e.target;
    const cursorPosition = textarea.selectionStart;

    // Get the text before and after the cursor
    const beforeCursor = inputText.substring(0, cursorPosition);
    const afterCursor = inputText.substring(cursorPosition);

    // Match all {{...}} blocks
    const fullMatch = inputText.match(/{{[^}]*}}/g);
    if (fullMatch) {
      for (let match of fullMatch) {
        const startIndex = inputText.indexOf(match);
        const endIndex = startIndex + match.length;

        // Check if the cursor is inside this block
        if (cursorPosition > startIndex && cursorPosition < endIndex) {
          e.preventDefault(); // Prevent the default backspace behavior

          // Remove the {{...}} block and keep the rest of the text
          const updatedText = inputText.substring(0, startIndex) + inputText.substring(endIndex);

          // Update the input text and caret position
          setInputText(updatedText);

          // Delay to allow React to update the DOM before setting cursor position
          setTimeout(() => {
            textarea.setSelectionRange(startIndex, startIndex);
          }, 0);
          return; // Exit after handling the match
        }
      }
    }

    // Existing logic for handling the block immediately before the cursor
    const blockMatch = beforeCursor.match(/{{[^}]*}}$/);
    if (blockMatch) {
      e.preventDefault(); // Prevent the default backspace behavior

      const startIndex = beforeCursor.lastIndexOf('{{');
      const endIndex = cursorPosition;

      // Remove the {{...}} block and keep the rest of the text
      const updatedText =
        inputText.substring(0, startIndex) + inputText.substring(endIndex);

      // Update the input text and caret position
      setInputText(updatedText);

      // Delay to allow React to update the DOM before setting cursor position
      setTimeout(() => {
        textarea.setSelectionRange(startIndex, startIndex);
      }, 0);
    }
  }
};

  
  const handleAdd = () => {
    if (inputText.length !== 0) {
      // Define the callback function to handle font weight and family updates
      const cb = (name, isWeightAvail, weight) => {
        if (!isWeightAvail) {
          const newWeight = parseInt(weight);
          if (store.editorElement) {
            store.updateFontWeight(store.editorElement, newWeight);
          }
        }

        if (store.editorElement) {
          store.updateFontFamily(store.editorElement, name);
        }
      };
  
      // Find the font object based on the selected font
      const object = fontFamilyJson.find(item => item.name === 'roboto'); // Assuming `selectedFont` holds the current font name
      const weights = object.availableWeights;
  
      // Load the font with the selected font and weight
      fontLoader('roboto', object, weights, 400, cb);
  
      // Define the text configuration
      const textConfig = {
        isFirstTime : true,
        x: 0,
        y: 0,
        text: inputText,
        fontSize: 3,
        textColor: '#000000',
        backgroundColor: 'transparent',
        stroke: '#000000',
        strokeWidth: 0,
        borderRadius: 0,
        paddingX: 2,
        paddingY: 2,
        lineHeight: 1.2,
        charSpacing: 0,
        fontWeight: 400, // Ensure fontWeight is set correctly
        fontStyle: 'normal',
        txtOpacity: 1,
        linkUrl: '',
        fixedWidth: false,
        fontFamily: 'roboto', // Set the font family
        underline: false,
        textAlign: 'center',
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        btnOpacity: 1,
        outline: false,
        outlineWidth: 0,
        created: new Date()?.getTime(),
      };
  
      // Add the text configuration to the store
      setTimeout(() => {
        store.addText(textConfig);
        store.setSelectedMenuOption('TextPanel');
      }, 50);
    }
  };
  
  const handlePopup = (item) => {
    setInputText((prevValue) => {
      const textarea = document.querySelector("textarea");
      if (textarea) {
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const before = prevValue.substring(0, startPos);
        const after = prevValue.substring(endPos, prevValue.length);
  
        // Insert the header text at the cursor position
        const updatedText = before + `{{${item}}}` + after;
  
        // Use setTimeout to ensure DOM updates happen after state update
        setTimeout(() => {
          // Restore the cursor position after the inserted text
          const cursorPosition = startPos + `{{${item}}}`.length;
          textarea.setSelectionRange(cursorPosition, cursorPosition);
          textarea.focus();
        }, 0);
  
        return updatedText;
      }
  
      return prevValue + `{{${item}}}`;
    });
  
    setPopupVisible(false);
  };
  

  const headers = store.singleCampaignData.headers;

  return (
    <div className="p-[10px] 2xl:p-[20px] w-full">
      <div>
        <div className="flex justify-between items-center pb-[10px] 2xl:pb-[20px] relative">
          <h3 className="font-Inter font-medium text-[#ABABC0] text-[14px] ">
            Text
          </h3>
          <div
            className="flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer"
            onClick={() => {
              setPopupVisible(true);
            }}
          >
            <p className="mr-[10px]">{`{{ Variable }}`}</p>
            <img src={merge_list_icon} alt="MergList" />
          </div>
          {popupVisible && (
            <div
              ref={ref}
              className="absolute bg-[#1C1C26] min-w-[100px] p-[10px] gap-[5px] z-50 max-h-[200px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  overflow-auto rounded-[6px] text-[#ABABC0] top-[25px] right-[0px]"
            >
              {!store?.singleCampaignData?.readyToExport && (
                <div
                  onClick={() => {
                    setVariableModal(true);
                    setPopupVisible(false);
                  }}
                  className=" p-[3px] mb-2 text-[#fff] cursor-pointer bg-[#5446d0] text-center rouned-[3px] rounded-[3px] text-[13px]"
                >
                  Add
                </div>
              )}
              {headers &&
                headers?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handlePopup(item.name)}
                    className="p-[5px] cursor-pointer hover:bg-[#3B3B4F] rounded-[3px]"
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          )}
        </div>
        <textarea
          value={inputText}
          onKeyDown={handleBackspace}
          onChange={(e) => setInputText(e.target.value)}
          className="w-full bg-[#3B3B4F] rounded p-[10px] h-[70px] 2xl:h-[100px] text-[#FFFFFF] outline-none"
          placeholder="Please enter text"
        />
        <button
          onClick={handleAdd}
          className={`${
            inputText?.length > 0
              ? ''
              : 'opacity-[0.5] cursor-not-allowed'
          } flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] py-[10px] rounded outline-none mt-[15px] w-[100%]`}
        >
          <img src={add_icon} alt="add" />
          Add Text
        </button>
      </div>
    </div>
  );
};

export default TextInputPanel;
