import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../utility/hooks/UseStore'
import EditableJsonRenderer from './EditableJsonRendrer'
import plus_icon from '../../assets/icons/plus.svg'
import left_arrow_icon from "../../assets/icons/left_arrow.svg";
import APICallLoader from '../../components/ui/common/APILoaderRing'
import { use } from 'react';
import { json, useParams } from 'react-router-dom';
import { ca } from 'date-fns/locale';
import { set } from 'date-fns';

const ConfCamp = ({ closeModel }) => {

    const store = useStore()

    const [landingPages, setLandingPages] = useState([])
    const [currentConf, setCurrentConf] = useState({})
    const [apiCallLoader, setApiCallLoader] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const singleCampaignData = await store.videoStore.getLandingPages()
        const campaignConf = await store.videoStore.getCampaignConfiguration(id)

        setLandingPages(singleCampaignData)
        setCurrentConf(campaignConf)
    }

    // useEffect(() => {
    //     console.log(landingPages, "this is my landing pages ")
    // }, [landingPages])

    const [isOpenView, setOpenView] = useState(false)
    const [dataForEditModel, setDataForEditModel] = useState()


    //     const cssText = `
    //         @keyframes fadeIn {
    //   0% {
    //     opacity: 0;
    //   }
    //   100% {
    //     opacity: 1;
    //   }
    // }

    // /* Apply the animation to the h1 element */
    // .fade-in {
    //   opacity: 0; /* Start hidden */
    //   animation: fadeIn 2s ease-in forwards; /* Adjust timing as needed */
    // }
    //     `


    useEffect(() => {
        
        console.log(dataForEditModel, "THIS IS MY DATA FOR EDIT MODEL")

        if (dataForEditModel && dataForEditModel !== undefined) {
            const styleElement = document.createElement("style");
            styleElement.type = "text/css";
            if (styleElement.styleSheet) {
                styleElement.styleSheet.cssText = dataForEditModel.css;
            } else {
                styleElement.appendChild(document.createTextNode(dataForEditModel.css));
            }
            document.head.appendChild(styleElement);
            return () => {
                document.head.removeChild(styleElement);
            };
        }
    }, [dataForEditModel]);


    const renderJsonToReact = (node) => {

        // console.log(node, "this is my node")

        const { tag, attributes = {}, content = '', children = [] } = node;

        // Convert attributes to React-compatible format
        const reactAttributes = {};
        for (const key in attributes) {
            if (key === 'class') {
                reactAttributes['className'] = attributes[key]; // Convert 'class' to 'className'
            } else {
                reactAttributes[key] = attributes[key];
            }
        }

        // Handle void elements (self-closing)
        const voidElements = ['img', 'input', 'br', 'hr', 'meta', 'link', 'source'];
        if (voidElements.includes(tag)) {
            return React.createElement(tag, { ...reactAttributes, key: Math.random() });
        }

        // Recursively render children for non-void elements
        const childElements = children.map((child) => renderJsonToReact(child));

        return React.createElement(tag, { ...reactAttributes, key: Math.random() }, content || childElements);
    };



    // this function is for apply or select landing page that you want too apply for the campaign
    const selectLandingPageForCampaign = async (landingPageID) => {

        const data = {
            campaignID: id,
            landingPageID: landingPageID
        }

        setApiCallLoader(true)
        const updatedConfig = await store.videoStore.selectLandingPage({ data, setApiCallLoader });
        if (updatedConfig) {
            setCurrentConf(updatedConfig); // Update the state with the new configuration
        }

    }

    const changeLandingPageStatusForCampaign = async (landingPageID) => {
        const newStatus = !isActive; // Calculate the new toggled status

        setIsActive(newStatus);

        const data = {
            campaignID: id,
            landingPageID: landingPageID,
            status: newStatus
        }
        setApiCallLoader(true)
        const updatedConfig = await store.videoStore.changeLandingPageStatus({ data, setApiCallLoader });
        if (updatedConfig) {
            setCurrentConf(updatedConfig); // Update the state with the new configuration
        }

    }

    // save landing page configuration
    const saveLandingPage = async () => {
        const data = {
            campaignID: id,
            landingPageID: currentConf?.landingPage?.landingPageID,
            json: dataForEditModel.json
        }
        setApiCallLoader(true)
        const updatedConfig = await store.videoStore.updateLandingPage(data, setOpenView, setApiCallLoader);
        if (updatedConfig) {
            setCurrentConf(updatedConfig);
            originalJson.current = JSON.stringify(updatedConfig.json);
        }
    }

    const [saveModal, setSaveModal] = useState(false)
    const originalJson = useRef(null);
    useEffect(() => {
        if (dataForEditModel?.json && !originalJson.current) {
            originalJson.current = JSON.stringify(dataForEditModel.json);
        }
    }, [dataForEditModel]);

    // Function to check if there are changes
    const hasUnsavedChanges = () => {
        return JSON.stringify(dataForEditModel?.json) !== originalJson.current;
    };
    const handleBackButton = () => {
        if (hasUnsavedChanges()) {
            setSaveModal(true);
        } else {
            setOpenView(false)
        }
    };

    const [isActive, setIsActive] = useState()

    useEffect(() => {
        if (currentConf) {
            setIsActive(currentConf && currentConf?.landingPage?.isVisiblePlayer)
        }
    }, [currentConf])

    return (
        <>
            {/* API Loader */}

            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>

            {/* Save Modal */}

            {saveModal && (
                <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100]">
                    <div className="w-[100%] h-[100%] relative flex justify-center items-center">
                        <div
                            onClick={() => {
                                setSaveModal(false);
                            }}
                            className="w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]"
                        ></div>
                        <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 z-[1] -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
                            <div className=' flex items-center justify-between'>
                                <h1 className="font-medium text-lg">Save Changes</h1>
                                <img src={plus_icon} className=' cursor-pointer rotate-45' onClick={() => setSaveModal(false)}></img>
                            </div>
                            <p className="text-sm font-medium text-[#ABABC0] py-[15px]">Do you want to save your changes before leaving this page?</p>
                            <div className="flex justify-end items-center text-sm gap-[12px]">
                                <button
                                    onClick={() => {
                                        setSaveModal(false)
                                        setOpenView(false)
                                    }}
                                    className=" border border-[#5446D0] rounded-md px-[20px] min-w-[80px] py-[12px] text-center font-medium"
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => {
                                        saveLandingPage()
                                        setSaveModal(false)
                                    }}
                                    className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] min-w-[80px] text-center font-semibold"
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* this is for showing landing page and show the conf there */}
            {isOpenView &&
                <div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-50 bg-opacity-50 flex justify-center items-center`}>
                    <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                        <div onClick={() => { closeModel(false) }} className='w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]'></div>
                        <div className='relative min-w-[95%] max-w-[95%] 2xl:min-w-[90%] 2xl:max-w-[90%]  px-[0px]  bg-[#1E1E29]  z-[1] rounded-[8px] h-[90%] max-h-[90%]'>
                            <div className='w-[100%] px-3 rounded-t-[8px] absolute z-50 top-0 py-[10px] bg-[#1E1E29] border-b-[2px] shadow-2xl border-[#2c2d3c] flex justify-between items-center'>
                                <div className=' flex items-center gap-x-[10px]'>
                                    <img
                                        src={left_arrow_icon}
                                        alt="arrow"
                                        className="cursor-pointer p-[10px] rounded bg-[#272836]"
                                        onClick={() => {
                                            handleBackButton()
                                        }}
                                    />
                                    <p className='text-[20px] text-white font-Inter font-semibold leading-[24.2px]'>Customize Landing Page</p>
                                </div>
                                <img src={plus_icon} alt='close' className='rotate-[45deg] cursor-pointer' onClick={() => { closeModel(false) }} />
                                {/* <p className='text-[18px] text-white font-inter'></p> */}
                            </div>
                            <div className='w-[100%] h-[100%] grid grid-cols-10'>
                                <div className='col-span-2 border-r border-[#3b3b4f] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  p-[15px] mt-[46px]'>
                                    <EditableJsonRenderer dataChangeHandler={setDataForEditModel} dataForModel={dataForEditModel} jsonStructure={dataForEditModel.json} />
                                    <div className=' bg-[#1E1E29] py-[15px] sticky bottom-[-17px]'>
                                        <button onClick={() => {
                                            if (hasUnsavedChanges()) {
                                                saveLandingPage()
                                            }
                                        }} className='bg-[#5446D0] w-full text-[14px] 2xl:text-[16px] flex justify-center items-center py-[5px] min-h-[40px] col-span-6 rounded font-Inter font-medium text-[#FFFFFF] outline-none'>Save</button>
                                    </div>
                                </div>
                                <div className='col-span-8 mt-[46px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  p-[15px]' >
                                    {dataForEditModel &&
                                        <>
                                            {renderJsonToReact(dataForEditModel.json)}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
            <div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center rounded-[16px] overflow-hidden`}>
                <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                    <div onClick={() => { closeModel(false) }} className='w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]'></div>
                    <div className='relative min-w-[90%] max-w-[90%]  px-[0px]  bg-[#272836]  z-[1] rounded-[8px] h-[90%] max-h-[90%] overflow-hidden'>
                        <div className='w-[100%] px-3 py-[10px] bg-[#17171E] border-b-[2px] border-[#2c2d3c] flex justify-between items-center'>
                            <p className='text-[20px] text-white font-Inter font-semibold leading-[24.2px]'>Campaign Configuration</p>
                            <img src={plus_icon} alt='close' className='rotate-[45deg] cursor-pointer' onClick={() => { closeModel(false) }} />
                            {/* <p className='text-[18px] text-white font-inter'></p> */}
                        </div>
                        <div className='w-[100%] h-[100%] grid grid-cols-10'>
                            <div className='col-span-2 border-r-[2px] border-[#2c2d3c] p-[15px] bg-[#1e1e29]'>
                                <div className='text-[15px] text-white font-Inter bg-[#5446d0] min-h-[40px] rounded-[6px] pl-3 flex items-center '>Landing Pages</div>
                            </div>
                            <div className='col-span-8 p-[15px] bg-[#17171E] overflow-auto pb-[60px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                                <div className='grid grid-cols-12 gap-4 w-[100%]'>
                                    {landingPages?.map((item, i) => {
                                        const shouldShowButton =
                                            currentConf?.landingPage &&
                                            currentConf.landingPage.landingPageID === item._id &&
                                            currentConf.landingPage.status === true;
                                        return (
                                            <div key={`landingPagesPreview${i}`} className='col-span-6 xl:col-span-4 2xl:col-span-3 rounded-[6px]'>
                                                <div className='w-[100%] relative'>
                                                    <img src={item.thumbnail_landing} className='rounded-[6px]'></img>
                                                </div>
                                                <div className='flex mt-[15px] items-center justify-between'>
                                                    <h3 className={`text-[18px] ${shouldShowButton ? 'w-[50%]' : 'w-full'}  truncate  font-medium leading-[21px] text-white`}>{item.title}</h3>
                                                    {shouldShowButton &&
                                                        <div className=' flex items-center gap-[5px]'>
                                                            <div className="text-[12px] font-medium text-[#ABABC0]">Visible in player :</div>
                                                            <div
                                                                className="relative w-[30px] h-[15px] cursor-pointer"
                                                                onClick={() => changeLandingPageStatusForCampaign(item._id)}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id="toggleB"
                                                                    className="sr-only"
                                                                    checked={isActive}
                                                                    readOnly
                                                                />
                                                                <div
                                                                    className={`block bg-[#3B3B4F] w-[30px] h-[15px] rounded-full ${isActive ? "bg-[#5446d0]" : "bg-gray-500"
                                                                        }`}
                                                                ></div>
                                                                <div
                                                                    className={` absolute ${isActive ? "right-[1px]" : "left-[1px] "
                                                                        } top-[1px] bg-white w-[13px] h-[13px] rounded-full transition`}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`flex flex-row-reverse gap-2 2xl:gap-3  mt-[15px]`}>
                                                    <button onClick={() => { selectLandingPageForCampaign(item._id) }} className={`${currentConf?.landingPage?.landingPageID === item._id
                                                        ? currentConf?.landingPage?.status === false
                                                            ? 'bg-[#5446d0]'
                                                            : 'bg-[#FF5364]'
                                                        : 'bg-[#5446d0]'}  text-[14px] 2xl:text-[16px] flex justify-center items-center py-[5px] w-[50%] rounded font-Inter font-medium text-[#FFFFFF] outline-none`}>{currentConf?.landingPage?.landingPageID === item._id
                                                            ? currentConf?.landingPage?.status === false
                                                                ? 'Apply'
                                                                : 'Remove'
                                                            : 'Apply'}</button>
                                                    {shouldShowButton && <button onClick={() => { setDataForEditModel(currentConf.landingPage); setOpenView(true) }} className='bg-[#5446d0] text-[14px] 2xl:text-[16px] flex justify-center items-center py-[5px] w-[50%] rounded font-Inter font-medium text-[#fff] outline-none'>Customize</button>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfCamp