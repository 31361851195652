import React, { useRef, useEffect } from 'react';

function DragableView(props) {
  const ref = useRef({
    div: null,
    isDragging: false,
    initialMouseX: 0,
    initialValue: 0,
    lastRequestAnimationFrameId: null,
  });

  const { current: data } = ref;

  const calculateNewValue = (mouseX) => {
    if (!data.div || !props.total || props.total === 0) return props.value;
    const deltaX = mouseX - data.initialMouseX;
    const deltaValue = (deltaX / data.div.parentElement.clientWidth) * props.total;

    // Constrain the value within valid bounds
    const newValue = Math.max(0, Math.min(props.total, data.initialValue + deltaValue));
    return newValue;
  };

  const handleMouseDown = (event) => {
    if (props.disabled) return;
    data.isDragging = true;
    data.initialMouseX = event.clientX;
    data.initialValue = props.value;

    document.addEventListener('pointermove', handleMouseMove);
    document.addEventListener('pointerup', handleMouseUp);

    event.stopPropagation();
    event.preventDefault();
  };

  const handleMouseMove = (event) => {
    if (!data.isDragging || props.disabled) return;

    const move = () => {
      const newValue = calculateNewValue(event.clientX);
      if (typeof props.onChange === 'function') {
        props.onChange(newValue);
      }
    };

    if (!data.lastRequestAnimationFrameId) {
      data.lastRequestAnimationFrameId = requestAnimationFrame(() => {
        move();
        data.lastRequestAnimationFrameId = null; // Reset for the next frame
      });
    }
  };

  const handleMouseUp = () => {
    if (!data.isDragging) return;
    data.isDragging = false;

    document.removeEventListener('pointermove', handleMouseMove);
    document.removeEventListener('pointerup', handleMouseUp);
  };

  useEffect(() => {
    return () => {
      // Cleanup event listeners and animations
      if (data.lastRequestAnimationFrameId) {
        cancelAnimationFrame(data.lastRequestAnimationFrameId);
      }
      document.removeEventListener('pointermove', handleMouseMove);
      document.removeEventListener('pointerup', handleMouseUp);
    };
  }, []);

  return (
    <div
      ref={(r) => {
        data.div = r;
      }}
      className={`absolute ${props.className}`}
      style={{
        left: `${(props.value / props.total) * 100}%`,
        top: 0,
        bottom: 0,
        ...props.style,
      }}
      onMouseDown={handleMouseDown}
    >
      {props.children}
    </div>
  );
}

export default DragableView;
