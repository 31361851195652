import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from "../../assets/h_logo.png"
import magnifyingplus from "../../assets/magnifying-plus.png"
import magnifyingminus from "../../assets/magnifying-minus.png"
import plus from "../../assets/icons/plus.svg"
import arrow from "../../assets/timeline_arrow.svg"
import { menuOptions } from "../../configs/videoEditorConfig"
import EditorOptions from '../../components/videoEditor/editorOptions/EditorOptions'
import TimeLine from '../../components/videoEditor/timeline/TimeLine'
import { useStore } from '../../utility/hooks/UseStore'
import { localStorageNames } from "../../configs/constants"
import { fabric } from 'fabric'
import { ElementsPanel } from '../../components/videoEditor/editorOptions/ElementsPanel'
import ExcelListModal from '../../components/videoEditor/CommanExcelModal'
import AddExcelModel from '../../components/videoEditor/AddExcel'
import APICallLoader from '../../components/ui/common/APILoaderRing'
import { observer } from 'mobx-react'
import toast from 'react-hot-toast'
import deleteicon from '../../assets/delete_icon.png'
import back_arrow from '../../assets/back_arrow.png'
import { fontFamilyJson } from '../../configs/fontFamily'
import { allFontLoader, decryptData } from '../../utility/utils'
import plus_icon from "../../assets/icons/plus.svg";
import AddVariable from '../../components/videoEditor/AddVariable'
import { speed } from 'animejs'
import ConfCamp from '../../components/videoEditor/ConfCamp'



const { __USER_DATA } = localStorageNames
const Editor = () => {
    // this is for general use
    const navigate = useNavigate()
    const store = useStore()
    const [apiCallLoader, setApiCallLoader] = useState(false)

    const { id } = useParams()
    const [campaignData, setCampaignData] = useState(false)
    const [showTimeline, setShowTimeline] = useState(true)

    // this effect will run when its get params from url and get campaign data
    useEffect(() => {
        if (id !== undefined) {
            getData()
            // store.videoStore.getExcelListOfSingleCampaign({ campaignID: id, setGetExcelListFromStore })
        }
    }, [id])

    const getData = async () => {
        const singleCampaignData = await store.videoStore.getSingleCampaignData({ campaignID: id })
        setCampaignData(singleCampaignData)
    }

    const videoStore = store.videoStore;
    const videoWidth = videoStore.updatedVideoWidth;
    const videoHeight = videoStore.updatedVideoHeight;

    useEffect(() => {
        var parent = document.getElementById('container');
        if (!parent) return;
        const canvas = new fabric.Canvas('canvas', {
            backgroundColor: "#ededed",
            preserveObjectStacking: true,
        })
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor = "#00a0f5";
        fabric.Object.prototype.cornerStyle = "circle";
        fabric.Object.prototype.cornerStrokeColor = "#0063d8";
        fabric.Object.prototype.cornerSize = 10;
        canvas.on("mouse:down", function (e) {
            if (!e.target) {
                videoStore.setSelectedElement(null);
            }
            videoStore.setSelectedMenuOption('NoSelectedItem')
        });
        videoStore.setCanvas(canvas);
        fabric.util.requestAnimFrame(function render() {
            canvas?.renderAll();
            fabric.util.requestAnimFrame(render);
        });
        window.addEventListener('resize', () => {
            videoStore.refreshElements();
        })
    }, [videoStore, videoWidth, videoHeight])


    useEffect(() => {
        setApiCallLoader(true);
    }, [campaignData]);

    // Handle campaignData loading separately
    useEffect(() => {
        const loadFontAndVideo = async () => {
            if (!campaignData || campaignData === false) {
                return;
            }
    
            // Ensure fonts are preloaded first
            await fontPreLoader();
    
            if (campaignData.jsonData?.length > 0) {
                const data = campaignData.jsonData.find(element => element.type === 'video');
                videoStore.addVideo(
                    `${process.env.REACT_APP_MEDIA_URL}${campaignData.videoUrl}`,
                    data?.properties.volume,
                    data?.placement ?? "",
                    data?.timeFrame ?? "",
                    () => {
                        afterLoadFunction();
                        setTimeout(() => {
                            setApiCallLoader(false);
                        }, 500);
                    }
                );
            } else {
                videoStore.addVideo(
                    `${process.env.REACT_APP_MEDIA_URL}${campaignData.videoUrl}`,
                    100, "", "",
                    () => {
                        afterLoadFunction();
                        setTimeout(() => {
                        setApiCallLoader(false);
                        }, 500);
                    }
                );
            }
    
            if (campaignData.animationData) {
                videoStore.animations = [...JSON.parse(campaignData.animationData)];
            }
        };
    
        loadFontAndVideo();
    }, [campaignData]);
    



    const [newImageData, setimageData] = useState("")
    useEffect(() => {
        if (videoStore.selectedElement && videoStore.selectedElement.type === 'image') {
            const element = videoStore.selectedElement
            setimageData(element)
        }
    }, [videoStore.selectedElement])

    const fontPreLoader = async () => {
        const filterData = campaignData?.jsonData?.filter(item => item.type === 'text' || item.type === 'button');
        const totalFonts = [];
    
        // Collect unique font families
        filterData.forEach((item) => {
            if (!totalFonts.includes(item.properties.fontFamily)) {
                totalFonts.push(item.properties.fontFamily);
            }
        });
    
        // Ensure fonts are loaded before proceeding
        for (const font of totalFonts) {
            const fontData = fontFamilyJson.find(f => f.name === font);
            if (fontData && fontData.links?.length) {
                // Assuming allFontLoader is async, wrap in await
                await allFontLoader(0, fontData, fontData?.links?.length, font);
            }
        }
    };
    


    function afterLoadFunction() {
        if (campaignData === undefined || !campaignData.jsonData || campaignData.jsonData?.length <= 1) {
            return
        } else {
            campaignData.jsonData.map((item, i) => {
                if (item.type === 'text') {

                    videoStore.addText({
                        id: item.id,
                        x: item.placement.x,
                        y: item.placement.y,
                        rotation: item.placement.rotation,
                        scaleX: item.placement.scaleX,
                        scaleY: item.placement.scaleY,
                        angle: item.properties.angle,
                        backgroundColor: item.properties.backgroundColor,
                        borderRadius: item.properties.borderRadius,
                        btnOpacity: item.properties.btnOpacity,
                        charSpacing: item.properties.charSpacing,
                        fixedWidth: item.properties.fixedWidth,
                        fontFamily: item.properties.fontFamily,
                        fontSize: item.properties.fontSize,
                        fontWeight: item.properties.fontWeight,
                        lineHeight: item.properties.lineHeight,
                        outline: item.properties.outline,
                        outlineWidth: item.properties.outlineWidth,
                        paddingX: item.properties.paddingX,
                        paddingY: item.properties.paddingY,
                        text: item.properties.text,
                        textAlign: item.properties.textAlign,
                        textColor: item.properties.textColor,
                        txtOpacity: item.properties.txtOpacity,
                        underline: item.properties.underline,
                        start: item.timeFrame.start,
                        end: item.timeFrame.end,
                        updateStart: item.timeFrame.updateStart,
                        updateEnd: item.timeFrame.updateEnd,
                        strokeWidth: item.properties.strokeWidth,
                        stroke: item.properties.stroke,
                        fontStyle: item.properties.fontStyle,
                        created: item.properties.created
                    })
                }
                else if (item.type === 'audio') {
                    videoStore.addtexttospeech(item.properties.src, item.properties.text, item.placement, item.timeFrame, item.properties);
                }
                else if (item.type === 'button') {
                    videoStore.addButton({
                        text: item.properties.text,
                        fontSize: item.properties.fontSize,
                        fontFamily: item.properties.fontFamily,
                        fontWeight: item.properties.fontWeight,
                        textColor: item.properties.textColor,
                        linkUrl: item.properties.linkUrl,
                        backgroundColor: item.properties.backgroundColor,
                        width: item.properties.width,
                        height: item.properties.height,
                        underline: item.properties.underline,
                        fontStyle: item.properties.fontStyle,
                        textAlign: item.properties.textAlign,
                        strokeWidth: item.properties.strokeWidth,
                        stroke: item.properties.stroke,
                        borderRadius: item.properties.borderRadius,
                        alignment: item.properties.alignment,
                        txtOpacity: item.properties.txtOpacity,
                        btnOpacity: item.properties.btnOpacity,
                        charSpacing: item.properties.charSpacing,
                        lineHeight: item.properties.lineHeight,
                        paddingX: item.properties.paddingX,
                        paddingY: item.properties.paddingY,
                        x: item.placement.x,
                        y: item.placement.y,
                        rotation: item.placement.rotation,
                        scaleX: item.placement.scaleX,
                        scaleY: item.placement.scaleY,
                        start: item.timeFrame.start,
                        end: item.timeFrame.end,
                        created: item.properties.created,
                        id: item.properties.id
                    })
                }
                else if (item.type === 'image') {
                    const options = {
                        id: item.id,
                        isVisible: item.isVisible,
                        type: 'image',
                        x: item.placement.x,
                        y: item.placement.y,
                        width: item.placement.width,
                        height: item.placement.height,
                        rotation: item.placement.rotation,
                        scaleX: item.placement.scaleX,
                        scaleY: item.placement.scaleY,
                        start: item.timeFrame.start,
                        end: item.timeFrame.end,
                        UpdateStart: item.timeFrame.UpdateStart,
                        UpdateEnd: item.timeFrame.UpdateEnd,
                        elementId: item.properties.elementId,
                        src: item.properties.src,
                        opacity: item.properties.opacity,
                        created: item.properties.created
                    }
                    videoStore.addImage(options)
                }
            })
        }
    }

    const [excelModel, setExcelModel] = useState(false)
    const [addExcelModel, setAddExcelModel] = useState(false)

    const [getExcelListFromStore, setGetExcelListFromStore] = useState(false)
    const [excelList, seExcelList] = useState([])

    useEffect(() => {
        if (getExcelListFromStore) {
            seExcelList(store.videoStore.ExcelListForSingleCampaign);
            setGetExcelListFromStore(false)
        }
    }, [getExcelListFromStore])

    function onSaveHandler(showMsg) {
        const jsonData = []
        const editor = store.videoStore.editorElements;
        editor.map((item) => {
            if (item.type === "text") {
                const textData = {}
                textData.id = item.id;
                textData.isVisible = item.isVisible;
                textData.type = item.type;
                textData.placement = item.placement;
                textData.timeFrame = item.timeFrame;
                textData.properties = item.properties;
                jsonData.push(textData);

            } else if (item.type === "button") {
                const buttonData = {}
                buttonData.id = item.id;
                buttonData.isVisible = item.isVisible;
                buttonData.type = item.type;
                buttonData.placement = item.placement;
                buttonData.timeFrame = item.timeFrame;
                buttonData.properties = item.properties;
                jsonData.push(buttonData);
            }
            else if (item.type === "video") {
                const videoData = {}
                videoData.id = item.id;
                videoData.isVisible = item.isVisible;
                videoData.type = item.type;
                videoData.placement = item.placement;
                videoData.timeFrame = item.timeFrame;
                videoData.properties = {
                    ...item.properties,
                    volume: store.videoStore.volumeOfVideo
                }
                jsonData.push(videoData);
            }
            else if (item.type === "audio") {
                const speechData = {}
                speechData.id = item.id;
                speechData.isVisible = item.isVisible;
                speechData.type = item.type;
                speechData.placement = item.placement;
                speechData.timeFrame = item.timeFrame;
                speechData.properties = item.properties;
                jsonData.push(speechData);
            } else if (item.type === 'image') {
                const imageData = {}
                imageData.id = item.id;
                imageData.isVisible = item.isVisible;
                imageData.type = item.type;
                imageData.placement = item.placement;
                imageData.timeFrame = item.timeFrame;
                imageData.properties = item.properties;
                jsonData.push(imageData)
            }
        })
        const data = {
            jsonData: jsonData,
            animationData: JSON.stringify(store.videoStore.animations),
            // campaignId: id
        }
        setApiCallLoader(true)
        store.videoStore.JsonUpdate({ campaignId: id, data, setApiCallLoader, showMsg: showMsg })
    }
    function replacePlaceholders(str, replacements) {
        let tempString = str
        replacements.map(item => {
            tempString = tempString.replaceAll(Object.keys(item), item[Object.keys(item)])
        })
        return tempString
    }
    const [xmlVariable, setXmlVariable] = useState([])
    const [inputPopUpForXmlData, setInputPopUpForXmlData] = useState(false)
    const [XmlVariableData, setXmlVariableData] = useState([]);
    function redirectToPreview() {

        localStorage.removeItem("previewData")
        const jsonData = []
        const editor = JSON.parse(JSON.stringify(store.videoStore.editorElements));
        editor.map((item) => {
            if (item.type === "text") {
                const textData = {}
                textData.id = item.id;
                textData.isVisible = item.isVisible;
                textData.type = item.type;
                textData.placement = item.placement;
                textData.timeFrame = item.timeFrame;
                textData.properties = item.properties;
                if (item.properties.text.match(/\{\{(.*?)\}\}/g)) {
                    textData.properties.text = replacePlaceholders(item.properties.text, XmlVariableData)
                }
                jsonData.push(textData);
            } else if (item.type === "button") {
                const buttonData = {}
                buttonData.id = item.id;
                buttonData.isVisible = item.isVisible;
                buttonData.type = item.type;
                buttonData.placement = item.placement;
                buttonData.timeFrame = item.timeFrame;
                buttonData.properties = item.properties;
                jsonData.push(buttonData);
            }
            else if (item.type === "video") {
                const videoData = {}
                videoData.id = item.id;
                videoData.isVisible = item.isVisible;
                videoData.type = item.type;
                videoData.placement = item.placement;
                videoData.timeFrame = item.timeFrame;
                videoData.properties = item.properties;
                jsonData.push(videoData);
            }
            else if (item.type === "audio") {
                const speechData = {}
                speechData.id = item.id;
                speechData.isVisible = item.isVisible;
                speechData.type = item.type;
                speechData.placement = item.placement;
                speechData.timeFrame = item.timeFrame;
                speechData.properties = item.properties;
                speechData.config = {
                    isDynamic: item.properties.text.match(/\{\{(.*?)\}\}/g) ? true : false,
                    text: item.properties.text.match(/\{\{(.*?)\}\}/g) ? replacePlaceholders(item.properties.text, XmlVariableData) : ""
                }
                jsonData.push(speechData);
            } else if (item.type === 'image') {
                const imageData = {}
                imageData.id = item.id;
                imageData.isVisible = item.isVisible;
                imageData.type = item.type;
                imageData.placement = item.placement;
                imageData.timeFrame = item.timeFrame;
                imageData.properties = item.properties;
                jsonData.push(imageData)
            }
        })
        const data = {
            jsonData: jsonData,
            animationData: JSON.stringify(store.videoStore.animations),
            campaignId: id,
            volumeOfVideo: store.videoStore.volumeOfVideo
        }
        localStorage.setItem("previewData", JSON.stringify(data))
        window.open('/video/preview', '_blank')
    }


    function checkExcelVariableUse() {
        let variable = []
        const editor = JSON.parse(JSON.stringify(store.videoStore.editorElements))
        editor.map((item) => {
            if (item.type === "text") {
                if (item.properties.text.match(/\{\{(.*?)\}\}/)) {
                    variable = [...variable, ...item.properties.text.match(/\{\{(.*?)\}\}/g)]
                }
            } else if (item.type === "audio") {
                if (item.properties.text.match(/\{\{(.*?)\}\}/)) {
                    variable = [...variable, ...item.properties.text.match(/\{\{(.*?)\}\}/g)]
                }
            }
        })
        setXmlVariable([...new Set(variable)])
        if ([...new Set(variable)]?.length > 0) {
            setInputPopUpForXmlData(true)
            const matchedItems = store.videoStore?.singleCampaignData?.headers?.filter(obj =>
                variable?.some(key => `{{${obj.name}}}` === key)
            );
            if (matchedItems) {
                // Use the placeholder as the default value
                setXmlVariableData([
                    ...XmlVariableData,
                    ...matchedItems?.map(item => ({ [`{{${item.name}}}`]: item.placeholder }))
                ]);
            }
        } else {
            redirectToPreview()
        }
    }

    function storeValueForVariable(key, value) {
        const keyExists = XmlVariableData.some(item => Object.keys(item)[0] === key);
        if (keyExists) {
            const updatedXmlVariableData = XmlVariableData.map(item => {
                if (Object.keys(item)[0] === key) {
                    return { [key]: value };
                }
                return item;
            });
            setXmlVariableData(updatedXmlVariableData);
        } else {
            setXmlVariableData([...XmlVariableData, { [key]: value }]);
        }
    }
    const [error, setError] = useState({})
    const [runValidatorForPassword, setRunValidatorForPassword] = useState(false)
    function verifyXmlData() {
        const isError = validationForExcelVariable()
        setRunValidatorForPassword(true)
        if (isError) return
        // if (Object.keys(error).length === 0) {
        setRunValidatorForPassword(false)
        setInputPopUpForXmlData(false)
        redirectToPreview()
        setXmlVariableData([])
        // }
    }
    function validationForExcelVariable() {
        let error = {}
        let isError = false
        let XmlVariableDataArray = []
        XmlVariableData.map((item) => { XmlVariableDataArray = [...XmlVariableDataArray, ...Object.keys(item)] })
        xmlVariable.map((item_I) => {
            if (XmlVariableDataArray.includes(item_I)) {
                if (XmlVariableData.some(obj => Object.values(obj).some(value => value?.length < 1))) {
                    if (XmlVariableData.filter(obj => Object.values(obj).some(value => value?.length < 1))?.length > 0) {
                        XmlVariableData.filter(obj => Object.values(obj).some(value => value?.length < 1)).map((item) => {
                            error[Object.keys(item)[0]] = `${Object.keys(item)[0].replaceAll("{{", "").replaceAll("}}", "")} is Required`;
                            isError = true
                        })
                    }
                } else {
                    delete error[item_I]
                    isError = false
                }
            } else {
                error[item_I] = `${item_I.replaceAll("{{", "").replaceAll("}}", "")} is Required`;
                isError = true
            }
        })
        setError(error)
        return isError
    }
    useEffect(() => {
        if (runValidatorForPassword) {
            validationForExcelVariable()
        }
    }, [XmlVariableData])

    const [canvasWidth, setcanvasWidth] = useState(100)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [elementType, setElementType] = useState("")
    const [elementID, setElementId] = useState("")
    const [isDrag, setIsDrag] = useState(false)
    const [saveMOdal, setSaveModal] = useState(false)


    // const isUpperCase = (str) => {
    //     return str === str.toUpperCase();
    //   };

    const userStore = store.userRegister
    const [canGetOpenAIAPIFromStore, SetCanGetOpenAIAPIFromStore] =
        useState(false);
    const [canGetElevenLabAPIFromStore, SetcanGetElevenLabAPIFromStore] = useState(false);
    useEffect(() => {
        if (Object.values(userStore?.userOpenAiAPIKeys).length === 0) {
            userStore.getListOfOpenIaAPI({ SetCanGetOpenAIAPIFromStore })
        }
    }, [userStore])

    useEffect(() => {
        if (Object.values(userStore?.userElvenLabAPIKeys).length === 0) {
            userStore.getListOfElevenLabAPI({ SetcanGetElevenLabAPIFromStore, loader: setApiCallLoader })
        }
    }, [userStore])

    const [variableModal, setVariableModal] = useState(false)

    // this alll code is for campaign configurations model
    const [confCampModel, setConfCampModel] = useState(false)

    return (
        <>
            {inputPopUpForXmlData &&
                <div className='w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]'>
                    <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                        <div onClick={() => { setInputPopUpForXmlData(false) }} className='w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]'></div>
                        <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
                            <div className="w-[500px] bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-[20px]">
                                <div className="flex items-center justify-between">
                                    <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Data For Preview</h1>
                                    <img src={plus} alt="" onClick={() => {
                                        setInputPopUpForXmlData(false)
                                        setError({})
                                    }} className="cursor-pointer rotate-45" />
                                </div>
                                <div className="my-[15px] max-h-[400px] overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
                                    {xmlVariable.map((item, i) =>
                                        <div key={i} className="w-[100%] mb-[20px] relative">
                                            <label
                                                htmlFor="campaign"
                                                className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
                                            >
                                                {item.replaceAll("{{", "").replaceAll("}}", "")} <span className="text-[#FF5364]">*</span>
                                            </label>
                                            <div className=" relative">
                                                <input
                                                    name="excelName"
                                                    className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                                                    placeholder={`Enter ${item.replaceAll("{{", "").replaceAll("}}", "")}`}
                                                    type="text"
                                                    autoComplete="off"
                                                    value={XmlVariableData?.find(obj => Object.keys(obj)[0] === item) ? Object.values(XmlVariableData?.find(obj => Object.keys(obj)[0] === item)) : ""}
                                                    onChange={(e) => {
                                                        storeValueForVariable(item, e.target.value)
                                                    }}
                                                    autoFocus
                                                ></input>
                                                <p className="text-[12px] pt-1 absolute bottom-[-18px] leading-[14.52px] font-medium text-[#FF5364]">
                                                    {error[item] ? 'Field is required.' : ""}
                                                </p>
                                            </div>
                                            {/* {excelNameError?.length > 0 && (
                                       <img
                                           src={exclamation_mark}
                                           alt="*"
                                           className="absolute top-[50px] right-[10px] w-[20px]"
                                       />
                                   )} */}
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-center">
                                    <button onClick={(e) => { verifyXmlData() }} className="bg-[#5446D0] flex justify-center items-center gap-[10px] py-[8px] px-[32px] rounded font-Inter font-semibold text-base text-[#FFFFFF] outline-none" >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
            {/* API Loader */}
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>
            {/* Action on Excel Modal */}
            {excelModel &&
                <ExcelListModal onSaveHandler={onSaveHandler} setExcelModel={setExcelModel} setVariableModal={setVariableModal} setAddExcelModel={setAddExcelModel} setGetExcelListFromStore={setGetExcelListFromStore} excelList={excelList} />
            }
            {/* Add Excel Modal */}
            {addExcelModel &&
                <AddExcelModel setAddExcelModel={setAddExcelModel} setApiCallLoader={setApiCallLoader} setGetExcelListFromStore={setGetExcelListFromStore} />
            }
            {/* Campaign Conf Model */}
            {confCampModel &&
                <ConfCamp closeModel={setConfCampModel}>

                </ConfCamp>
            }

            <div className='w-[100%] h-[100vh] bg-[#2C2D3C]'>
                <p className='font-[POPPINS] hidden'></p>
                {/* navbar code starts */}
                <div className="bg-[#1C1C26] px-[20px] h-[60px] 2xl:h-[80px] flex items-center justify-between border-b border-[#2C2D3C]">
                    <div className="flex items-center ">
                        <div className="text-base font-Inter font-semibold text-white pr-[20px] border-r-[1px] border-[#3B3B4F]">
                            {/* <Link href='/dashboard'> */}
                            <img src={logo} alt="logo" className=" h-[25px] 2xl:h-auto w-auto object-contain 2xl:ml-1" />
                            {/* </Link> */}
                        </div>
                        <p className="font-Inter font-normal text-base text-[#ABABC0] px-[20px] pr-[20px] flex items-center gap-[8px]" >
                            <button onClick={() => setSaveModal(true)} className=' bg-[#272836] group relative cursor-pointer w-[30px] h-[30px] flex items-center justify-center rounded-[4px]'>
                                <img src={back_arrow}></img>
                                <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[110px] top-[40px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Back to home</span>
                            </button>
                            <span className='ml-2'>{campaignData.campaignName}</span>
                        </p>
                    </div>

                    <div className="flex gap-[10px] 2xl:gap-[15px] justify-center items-center">
                        <div className="pr-[10px] border-r-[1px] border-[#272836]">
                            {/* <Link href={'/dashboard/setting'} className="bg-[#5446D0] px-[14px] py-[7px] 2xl:py-[8px] uppercase rounded font-Inter font-semibold text-[14px] 2xl:text-base text-[#FFFFFF] cursor-pointer outline-none">
                                {localStorage.getItem(__USER_DATA) !== null ? JSON.parse(localStorage.getItem(__USER_DATA)).name[0] : "User"}
                            </Link> */}
                        </div>
                        {/* onClick={() => { xmlVariable !== undefined ? checkExcelVariableUse() : handleCreate() }} */}
                        <button onClick={() => { xmlVariable !== undefined ? checkExcelVariableUse() : redirectToPreview() }} className="border border-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center  py-[4px] px-[16px]  rounded font-Inter font-medium text-[#FFFFFF] outline-none">
                            <span>Preview</span>
                        </button>

                        {/* onClick={handleCreate} */}
                        <button onClick={() => { setExcelModel(true) }} className="border cursor-pointer border-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center  py-[4px] px-[16px]  rounded font-Inter font-medium text-[#FFFFFF] outline-none">
                            {/* <img src={export_icon} alt='Export' className='max-2xl:w-[18px] max-2xl:h-[18px]' />  */}
                            Export
                        </button>

                        <button onClick={() => { onSaveHandler(true) }} className="bg-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center py-[5px] px-[16px]  rounded font-Inter font-medium text-[#FFFFFF] outline-none">
                            {/* <img src={save_icon} alt="save" className='max-2xl:w-[18px] max-2xl:h-[18px]' /> */}
                            <span>Save</span>
                        </button>
                    </div>
                </div>
                {/* navbar code end */}
                <div className="w-[100%] h-[calc(100vh-60px)] 2xl:h-[calc(100vh-80px)] flex bg-[#1C1C26]">

                    {/* menu code starts */}
                    <div className="2xl:h-[calc(100vh-80px)] h-[calc(100vh-60px)]">
                        <ul className="bg-[#1C1C26] h-full w-[60px] 2xl:w-[80px]">
                            {menuOptions?.map((item, i) => {
                                return (
                                    <li key={item.name} className={`h-[60px] 2xl:h-[80px] w-[60px] 2xl:w-[80px] flex flex-col items-center justify-center ${item.slug === store.videoStore.selectedMenuOption ? "bg-[#272836]" : ""}`}>
                                        <button onClick={() => {
                                            if (item.name === "Data") {
                                                setExcelModel(true)
                                            } else if (item.slug === "conf") {
                                                setConfCampModel(true)
                                            } else if (item.name === "Video") {
                                                const newElement = store.videoStore.editorElements?.find(item => item?.type === 'video')
                                                store.videoStore.setSelectedElement(newElement)
                                                store.videoStore.setSelectedMenuOption(item.slug)
                                            }
                                            else {
                                                store.videoStore.setSelectedMenuOption(item.slug)

                                            }
                                        }} className={`flex flex-col items-center outline-none`}>
                                            <img src={item.image} alt={item.name} style={{ width: "20px", height: "20px", color: store.videoStore.selectedMenuOption === item.slug ? "#000" : "#444" }} />
                                            <div className={`text-[12px] mt-[2px] 2xl:text-[14px] hover:text-white font-Inter font-medium text-center ${store.videoStore.selectedMenuOption === item.slug ? "text-white" : "text-[#ABABC0]"}`}> {item.name}
                                            </div>
                                        </button>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                    {/* menu code ends */}

                    {/* editor options and canvas start */}
                    <div className=" w-[100%]">
                        <div className={`grid grid-cols-12 ${showTimeline ? "2xl:h-[calc(100vh-250px-80px)] h-[calc(100vh-192px-60px)]" : "2xl:h-[calc(100vh-47px-80px)] h-[calc(100vh-47px-60px)]"}  bg-[#17171E]`}>
                            {/* left editor options side bar start */}
                            <div className="col-span-3 2xl:col-span-2 min-h-[100%] overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent bg-[#272836]">
                                <EditorOptions setVariableModal={setVariableModal} imageData={newImageData} />
                            </div>
                            <div className={`p-[10px] 2xl:p-[30px] col-span-9 2xl:col-span-10 flex justify-center overflow-auto h-[calc(100%-10px)] scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent`}>
                                <div className='w-[100%]' id="container">
                                    <div style={{ width: '100%', height: '100%' }} className=''>
                                        <canvas id="canvas"></canvas>
                                        <div className=" absolute top-0 invisible ">
                                            <ElementsPanel />
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex items-center bg-[#272836] text-[#ababc0] min-h-[30px]  rounded-md absolute ${showTimeline ? `bottom-[190px] 2xl:bottom-[250px]` : 'bottom-[45px]'} right-[45px]`}>

                                    <button className='mx-3 my-[3px] min-w-[20px] ' disabled={store.videoStore.videoSizePercentage <= 20} onClick={() => { store.videoStore.videoZoomHandler(Math.round(store.videoStore.videoSizePercentage) - 1) }}><img src={magnifyingminus} className='w-[18px]' alt='magnifying-minus'></img></button>
                                    <input
                                        onMouseDown={(e) => { setIsDrag(true) }}
                                        onMouseUp={(e) => { setIsDrag(false) }}
                                        onChange={(e) => {
                                            store.videoStore.videoZoomHandler(e.target.value);
                                            setTimeout(() => {
                                                store.videoStore.refreshElements();
                                            }, 100);
                                        }
                                        } type="range" step={1} className={`bg-[#ABABC0] ${isDrag ? "cursor-grabbing" : "cursor-grab"} w-full h-[4px] outline-none range-edge`}
                                        value={Math.round(store.videoStore.videoSizePercentage)}
                                        // value={canvasWidth}
                                        min={20}
                                        max={150}
                                    />
                                    <button disabled={store.videoStore.videoSizePercentage >= 150} className='mx-3 my-[3px] min-w-[20px]' onClick={() => { store.videoStore.videoZoomHandler(Math.round(store.videoStore.videoSizePercentage) + 1) }}><img src={magnifyingplus} className='w-[18px]' alt='magnifying-plus'></img></button>
                                </div>
                            </div>


                            {/* canvas end */}

                        </div>
                        <div className={`bg-[#17171E] w-full showTimeLine transform ${showTimeline ? "h-[185px] 2xl:h-[250px] showTimeLine" : " h-[45px] hideTimeLine"}  flex  border-t border-[#2C2D3C] relative`}>
                            <button className={`bg-[#272836]  outline-none w-[40px] h-[30px] self-end absolute top-[-30px] right-0 rounded-tl-[6px] p-[5px] flex justify-center items-center cursor-pointer`} onClick={() => { setShowTimeline(!showTimeline); store.videoStore.timeLineShowHandler() }}>
                                <img src={arrow} alt='arrow' className={` duration-500 transform ${showTimeline ? "rotate-[180deg]" : "rotate-[0deg]"}`} />
                            </button>
                            <div className="w-[100%]">
                                <TimeLine showTimeline={showTimeline} setOpenDeleteModal={setOpenDeleteModal} setElementType={setElementType} setElementId={setElementId} />
                            </div>
                        </div>
                    </div>
                    {/* editor options and canvas end */}
                </div>
            </div>


            {/*Element Delete Modal */}
            {openDeleteModal && <div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}>
                <div className="w-[100%] h-[100%] relative flex justify-center items-center">
                    <div
                        onClick={() => {
                            setOpenDeleteModal(false);
                        }}
                        className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]"
                    ></div>
                    <div className="w-[400px] p-[20px] bg-[#272836] flex flex-col items-center z-[1] rounded-[8px]">
                        <img src={deleteicon} alt="deleteicon" className=" mx-auto w-[100px] h-auto object-contain"></img>
                        <p className="text-[#ABABC0] my-[20px]  font-Inter font-normal text-[14px] leading-[16.94px] text-center tracking-[0.48px]">Are you sure you want to delete this {elementType}?</p>
                        <div className="flex justify-center items-center text-sm gap-[12px]">
                            <button onClick={() => setOpenDeleteModal(false)} className="border border-[#5446D0] text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                            <button onClick={(e) => {
                                videoStore.removeEditorElement(elementID);
                                videoStore.refreshElements();
                                e.preventDefault();
                                e.stopPropagation();
                                videoStore.setSelectedMenuOption("NoSelectedItem")
                                setOpenDeleteModal(false)
                            }} className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold text-[#fff]">Delete</button>
                        </div>
                    </div>
                </ div>
            </div>}

            {/*Element save Modal */}
            {saveMOdal && (
                <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]">
                    <div className="w-[100%] h-[100%] relative flex justify-center items-center">
                        <div
                            onClick={() => {
                                setSaveModal(false);
                            }}
                            className="w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]"
                        ></div>
                        <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 z-[1] -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
                            <div className=' flex items-center justify-between'>
                                <h1 className="font-medium text-lg">Save Changes</h1>
                                <img src={plus} className=' cursor-pointer rotate-45' onClick={() => setSaveModal(false)}></img>
                            </div>
                            <p className="text-sm font-medium text-[#ABABC0] py-[15px]">Do you want to save your changes before leaving this page?</p>
                            <div className="flex justify-end items-center text-sm gap-[12px]">
                                <button
                                    onClick={() => {
                                        setSaveModal(false)
                                        navigate("/videos")
                                        store.videoStore.setSelectedMenuOption("NoSelectedItem")
                                    }}
                                    className=" border border-[#5446D0] rounded-md px-[20px] min-w-[80px] py-[12px] text-center font-medium"
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => {
                                        {
                                            if (!store.videoStore.singleCampaignData.readyToExport) {
                                                onSaveHandler(true);
                                                navigate("/videos");
                                                store.videoStore.setSelectedMenuOption("NoSelectedItem")
                                            } else {
                                                navigate("/videos");
                                                store.videoStore.setSelectedMenuOption("NoSelectedItem")
                                            }
                                        }
                                    }}
                                    className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] min-w-[80px] text-center font-semibold"
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            )}

            {/* Add Variable Modal   */}
            {variableModal && (
                <AddVariable setVariableModal={setVariableModal} setApiCallLoader={setApiCallLoader} />
            )}
            <div className='absolute top-[-1000px] left-[-10000px]'>
                <p style={{ fontFamily: 'popins' }} className=''>hello</p>
            </div>
        </>
    )
}

export default observer(Editor)