import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Configuration from '../../components/dashboard/settings/Configuration'
import Elevenlabs from '../../components/dashboard/Integrations/Elevenlabs'
import open_ai_logo from '../../assets/open_ai_logo.png'
import eleven_labs_logo from '../../assets/eleven_lab_logo.png'
import share from '../../assets/share.png'

function Integration() {
    const location = useLocation()
    const history = useNavigate()

    const integrationJson = [
        {
            name: "Open AI TTS",
            path: "/integration/open-ai",
            component: <Configuration />,
            img: open_ai_logo,
            url: 'https://platform.openai.com/docs/guides/text-to-speech',
            doc: 'openai.com'
        },
        {
            name: "Eleven Labs",
            path: "/integration/eleven-labs",
            component: <Elevenlabs />,
            img: eleven_labs_logo,
            url: 'https://elevenlabs.io/',
            doc: 'elevenlabs.io'
        },
    ];

    return (
        <div className="w-[100%] h-[100%] py-[20px] 2xl:py-[30px] px-[24px]">
            <div className=' col-span-12 pb-[20px]'>
                <h3 className="font-Inter font-medium text-[24px] text-[#FFFFFF]">Integrations</h3>
            </div>
            <div className="grid grid-cols-12 border rounded-[6px] border-[#3b3b4f]">
                {/* Sidebar Menu */}
                <div className="col-span-3 border-r border-[#3b3b4f]">
                    {integrationJson.map((integration) => (
                        <div
                            key={integration.path}
                            className={`py-[20px] font-Inter font-medium relative  cursor-pointer px-[10px] 2xl:px-[20px] border-b border-[#3b3b4f] ${location.pathname === integration.path
                                ? "bg-[#272836] text-[#fff]"
                                : "text-[#ABABC0]"
                                }`}
                            onClick={() => history(integration.path)}
                        >
                            <div className='flex items-center gap-3'>
                                <img src={integration.img} className=' rounded-[15px] outline outline-1 outline-[#3b3b4f] outline-offset-[3px]'></img>
                                <div>
                                    <p className=' mb-[2px]'>{integration.name}</p>
                                    <a href={integration.url} target='_blank' className='group flex items-center'>
                                        <p className='text-[10px] text-[#ababc0] group-hover:underline'>{integration.doc}</p>
                                        <img src={share} className=' w-[12px] h-auto object-contain ml-1'></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Main Content */}
                <div className="h-[calc(100vh-120px)] px-4 col-span-9 overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
                    {integrationJson.map(
                        (integration) =>
                            location.pathname === integration.path && (
                                <div key={integration.path}>{integration.component}</div>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}

export default Integration