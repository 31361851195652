'use client'
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import DragableView from "./DragableView";
import { useStore } from "../../../../utility/hooks/UseStore";
import arrow from "../../../../assets/icons/timeline_arrow.svg";
import Waveform from "./WaveForm";
const TimeFrameView = observer((props) => {

    const mainStore = useStore()
    const store = mainStore.videoStore

    const { element } = props;
    const disabled = element.type === "video";
    const isSelected = store.selectedElement?.id === element.id;
    // const bgColorOnSelected = isSelected ? "bg-slate-800" : "bg-slate-600" #F36870;
    let bgColorOnSelected = "bg-[#4034A7]";
    if (element.type === "video") {
        bgColorOnSelected = `${`bg-[#D7A51F] border border-x-[#D7A51F]  ${isSelected ? 'border-y-[#D7A51F]' : 'border-y-[0px]'} `}`;
    } else if (element.type === "text") {
        bgColorOnSelected = `${`bg-[#F36870] border border-x-[#D4585F]  ${isSelected ? 'border-y-[#D4585F] border-x-[15px]' : 'border-y-[0px]'}`}`;
    } else if (element.type === "button") {
        bgColorOnSelected = `${`bg-[#7A0BC0] border border-x-[#6607A1] ${isSelected ? 'border-y-[#6607A1] border-x-[15px]' : 'border-y-[0px]'}`}`;
    } else if (element.type === "audio") {
        bgColorOnSelected = `${`bg-[#14AE87] border border-y-[#138B67] border-x-[#138B67] ${isSelected ? 'border-y-[#138B67]' : 'border-y-[0px]'}`}`;
    } else if (element.type === 'image') {
        bgColorOnSelected = `${`bg-[#01A5F4] border border-x-[#2D8DB8] ${isSelected ? 'border-y-[#2D8DB8] border-x-[15px]' : 'border-y-[0px]'}`}`
    }

    const disabledCursor = disabled ? "cursor-no-drop" : "cursor-ew-resize";

    const [audioUrl, setAudioUrl] = useState("")
    useEffect(() => {
        if (element && element.type === 'audio') {
            setAudioUrl(element.properties.src)
        }
    }, [element])

    const [frames, setFrames] = useState([]);
    const [timelineWidth, setTimelineWidth] = useState(100);
    const timelineRef = useRef(null);


    useEffect(() => {
        if (element && element.type === "video" && timelineWidth > 0) {
            const frameCount = window?.innerWidth < 1280 ? 20 : 30; // Define a reasonable number of frames to display
            captureVideoFrames(element.properties.src, frameCount);
        }
    }, [element, timelineWidth]);

    const captureVideoFrames = async (src, frameCount) => {
        const video = document.createElement("video");
        video.src = src;
        video.crossOrigin = "anonymous";

        video.onloadedmetadata = async () => {
            const captures = [];
            const interval = video.duration / frameCount;

            for (let i = 0; i < frameCount; i++) {
                video.currentTime = i * interval;
                await new Promise((resolve) => (video.onseeked = resolve));

                const canvas = document.createElement("canvas");
                canvas.width = 100;  // Fixed width for capturing the frame
                canvas.height = 56;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                captures.push(canvas.toDataURL());
            }

            setFrames(captures);
        };
    };

    // Calculate each frame's width based on video duration and timeline width
    const frameWidth = (timelineWidth * (element.timeFrame.end - element.timeFrame.start)) / (store.maxTime * frames.length);

    useEffect(() => {
        if(store.selectedMenuOption === 'NoSelectedItem'){
        setTimeout(() => {
            store.setSelectedElement(null);
            store.setSelectedMenuOption('NoSelectedItem');
        }, 110);
    }
    }, [store.selectedMenuOption]);

    return (
        <div onClick={() => {
            store.setSelectedElement(element);
            if (store.selectedElement?.type === "audio") {
                store.setSelectedMenuOption("Speech Sidepanel")
            } else if (store.selectedElement?.type === "text") {
                store.setSelectedMenuOption("TextPanel")
            } else if (store.selectedElement?.type === "button") {
                store.setSelectedMenuOption("Button Link")
            } else if (store.selectedElement?.type === "video") {
                store.setSelectedMenuOption("video")
            } else if (store.selectedElement?.type === 'image') {
                store.setSelectedMenuOption('Image Edit Panel')
            }

        }} key={element.id} className={`relative w-full h-[25px] 2xl:h-[30px] mb-1 rounded  ${isSelected ? "bg-[#0D0D11]" : ""
            }`} >
            {
                (element.type !== "video" && element.type !== "audio") &&
                <DragableView
                    className={`${isSelected ? 'block' : 'hidden'} z-10 h-[100%]`}
                    value={element.timeFrame.start}
                    total={store.maxTime}
                    disabled={disabled}
                    onChange={(value) => {
                        if (value >= element.timeFrame.end - 1000) {
                            value = element.timeFrame.end - 1000; // Snap to just before the end time
                        }
                        store.updateEditorElementTimeFrame(element, {
                            start: value,
                        });
                        if (value > 0) {
                            store.setCurrentTimeInMs(store?.maxTime > 15000 ? value + 100 : value + 30)
                        } else {
                            store.setCurrentTimeInMs(value)
                        }
                    }}
                >
                    {/* <div className={`mt-[calc(41px/2)] translate-y-[-50%] transform translate-x-[-50%] ${disabledCursor}`} > */}
                    <div className={`h-[100%] flex justify-around items-center absolute right-[-14px] w-[15px] top-0 ${disabledCursor}`} >
                        <img src={arrow} alt="arrow" width={7} />
                    </div>
                </DragableView>
            }
            {element.type === "video" ?
                <div className="flex w-full bg-[#272836] h-full cursor-pointer justify-center items-center  rouned" ref={timelineRef} >
                    {frames?.length > 0 ? frames.map((frame, index) => (
                        <img
                            key={index}
                            src={frame}
                            alt="frame"
                            style={{
                                width: `${frameWidth}%`,  // Set dynamic width for each frame
                            }}
                            className="h-[25px] 2xl:h-[30px] "
                        />
                    )) :
                        <div className="w-full h-full rounded-md skeleton"></div>
                    }
                </div> :
                <DragableView
                    // className={disabled ? "cursor-no-drop" : "cursor-col-resize"}
                    value={element.timeFrame.start}
                    disabled={disabled}
                    style={{ width: `${((element.timeFrame.end - element.timeFrame.start) / store.maxTime) * 100}%` }}
                    total={store.maxTime}
                    onChange={(value) => {
                        const { start, end } = element.timeFrame;
                        element.type !== "video" && store.updateEditorElementTimeFrame(element, { start: value, end: value + (end - start), })
                        if (value > 0) {
                            store.setCurrentTimeInMs(store?.maxTime > 15000 ? value + 100 : value + 30)
                        } else {
                            store.setCurrentTimeInMs(value)
                        }
                    }}
                >
                    <div className={`${bgColorOnSelected} flex items-center  cursor-pointer overflow-hidden truncate select-none h-full text-white text-xs ${element.type !== 'image' && 'px-[4px]'} leading-[25px] rounded border-y-4  `} >
                        {element.type === "text" ? element.properties.text ? element.properties.text : 'Text' : element.type === "button" ? element.properties.text ? element.properties.text : 'Button' : element.type === "image" ?
                            <img src={element.properties.src} alt="Repeated icon" className="w-5 h-5 2xl:w-6 2xl:h-6 mx-auto object-contain" />
                            : <Waveform audioUrl={audioUrl} element={element} />}
                    </div>
                </DragableView>}
            {(element.type !== "video" && element.type !== "audio") &&
                <DragableView
                    className={`${isSelected ? 'block' : 'hidden'} z-10`}
                    disabled={disabled}
                    value={element.timeFrame.end}
                    total={store.maxTime}
                    onChange={(value) => {
                        if (value <= element.timeFrame.start + 1000) {
                            value = element.timeFrame.start + 1000; // Snap to just after the start time
                        }
                        store.updateEditorElementTimeFrame(element, {
                            end: value,
                        });
                        store.setCurrentTimeInMs(value)
                    }}
                >
                    <div className={`h-[100%] flex justify-around items-center absolute left-[-14px] w-[15px] top-0 rotate-180 ${disabledCursor} `} >
                        <img src={arrow} alt="arrow" width={7} />
                    </div>
                </DragableView>
            }
        </div>
    );
});

export default TimeFrameView;