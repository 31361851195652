import React, { useEffect, useState } from 'react'
import add from "../../../assets/icons/add.svg";
import plus_icon from "../../../assets/icons/plus.svg";
import { useStore } from '../../../utility/hooks/UseStore';
import APICallLoader from "../../../components/ui/common/APILoaderRing";
import deleteIcon from "../../../assets/icons/delete.svg";
import deleteicon from "../../../assets/delete_icon.png";

function Elevenlabs() {
    const store = useStore();
    const [ElevenLabApi, setElevenLabApi] = useState(false);
    const [newElevelabApiModel, setnewElevelabApiModel] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [errorForElevenlabs, setErrorForElevenlabs] = useState();
    const [runValidatorForElevenLab, setRunValidatorForElevenLab] = useState("");
    const [apiCallLoader, SetApiCallLoader] = useState(false);
    const [canGetElevenLabAPIFromStore, SetcanGetElevenLabAPIFromStore] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);

    function validationForElevenLab() {
        const errorahandler = {};
        let isErrorFound = false;

        if (!apiKey.trim()) {
            errorahandler.apiKey = "Api Key is required.";
            isErrorFound = true;
        } else {
            delete errorahandler.apiKey;
        }
        setErrorForElevenlabs(errorahandler);
        return isErrorFound;
    }

    const handelSubmitForOpenAI = (e) => {
        e.preventDefault();
        const isError = validationForElevenLab();
        setRunValidatorForElevenLab(true);
        if (isError) return;
        SetApiCallLoader(true);
        const finalData = {
            key: apiKey,
        };
        store.userRegister.addElevenLabsAPI({
            finalData,
            SetcanGetElevenLabAPIFromStore,
            loader: SetApiCallLoader,
            setApiKey,
            setRunValidatorForElevenLab,
            setnewElevelabApiModel,
        });
    };


    useEffect(() => {
        if (runValidatorForElevenLab) {
            validationForElevenLab();
        }
    }, [apiKey]);

    useEffect(() => {
        store.userRegister.getListOfElevenLabAPI({
            SetcanGetElevenLabAPIFromStore, loader: SetApiCallLoader
        });
    }, []);


    useEffect(() => {
        if (canGetElevenLabAPIFromStore) {
            setElevenLabApi(store.userRegister.userElvenLabAPIKeys);
            SetcanGetElevenLabAPIFromStore(false);
            setErrorForElevenlabs();
        }
    }, [canGetElevenLabAPIFromStore]);



    const [isActiveForElevenlab, setIsActiveForElevenlab] = useState(false);
    const [tempIsActiveForElevenlab, setTempIsActiveForElevenlab] = useState(false);

    useEffect(() => {
        if (ElevenLabApi) {
            setIsActiveForElevenlab(ElevenLabApi?.status);
            setTempIsActiveForElevenlab(ElevenLabApi?.status);
        }
    }, [ElevenLabApi?.status]);

    const [statusUpdateModelElevenLab, setStatusUpdateModelElevenLab] = useState(false);

    const toggleActiveForElevenLab = () => {
        // setTempIsActiveForOpenAi(!tempIsActiveForOpenAi);
        setStatusUpdateModelElevenLab(true);
    };

    function UpdateStatusOfElevenLab() {
        SetApiCallLoader(true);
        const finalData = {
            status: tempIsActiveForElevenlab === true ? false : true,
        };
        store.userRegister.updateElevenLabStatus({
            finalData,
            SetcanGetElevenLabAPIFromStore,
            loader: SetApiCallLoader,
            setStatusUpdateModelElevenLab,
        });
    }

    function Delete() {
        setDeleteModel(false);
        store.userRegister.deleteElevenLabApi({
            SetcanGetElevenLabAPIFromStore,
            loader: SetApiCallLoader,
        });
    }
    return (
        <div>

            {/* Loader When api Call */}
            <div
                className={`${apiCallLoader ? "flex" : "hidden"
                    } fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}
            >
                <APICallLoader />
            </div>

            {/* Delete Model  */}
            {deleteModel && (
                <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                        {/* <h1 className="font-medium text-lg">
              {deleteModalFor === "openAi" ? "OpenAI API" : "Custom Domain"}
            </h1> */}
                        <img
                            src={deleteicon}
                            alt="deleteicon"
                            className=" mx-auto w-[100px] h-auto object-contain"
                        ></img>
                        <p className="text-sm font-medium text-[#ABABC0] py-[15px] text-center">
                            Are you sure you want to delete this Api Key?
                        </p>
                        <div className="flex justify-center items-center text-sm gap-[12px]">
                            <button
                                onClick={() => setDeleteModel(false)}
                                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    Delete();
                                }}
                                className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-semibold"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Status Model for ElevenLab*/}

            {statusUpdateModelElevenLab && (
                <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
                        <h1 className="font-medium text-lg">Eleven Labs</h1>
                        <p className="text-sm font-medium text-[#ABABC0] py-[15px]">{`Are you sure you want to ${tempIsActiveForElevenlab ? "inactive" : "active"
                            } your Eleven Labs API key?`}</p>
                        <div className="flex justify-end items-center text-sm gap-[12px]">
                            <button
                                onClick={() => setStatusUpdateModelElevenLab(false)}
                                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    UpdateStatusOfElevenLab();
                                }}
                                className=" bg-[#5446D0] min-w-[88px] rounded-md px-[20px] py-[12px] text-center font-semibold"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* new Elevenlabs API modal */}

            {newElevelabApiModel && (
                <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
                        <img
                            src={plus_icon}
                            alt=""
                            onClick={() => {
                                setnewElevelabApiModel(false);
                                setErrorForElevenlabs("");
                            }}
                            className="cursor-pointer rotate-45  ml-auto"
                        />

                        <form onSubmit={handelSubmitForOpenAI}>
                            <div >
                                <label
                                    htmlFor="domain"
                                    className=" font-Inter text-[14px] font-semibold text-[#fff]"
                                >
                                    Enter Eleven Labs API <span className="text-[#FF5364]">*</span>
                                </label>
                                <div className=" relative">
                                    <input
                                        type="text"
                                        name="domain"
                                        value={apiKey}
                                        className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[11px] mt-[8px] px-[10px] text-[14px] mb-[12px] outline-none"
                                        placeholder="Enter Your Eleven Labs API"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setApiKey(e.target.value.trim());
                                        }}
                                    />
                                    {errorForElevenlabs && errorForElevenlabs.apiKey && (
                                        <p className="text-[12px] leading-[14.52px] absolute bottom-[-18px] font-medium text-[#FF5364] mb-[12px]">
                                            {errorForElevenlabs.apiKey}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-center items-center text-sm gap-[12px] mt-[10px] 2xl:mt-[30px]">
                                <button
                                    onClick={() => {
                                        setnewElevelabApiModel(false);
                                        setErrorForElevenlabs("");
                                    }}
                                    className=" border border-[#5446D0] rounded-md px-[20px] min-w-[130px] py-[9px] text-center font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center min-w-[130px] font-medium"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className="pt-[24px] ">
                <div className="flex justify-between items-center">
                    <h3 className="font-Inter font-medium text-[18px] leading-[21.78px] text-[#FFFFFF]">
                        Eleven Labs
                    </h3>
                    <button
                        onClick={() => {
                            !(ElevenLabApi?.endChar && ElevenLabApi?.startChar) &&
                                setnewElevelabApiModel(true);
                        }}

                        className={`${!(ElevenLabApi?.endChar && ElevenLabApi?.startChar)
                            ? ""
                            : "opacity-[0.5] cursor-not-allowed"
                            } flex items-center justify-center gap-[10px] bg-[#5446D0] rounded-[6px] px-[20px] py-[10.5px] font-Inter font-semibold text-[14px] 2xl:text-[16px] leading-[19px] tracking-[0.48px] text-[#FFFFFF]`}
                    >
                        <img src={add} alt="add" />
                        Add API Key
                    </button>
                </div>
                {/* Table  */}
                <div className="w-full h-[94%] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-gray-100 pt-[15.5px]">
                    <table className="w-[100%] text-left  border border-[#272836] rounded ">
                        <thead>
                            <tr className="bg-[#272836] text-[#FFFFFF] text-[16px] font-normal leading-[19.36px] tracking-[0.32px]">
                                <th className="pl-[14px] w-[25%] 2xl:w-[45%] py-[15.5px]">
                                    {" "}
                                    API Key{" "}
                                </th>
                                <th className="py-[15.5px] w-[10%]"> Status </th>
                                <th className="py-[15.5px] w-[5%] 2xl:w-[15%] text-center">
                                    {" "}
                                    Action{" "}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(ElevenLabApi && Object.keys(ElevenLabApi)?.length > 0) &&
                                <>
                                    <tr className="border-b border-[#272836] text-[#ABABC0] text-[14px]">
                                        <td className="pl-[14px] py-[15.5px]">{`${ElevenLabApi?.startChar}***************${ElevenLabApi?.endChar}`}</td>
                                        <td className="py-[15.5px] ">
                                            <div
                                                className="relative w-[42px] h-[22px] cursor-pointer"
                                                onClick={toggleActiveForElevenLab}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="toggleB"
                                                    className="sr-only"
                                                    checked={isActiveForElevenlab}
                                                    onChange={toggleActiveForElevenLab}
                                                />
                                                <div
                                                    className={`block bg-[#3B3B4F] w-[42px] h-[22px] rounded-full ${isActiveForElevenlab ? "bg-[#5446d0]" : "bg-gray-500"
                                                        }`}
                                                ></div>
                                                <div
                                                    className={` absolute ${isActiveForElevenlab ? "right-[1px]" : "left-[1px] "
                                                        } top-[1px] bg-white w-[20px] h-[20px] rounded-full transition`}
                                                ></div>
                                            </div>
                                        </td>
                                        <td className="py-[15.5px]">
                                            <img
                                                onClick={() => {
                                                    setDeleteModel(true);
                                                }}
                                                src={deleteIcon}
                                                alt="deleteIcon"
                                                className="cursor-pointer mx-auto"
                                            />
                                        </td>
                                    </tr>
                                </>
                            }
                            {(Object.keys(ElevenLabApi)?.length === 0) && !apiCallLoader && (
                                <tr className="border-b border-[#272836]">
                                    <td className="pl-[14px] py-[15.5px] text-[#ABABC0] text-[14px]">
                                        No Eleven Labs API Key Added
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Elevenlabs