import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../../../utility/hooks/UseStore';
import toast from 'react-hot-toast';
import useOutsideClick from '../../../../utility/hooks/useOutsideClick';
import merge_list_icon from '../../../../assets/icons/mergeMenu.svg'
import pause from '../../../../assets/audio_pause.svg'
import reset_icon from '../../../../assets/icons/reset.svg'
import playbutton from '../../../../assets/audio_play.svg'
import droparrow from '../../../../assets/dropdown-arrow.svg'
import { formatTimeToMinSec } from '../../../../utility/videoEditorUtils';
import SpeechSidePanel from './SpeechSidePanel';
import { observer } from 'mobx-react';
import APICallLoader from '../../../../components/ui/common/APILoaderRing'
import { useParams } from 'react-router-dom';
import { localStorageNames } from "../../../../configs/constants"
import Select from "react-select";
import { hyperclipTTS } from '../../../../configs/hyperclipTTS'
import { manageAudio } from '../../../../utility/hyperclipTTS';
import left_arrow_icon from "../../../../assets/icons/left_arrow.svg";

const { __USER_DATA } = localStorageNames



const SpeechPanel = ({ setVariableModal }) => {

    const mergeTagsPopupRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [mergDropDown, setMergDropDown] = useState(false);
    const mainStore = useStore()
    const userStore = mainStore.userRegister
    const store = mainStore.videoStore
    const ref = React.useRef(null);
    const [canGetElevenLabVoice, SetcanGetElevenLabVoice] = useState(false)
    const [canGetElevenLabModal, SetcanGetElevenLabModal] = useState(false)
    const [ElevenVoiceList, setElevenVoiceList] = useState([])
    const [ElevenModalList, setElevenModalList] = useState([])
    const [engine, setEngine] = useState('')
    const [stability, setStability] = useState(50)
    const [similarity, setSimilarity] = useState(75)
    const [styleExaggeration, setStyleExaggeration] = useState(0)
    const [ElevenVoiceOptions, setElevenVoiceOptions] = useState([])
    const [voiceEleven, setVoiceEleven] = useState('')
    const [elevenModal, setElevenModal] = useState({})
    const [speakerBoost, setSpeakerBoost] = useState(true)
    const [elevenlabText, setElevenLabText] = useState('')

    const handelReset = () => {
        setSimilarity(75)
        setStability(50)
        setStyleExaggeration(0)
        setSpeakerBoost(true)
    }
    useEffect(() => {
        if (ElevenModalList?.length > 0) {
            setElevenModal(ElevenModalList[0])
        }
    }, [ElevenModalList])
    useEffect(() => {
        const con = ElevenVoiceList
            ?.map(c => ({
                value: c.voice_id,
                label: c.name, id: c.preview_url
            }));
        setElevenVoiceOptions(con);
    }, [ElevenVoiceList]);

    useEffect(() => {
        if (engine === 'elevenLabs') {
            userStore.getListOfElevenLabVoice({ SetcanGetElevenLabVoice })
            userStore.getListOfElevenLabModals({ SetcanGetElevenLabModal })
        }
    }, [engine])

    useEffect(() => {
        if (canGetElevenLabVoice) {
            setElevenVoiceList(userStore.userElvenLabVoice);
            SetcanGetElevenLabVoice(false);
        }
    }, [canGetElevenLabVoice]);

    useEffect(() => {
        if (canGetElevenLabModal) {
            setElevenModalList(userStore.userElvenLabModal);
            SetcanGetElevenLabModal(false);
        }
    }, [canGetElevenLabModal]);


    const { id } = useParams()

    const [hyperclipTTSconfig, setHyperclipTTSconfig] = useState({
        language: "",
        gender: "",
        dataset: "",
        speaker: "",
        text: ""
    })

    const handlePopup = (item) => {
        const textarea = document.getElementById('openai'); // Replace with your textarea ref or ID
        if (textarea) {
            const cursorPos = textarea.selectionStart;
            const currentValue = textarea.value;

            // Insert the item at the cursor position
            const newValue = currentValue.slice(0, cursorPos) + `{{${item}}}` + currentValue.slice(cursorPos);

            // Update the input value with the new value
            setInputValue(newValue);

            // Update the hyperclipTTSconfig text
            // const newText = hyperclipTTSconfig.text
            //     ? hyperclipTTSconfig.text.slice(0, cursorPos) + `{{${item}}}` + hyperclipTTSconfig.text.slice(cursorPos)
            //     : `{{${item}}}`;
            // setHyperclipTTSconfig({
            //     ...hyperclipTTSconfig,
            //     text: newText,
            // });

            // Set popup and dropdown visibility to false
            setPopupVisible(false);
            setMergDropDown(false);

            // Restore the cursor position after inserting the text
            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = cursorPos + `{{${item}}}`.length;
                textarea.focus(); // Ensure focus is maintained
            }, 0);
        }
    };




    const handleDoubleClick = () => {
        if (headers !== undefined) {
            setPopupVisible(true);
        } else {
            toast.error("please upload excel file first")
        }
    };

    const handleFileChange = (event) => {
        // Handle file change logic
    };

    const handleDocumentClick = (event) => {
        if (
            mergeTagsPopupRef.current &&
            !mergeTagsPopupRef.current.contains(event.target)
        ) {
            setPopupVisible(false);
        }
    };


    useOutsideClick(ref, () => {
        if (mergDropDown) {
            setMergDropDown(false)
        }
    })

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    // const dispatch = useDispatch();
    const [language, setLanguage] = useState("");
    const [voice, setVoice] = useState("");
    const [voiceType, setVoiceType] = useState("");
    const [Actorvoice, setActorVoice] = useState("");
    const [Actors, setActors] = useState(false)
    const [apiCallLoader, setApiCallLoader] = useState(false)
    const [formatedAudioLength, setFormatedAudioLength] = React.useState("00:00");
    const [speed, setSpeed] = useState(1);
    const [volume, setVolume] = useState(100);
    const [screen, setScreen] = useState("speech")
    const [startValue, setStartValue] = useState("");
    const [error, setError] = useState('');
    const [time, setTime] = useState({
        minutes: '',
        seconds: '',
        milliseconds: '',
    });

    const handleRangeChange = (e) => {
        setSpeed(e.target.value);
    };


    // const XmlData = useSelector(state => state?.excelFile?.addFile?.data?.excelID)
    const [excelId, setExcelId] = useState("")

    useEffect(() => {
        store.ExcelListForSingleCampaign.map((item) => {
            setExcelId(item.excelId);
        })
    }, [])


    const calculateEstimatedAudioDuration = (text, speed) => {
        const averageCharactersPerSecond = 12; // Rough estimate, adjust this value based on testing
        const totalCharacters = text?.length;
        const estimatedDurationInSeconds = totalCharacters / averageCharactersPerSecond / speed;
        return estimatedDurationInSeconds;
    };

    const calculateEstimatedAudioDurationForElevenLabs = (text, speed) => {
        const averageCPS = 12; // Average characters per second for ElevenLabs
        const adjustedCPS = averageCPS * speed; // Adjust based on speed
        const durationInSeconds = text.length / adjustedCPS;
        return durationInSeconds; // Duration in seconds
    };

    const handleAddButtonClick = (e) => {
        e?.preventDefault();
        // Calculate total milliseconds from the input values
        const totalMilliseconds =
            Number(time.minutes) * 60000 +
            Number(time.seconds) * 1000 +
            Number(time.milliseconds);
        store.startedTime = totalMilliseconds;

        const estimatedAudioDuration = calculateEstimatedAudioDuration(engine === 'hyperclip' ? hyperclipTTSconfig.text : inputValue, speed);
        const estimatedDuration = calculateEstimatedAudioDurationForElevenLabs(elevenlabText, 1);

        // console.log('Estimated audio duration:', estimatedDuration, store.videoDuration);
        // Check if the total milliseconds exceed the video duration
        if (estimatedAudioDuration > store.videoDuration) {
            toast.error('Text-to-speech content must not exceed video length.');
            setError('The entered time exceeds the video duration.');
        } else if (estimatedDuration > store.videoDuration) {
            toast.error('Text-to-speech content must not exceed video.');
            setError('The entered time exceeds the video duration.');
        } else {
            setError('');
            setApiCallLoader(true)

            if (engine === 'hyperclip') {

                let languageCode, dataset, isDefault, speaker;

                hyperclipTTS.map((ele, i) => {
                    if (ele.language === hyperclipTTSconfig.language) {
                        languageCode = ele.code
                        dataset = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).dataset
                        isDefault = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).isDefault
                        if (ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).isDefault === false) {
                            speaker = ele.speakers.find(ele => ele.name === hyperclipTTSconfig.speaker).speaker
                        } else {
                            speaker = ""
                        }
                    }
                })

                const finaldata = {
                    engine: 'hyperclip',
                    language: languageCode,
                    dataset: dataset,
                    isDefault: isDefault,
                    speaker: speaker,
                    text: hyperclipTTSconfig.text,
                    created: new Date()?.getTime()
                }

                store.textToSpeech({ campaignID: id, finaldata, setApiCallLoader, setEngine })
                setHyperclipTTSconfig({ text: '' })

            } else if (engine === 'openai') {
                const finaldata = {
                    engine: 'openai',
                    text: inputValue,
                    voice: voice,
                    speed: speed,
                    language: language,
                    created: new Date()?.getTime()
                }
                store.textToSpeech({ campaignID: id, finaldata, setApiCallLoader, setEngine })
                // dispatch(TextToSpeech({ inputValue, voice, language, speed, volume, excelId: excelId }));
                // dispatch(SpeechLoader())
                setVolume(100)
                setVoice("")
                setLanguage("")
                setInputValue('')
            } else if (engine === 'elevenLabs') {
                const finaldata = {
                    engine: 'elevenLabs',
                    text: elevenlabText,
                    model: elevenModal?.model_id,
                    voice: voiceEleven,
                    stability: Number(parseFloat(Number(stability) / 100).toFixed(1)),
                    similarity: Number(parseFloat(Number(similarity) / 100).toFixed(1)),
                    ...(elevenModal?.can_use_style && { style: Number(parseFloat(Number(styleExaggeration) / 100).toFixed(1)) }),
                    ...(elevenModal?.can_use_speaker_boost && { speaker: Boolean(speakerBoost) }),
                    created: new Date()?.getTime()
                }
                store.textToSpeech({ campaignID: id, finaldata, setApiCallLoader, setEngine })
                setVoiceEleven('')
                setElevenLabText('')
            }

        }

    };
    // const headers = useSelector((state) => state?.excelFile?.addFile?.data?.selectedHeaders);
    const headers = store.singleCampaignData.headers
    // const head = headers

    const audioData = []
    useEffect(() => {
        if (audioData) {
            // store.addtexttospeech(audioData, inputValue);
        }
    }, [audioData])

    useEffect(() => {
        // If audioData chanaddtexttospeechges, update the audio source
        if (audioData) {
            const audioElement = document.getElementById('audioPlayer');
            if (audioElement) {
                // audioElement.src = `https://hooc.b-cdn.net/${audioData}`;
                audioElement.src = `${audioData}`;
            }
        }
    }, [audioData]);

    const [isDrag, setIsDrag] = useState(false)

    // this logic is making for the adding multiple TTS


    const handleChangeHyperclipTTSconfig = (e) => {
        const { name, value } = e.target;

        setHyperclipTTSconfig((prevConfig) => {
            const updatedConfig = {
                ...prevConfig,
                [name]: value
            };

            // Reset dependent values
            if (name === "language") {
                updatedConfig.gender = ""; // Reset gender
                updatedConfig.speaker = ""; // Reset speaker
            } else if (name === "gender") {
                updatedConfig.speaker = ""; // Reset speaker when gender changes
            }

            return updatedConfig;
        });
    };

    const handleDoubleClickOnHyperText = () => {
        if (headers !== undefined) {
            setPopupVisible(true);
        } else {
            toast.error("please upload excel file first")
        }
    };

    const handlePopupHyperText = (item) => {
        const textarea = document.getElementById('hyperclip'); // Ensure the correct ID or reference is used

        if (textarea) {
            // Get the current cursor position
            const cursorPosition = textarea.selectionStart;

            // Get the current text in the textarea
            const currentText = textarea.value;

            // Insert the item at the cursor position
            const newText = currentText.slice(0, cursorPosition) + `{{${item}}}` + currentText.slice(cursorPosition);

            // Update the hyperclipTTSconfig state with the new text
            setHyperclipTTSconfig((prevValue) => ({
                ...prevValue,
                text: newText
            }));

            // Update the textarea value directly to reflect changes
            textarea.value = newText;

            // Restore the cursor position after the update
            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = cursorPosition + `{{${item}}}`.length;
                textarea.focus(); // Ensure focus remains on the textarea
            }, 0);

            // Close the popup and dropdown
            setPopupVisible(false);
            setMergDropDown(false);
        }
    };



    // select voice section
    const [voiceOption, setVoiceOption] = useState([]);
    const [playAudio, setPlayAudio] = useState(false);
    const [audioId, setAudioId] = useState("");
    const handlePlayClick = (e, id, filePath) => {
        e.stopPropagation(); // Prevent parent click handler from firing

        if (audioId === id && playAudio) {
            // If the same audio is clicked and playing, pause it
            manageAudio(filePath, true); // Pause the current audio
            setPlayAudio(false);
            setAudioId(null);
        } else {
            // If a new audio is clicked, play it
            manageAudio(filePath, false, () => {
                // Reset state when audio ends
                setPlayAudio(false);
                setAudioId(null);
            }); // Play the new audio
            setPlayAudio(true);
            setAudioId(id);
        }
    };
    const customStyles = {
        control: (base) => ({
            ...base,
            boxShadow: "none",
            minHeight: 41,
            border: "none !important",
            borderColor: "none !important",
            backgroundColor: "#3B3B4F",
            color: "#fff !important",
            borderRadius: 0,
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: "none",
            zIndex: "9999 !important",
            color: "#fff !important",
        }),
        menuList: (base) => ({
            ...base,
            color: "#FFFFFF",
            backgroundColor: "#1c1c26",
            minHeight: 180,
            maxHeight: 180,
            overflow: 'auto',
            "::-webkit-scrollbar": {
                width: "5px",
            },
            "::-webkit-scrollbar-track": {
                background: "#272836",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#5446d0",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#DAE9FF",
            },
        }),
        option: (provided) => ({
            ...provided,
            color: "#fff !important",
            fontWeight: 400,
            fontSize: 14,
            cursor: "pointer",
            borderBottom: 'none !important',
            zIndex: 9999,
            paddingTop: 5,
            paddingBottom: 5,
            borderColor: "#DAE9FF",
            borderStyle: "solid",
            ":hover": {
                backgroundColor: "#1967d2 !important",
                color: "#fff !important",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white !important',
            fontSize: 14,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'white !important',
            fontSize: 14,
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        input: (provided) => ({
            ...provided,
            color: 'white', // Set the input text color to white
            fontSize: 14,
        }),
    };

    const customOption = (props) => {

        return (
            <div
                onClick={(e) => {
                    if (!e.target.classList.contains('play-button')) {
                        props.innerProps.onClick(e); // Let the normal select action happen when not clicking the button
                    }
                }}
                {...props.innerProps}
                className="custom-option flex items-center cursor-pointer hover:bg-[#1967d2] justify-between px-3 py-1"
            >
                <span className=' cursor-pointer'>{props.data.label}</span>
                <button
                    className=""
                    onClick={(e) => {
                        // handlePlayClick(e, props.data.value)
                        handlePlayClick(e, props.data.value, props.data.value);
                        // if (playAudio && audioId === props.data.value) {
                        //   setPlayAudio(false);
                        // }else{
                        //   setPlayAudio(true);
                        // }
                    }}
                >
                    <img src={playAudio && audioId === props.data.value ? pause : playbutton} alt="Play" className=' w-[18px] h-auto object-contain' />
                </button>
            </div>
        );
    };

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null); // Track the current audio by its ID or URL
    const audioRef = useRef(null); // Use a ref to control audio playback

    // elevenlab preview audio

    const playAudioElevenLab = (url, id) => {
        // Pause the current audio if there is any
        if (audioRef.current) {
            audioRef.current.pause();
        }

        // Create a new audio element and set it to the reference
        const newAudio = new Audio(url);
        audioRef.current = newAudio;

        // Set the current audio state to the new audio's ID or URL
        setCurrentAudio(id); // Set current audio ID for tracking

        newAudio.play()
            .then(() => {
                setIsPlaying(true); // Set the state to playing
            })
            .catch((error) => {
                console.error('Error playing audio:', error);
            });

        // Reset state when the audio ends
        newAudio.onended = () => {
            setIsPlaying(false);
            setCurrentAudio(null); // Reset current audio when it finishes playing
        };
    };
    const handlePlayClickElevenlab = (e, url, id) => {
        e.stopPropagation(); // Prevent the parent div click from firing

        if (isPlaying && currentAudio === id) {
            // If the same audio is playing, pause it
            audioRef.current.pause();
            setIsPlaying(false);
            setCurrentAudio(null); // Reset current audio state
        } else {
            // Play the new audio if it's a different one
            playAudioElevenLab(url, id);
        }
    };
    const customOptionForElevenLab = (props) => {
        return (
            <div
                onClick={(e) => {
                    if (!e.target.classList.contains('play-button')) {
                        props.innerProps.onClick(e); // Allow normal select action
                    }
                }}
                {...props.innerProps}
                className="custom-option flex items-center cursor-pointer hover:bg-[#1967d2] justify-between px-3 py-1"
            >
                <span className="cursor-pointer">{props.data.label}</span>
                <button
                    className="play-button"
                    onClick={(e) => {
                        handlePlayClickElevenlab(e, props.data.id, props.data.id)
                    }} // Pass the audio URL and ID
                >
                    <img
                        src={isPlaying && currentAudio === props.data.id ? pause : playbutton} // Toggle play/pause image
                        alt="Play/Pause"
                        className="w-[18px] h-auto object-contain"
                    />
                </button>
            </div>
        );
    };

    useEffect(() => {
        const con = hyperclipTTS.find(ele => ele.language === hyperclipTTSconfig.language)?.speakers?.filter(c => c.gender === hyperclipTTSconfig.gender)
            ?.map(c => ({
                value: c.sample,
                label: c.name
            }));
        setVoiceOption(con);
    }, [hyperclipTTSconfig.gender]);

    const openaikey = userStore?.userOpenAiAPIKeys?.openAI
    const ElevenLabkey = userStore?.userElvenLabAPIKeys


    const handleBackspace = (e) => {
        if (e.key === 'Backspace') {
            const textarea = e.target;
            const cursorPosition = textarea.selectionStart;

            // Get the text before and after the cursor
            const beforeCursor = hyperclipTTSconfig.text.substring(0, cursorPosition);
            const afterCursor = hyperclipTTSconfig.text.substring(cursorPosition);

            // Match all `{{...}}` blocks in the text
            const fullMatch = hyperclipTTSconfig.text.match(/{{[^}]*}}/g);

            if (fullMatch) {
                for (let match of fullMatch) {
                    const startIndex = hyperclipTTSconfig.text.indexOf(match);
                    const endIndex = startIndex + match.length;

                    // Check if the cursor is inside this block
                    if (cursorPosition > startIndex && cursorPosition < endIndex) {
                        e.preventDefault(); // Prevent the default backspace behavior

                        // Remove the `{{...}}` block and keep the rest of the text
                        const updatedText =
                            hyperclipTTSconfig.text.substring(0, startIndex) +
                            hyperclipTTSconfig.text.substring(endIndex);

                        // Update the input text and caret position
                        setHyperclipTTSconfig((prevConfig) => ({
                            ...prevConfig,
                            text: updatedText,
                        }));

                        // Delay to allow React to update the DOM before setting cursor position
                        setTimeout(() => {
                            textarea.setSelectionRange(startIndex, startIndex);
                        }, 0);

                        return; // Exit after handling the match
                    }
                }
            }

            // Match a `{{...}}` block immediately before the cursor
            const blockMatch = beforeCursor.match(/{{[^}]*}}$/);
            if (blockMatch) {
                e.preventDefault(); // Prevent the default backspace behavior

                const startIndex = beforeCursor.lastIndexOf('{{');
                const endIndex = cursorPosition;

                // Remove the `{{...}}` block and keep the rest of the text
                const updatedText =
                    hyperclipTTSconfig.text.substring(0, startIndex) +
                    hyperclipTTSconfig.text.substring(endIndex);

                // Update the input text and caret position
                setHyperclipTTSconfig((prevConfig) => ({
                    ...prevConfig,
                    text: updatedText,
                }));

                // Delay to allow React to update the DOM before setting cursor position
                setTimeout(() => {
                    textarea.setSelectionRange(startIndex, startIndex);
                }, 0);
            }
        }
    };

    const handleBackspaceForOpenAi = (e) => {
        if (e.key === 'Backspace') {
            const textarea = e.target;
            const cursorPosition = textarea.selectionStart;

            // Get the text before and after the cursor
            const beforeCursor = inputValue.substring(0, cursorPosition);
            const afterCursor = inputValue.substring(cursorPosition);

            // Match all `{{...}}` blocks in the input value
            const fullMatch = inputValue.match(/{{[^}]*}}/g);

            if (fullMatch) {
                for (let match of fullMatch) {
                    const startIndex = inputValue.indexOf(match);
                    const endIndex = startIndex + match.length;

                    // Check if the cursor is inside this block
                    if (cursorPosition > startIndex && cursorPosition < endIndex) {
                        e.preventDefault(); // Prevent the default backspace behavior

                        // Remove the `{{...}}` block and keep the rest of the text
                        const updatedText =
                            inputValue.substring(0, startIndex) +
                            inputValue.substring(endIndex);

                        // Update the input text and caret position
                        setInputValue(updatedText);

                        // Delay to allow React to update the DOM before setting cursor position
                        setTimeout(() => {
                            textarea.setSelectionRange(startIndex, startIndex);
                        }, 0);

                        return; // Exit after handling the match
                    }
                }
            }

            // Match a `{{...}}` block immediately before the cursor
            const blockMatch = beforeCursor.match(/{{[^}]*}}$/);
            if (blockMatch) {
                e.preventDefault(); // Prevent the default backspace behavior

                const startIndex = beforeCursor.lastIndexOf('{{');
                const endIndex = cursorPosition;

                // Remove the `{{...}}` block and keep the rest of the text
                const updatedText =
                    inputValue.substring(0, startIndex) +
                    inputValue.substring(endIndex);

                // Update the input text and caret position
                setInputValue(updatedText);

                // Delay to allow React to update the DOM before setting cursor position
                setTimeout(() => {
                    textarea.setSelectionRange(startIndex, startIndex);
                }, 0);
            }
        }
    };

    const handleBackspaceForElevenLab = (e) => {
        if (e.key === 'Backspace') {
            const textarea = e.target;
            const cursorPosition = textarea.selectionStart;

            // Get the text before and after the cursor
            const beforeCursor = elevenlabText.substring(0, cursorPosition);
            const afterCursor = elevenlabText.substring(cursorPosition);

            // Match all `{{...}}` blocks in the text
            const fullMatch = elevenlabText.match(/{{[^}]*}}/g);

            if (fullMatch) {
                for (let match of fullMatch) {
                    const startIndex = elevenlabText.indexOf(match);
                    const endIndex = startIndex + match.length;

                    // Check if the cursor is inside this block
                    if (cursorPosition > startIndex && cursorPosition < endIndex) {
                        e.preventDefault(); // Prevent the default backspace behavior

                        // Remove the `{{...}}` block and keep the rest of the text
                        const updatedText =
                            elevenlabText.substring(0, startIndex) +
                            elevenlabText.substring(endIndex);

                        // Update the input text and caret position
                        setElevenLabText(updatedText);

                        // Delay to allow React to update the DOM before setting cursor position
                        setTimeout(() => {
                            textarea.setSelectionRange(startIndex, startIndex);
                        }, 0);

                        return; // Exit after handling the match
                    }
                }
            }

            // Match a `{{...}}` block immediately before the cursor
            const blockMatch = beforeCursor.match(/{{[^}]*}}$/);
            if (blockMatch) {
                e.preventDefault(); // Prevent the default backspace behavior

                const startIndex = beforeCursor.lastIndexOf('{{');
                const endIndex = cursorPosition;

                // Remove the `{{...}}` block and keep the rest of the text
                const updatedText =
                    elevenlabText.substring(0, startIndex) +
                    elevenlabText.substring(endIndex);

                // Update the input text and caret position
                setElevenLabText(updatedText);

                // Delay to allow React to update the DOM before setting cursor position
                setTimeout(() => {
                    textarea.setSelectionRange(startIndex, startIndex);
                }, 0);
            }
        }
    };

    const handlePopupElevenlab = (item) => {
        // Get the textarea element
        const textarea = document.getElementById('elevanlab'); // Ensure the correct ID or reference is used

        if (textarea) {
            // Get the current cursor position
            const cursorPosition = textarea.selectionStart;

            // Get the current text in the textarea
            const currentText = textarea.value;

            // Insert the item at the cursor position
            const newText = currentText.slice(0, cursorPosition) + `{{${item}}}` + currentText.slice(cursorPosition);

            // Update the ElevenLab text state with the new value
            setElevenLabText(newText);

            // Update the textarea value directly
            textarea.value = newText;

            // Restore the cursor position after updating the text
            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = cursorPosition + `{{${item}}}`.length;
                textarea.focus(); // Ensure the textarea remains focused
            }, 0);

            // Close the popup and dropdown menus
            setPopupVisible(false);
            setMergDropDown(false);
        }
    };


    const [ISDragStability, setISDragStability] = useState(false)
    const [tooltipPosition, setTooltipPosition] = useState(0); // Position for tooltip
    const [ISDragSimilarity, setISDragSimilarity] = useState(false)
    const [isDragStyleExaggeration, setIsDragStyleExaggeration] = useState(false)

    const selectRef = useRef(null);

    useEffect(() => {
        const handleScrollOutside = (event) => {
            // Check if the scroll target is outside the select dropdown
            if (
                selectRef.current &&
                !selectRef.current.menuListRef?.contains(event.target) &&
                !selectRef.current.controlRef?.contains(event.target)
            ) {
                selectRef.current.blur(); // Close the dropdown
            }
        };

        window.addEventListener('scroll', handleScrollOutside, true); // Capture scroll events
        return () => window.removeEventListener('scroll', handleScrollOutside);
    }, []);

    return (
        <>
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[30]`}>
                <APICallLoader />
            </div>
            {screen === "speech" &&
                <div className='overflow-hidden'>
                    {/* <h1 className="font-Inter font-semibold text-lg px-[10px] 2xl:px-[20px] pt-[10px] 2xl:pt-[20px] text-[#FFFFFF]">Text To Speech</h1> */}
                    <div className="p-[10px] 2xl:p-[20px]">
                        <h1 className="font-Inter font-medium text-sm text-[#ABABC0]"> Select Engine</h1>
                        <div className=' relative'>
                            <select
                                id="languageSelector"
                                className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                value={engine}
                                onChange={(e) => {
                                    // if(e.target.value === 'elevenLabs'){
                                    //     const regex = /{{\s*[\w]*\s*}}/;
                                    //     const elementlimit = store.editorElements?.filter((ele) => ele.type === 'audio' && ele?.properties?.engine === 'elevenLabs' && regex.test(ele.properties.text))?.length
                                    //     if(elementlimit >= ElevenLabkey.limit){
                                    //         toast.error('You have reached the limit of adding ElevenLab TTS')
                                    //         return
                                    //     }
                                    // }
                                    setEngine(e.target.value)
                                }}
                            >
                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select TTS</option>
                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="hyperclip">HyperclipTTS</option>
                                {openaikey?.status && <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="openai">Open AI TTS</option>}
                                {ElevenLabkey?.status && <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="elevenLabs">ElevenLabs TTS</option>}
                            </select>
                            <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                        </div>
                    </div>


                    {engine === 'hyperclip' && (
                        <form onSubmit={handleAddButtonClick}>
                            {/* text  */}
                            <div className="p-[10px] 2xl:p-[20px]">
                                <div className="flex justify-between items-center relative">
                                    <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">Text</h3>
                                    <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                                        setMergDropDown(true)
                                    }} > <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                                        <img src={merge_list_icon} alt="MergList" />
                                    </div>

                                    {mergDropDown && (
                                        <div ref={ref} className='bg-[#1C1C26] min-w-[100px] absolute z-[1] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent top-[25px] right-[0px] text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]' >
                                            {!store?.singleCampaignData?.readyToExport && <div onClick={() => {
                                                setVariableModal(true)
                                                setMergDropDown(false)
                                            }} className=" p-[3px] mb-2 text-[#fff] cursor-pointer bg-[#5446d0] text-center rouned-[3px] rounded-[3px] text-[13px]">Add</div>}
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopupHyperText(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F] rounded-[3px]">
                                                    {employee.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <textarea
                                        value={hyperclipTTSconfig.text}
                                        onChange={(e) => handleChangeHyperclipTTSconfig(e)}
                                        name='text'
                                        onKeyDown={handleBackspace}
                                        className="bg-[#3B3B4F] rounded-[4px] p-[8px] 2xl:p-[10px] h-[60px] 2xl:h-[100px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                        onDoubleClick={(e) => { handleDoubleClickOnHyperText(e) }}
                                        placeholder="Please enter text"
                                        id='hyperclip'
                                        required
                                    />
                                    {popupVisible && headers?.length > 0 && (
                                        <div ref={mergeTagsPopupRef} className='bg-[#1C1C26] absolute top-0 right-0  text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                                            {headers && headers?.map((employee, index) => (
                                                <div key={index} onClick={() => handlePopupHyperText(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                    {employee.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                            {/* language */}
                            <div className="p-[10px] 2xl:p-[20px]">
                                <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Language</h1>
                                <div className=' relative'>
                                    <select id="languageSelector" required className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none capitalize" name='language' value={hyperclipTTSconfig.language} onChange={(e) => handleChangeHyperclipTTSconfig(e)}>
                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Language</option>
                                        {hyperclipTTS.map((ele, i) => {
                                            return (
                                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F] capitalize' value={ele.language}> {ele.flag} {ele.language}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                </div>
                            </div>
                            <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                            {/* Gender */}
                            {hyperclipTTS && hyperclipTTS?.some(ele => ele.language === hyperclipTTSconfig.language) &&
                                (<>
                                    <div className="p-[10px] 2xl:p-[20px]">
                                        <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Gender</h1>
                                        <div className=' relative'>
                                            <select id="languageSelector" required className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none" name='gender' value={hyperclipTTSconfig.gender} onChange={(e) => handleChangeHyperclipTTSconfig(e)}>
                                                <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Gender</option>
                                                {hyperclipTTS?.find(ele => ele.language === hyperclipTTSconfig.language)?.isMaleAvailable &&
                                                    (
                                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="male">Male</option>
                                                    )
                                                }
                                                {hyperclipTTS?.find(ele => ele.language === hyperclipTTSconfig.language)?.isFemaleAvailable &&
                                                    (
                                                        <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="female">Female</option>
                                                    )
                                                }
                                            </select>
                                            <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                        </div>
                                    </div>
                                    <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                                </>)
                            }

                            {/* Voices */}
                            {hyperclipTTSconfig?.language?.length > 0 && (hyperclipTTSconfig.gender === 'male' || hyperclipTTSconfig.gender === "female") &&
                                (
                                    <>
                                        <div className="p-[10px] 2xl:p-[20px] w-full">
                                            <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Voices</h1>
                                            <div className=' relative mt-[16px]'>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    name="speaker"
                                                    menuPlacement="auto" // Automatically decide placement based on available space
                                                    menuShouldScrollIntoView={false}
                                                      menuPortalTarget={document.body} // Render the dropdown in a fixed position relative to the body
                                                    menuPosition="fixed"
                                                    required
                                                    options={voiceOption}
                                                    value={
                                                        hyperclipTTSconfig.speaker && voiceOption
                                                            ? voiceOption.find((ele) => ele.label === hyperclipTTSconfig.speaker)
                                                            : null // Reset speaker when the value is empty
                                                    }
                                                    styles={customStyles}
                                                    ref={selectRef}
                                                    placeholder="Select Voice"
                                                    onChange={(options) => {
                                                        setHyperclipTTSconfig({ ...hyperclipTTSconfig, speaker: options?.label });
                                                    }}
                                                    components={{
                                                        Option: customOption,
                                                    }}
                                                />
                                                <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[45%] transform translate-x-[-50%]'></img>
                                            </div>
                                        </div>
                                        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                                    </>
                                )
                            }
                            {/* Add Button  */}
                            <div className="flex justify-center px-[10px] 2xl:px-[20px] py-[20px]">
                                <button type='submit' className="py-[10px] rounded w-full bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] mx-auto outline-none">
                                    Add
                                </button>
                            </div>
                        </form>
                    )
                    }
                    {engine === 'openai' && (
                        <form onSubmit={handleAddButtonClick}>
                            <div>
                                {/* text  */}
                                <div className="p-[10px] 2xl:p-[20px]">
                                    <div className="flex justify-between items-center relative">
                                        <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">Text</h3>
                                        <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                                            setMergDropDown(true)
                                        }} >
                                            <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                                            <img src={merge_list_icon} alt="MergList" />
                                        </div>

                                        {mergDropDown && (
                                            <div ref={ref} className='bg-[#1C1C26] absolute min-w-[100px] z-[1] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent top-[25px] right-[0px] text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]' >
                                                {!store?.singleCampaignData?.readyToExport && <div onClick={() => {
                                                    setVariableModal(true)
                                                    setMergDropDown(false)
                                                }} className=" p-[3px] mb-2 text-[#fff] cursor-pointer bg-[#5446d0] text-center rouned-[3px] rounded-[3px] text-[13px]">Add</div>}
                                                {headers && headers?.map((employee, index) => (
                                                    <div key={index} onClick={() => handlePopup(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F] rounded-[3px]">
                                                        {employee.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <textarea
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            className="bg-[#3B3B4F] rounded-[4px] p-[8px] 2xl:p-[10px] h-[60px] 2xl:h-[100px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                            onDoubleClick={handleDoubleClick}
                                            onKeyDown={handleBackspaceForOpenAi}
                                            placeholder="Default Text"
                                            id='openai'
                                            required
                                        />
                                        {popupVisible && headers?.length > 0 && (
                                            <div ref={mergeTagsPopupRef} className='bg-[#1C1C26] absolute top-0 right-0  text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                                                {headers && headers?.map((employee, index) => (
                                                    <div key={index} onClick={() => handlePopup(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                        {employee.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                                {/* language  */}
                                <div className="p-[10px] 2xl:p-[20px]">

                                    <h1 className="font-Inter font-medium text-sm text-[#ABABC0]"> Language</h1>
                                    <div className=' relative'>
                                        <select
                                            id="languageSelector"
                                            className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                            required
                                        >
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Language</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="english">English</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="hindi">Hindi</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="chinese">Chinese HK</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="french">French(Canada)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="marathi">Marathi</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="tamil">Tamil</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="urdu">Urdu</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="nepali">Nepali</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="russian">Russian</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="korean">Korean</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="japanese">Japanese</option>
                                        </select>
                                        <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                    </div>
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />
                                <div className="p-[10px] 2xl:p-[20px]">

                                    <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Voices</h1>
                                    <div className=' relative'>
                                        <select
                                            id="languageSelector"
                                            className="bg-[#3B3B4F] p-[10px] cursor-pointer text-[14px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                            value={voice}
                                            onChange={(e) => setVoice(e.target.value)}
                                            required
                                        >
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="">Select Voices</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="alloy">Alloy (Male)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="echo">Echo (Male)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="fable">Fable (Male)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="onyx">Onyx (Male)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="nova">Nova (Female)</option>
                                            <option className='bg-[#1C1C26] hover:bg-[#3B3B4F]' value="shimmer">Shimmer (Female)</option>
                                        </select>
                                        <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[60%] transform translate-x-[-50%]'></img>
                                    </div>
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />


                                {/* speed  */}
                                <div className="p-[10px] 2xl:p-[20px]">
                                    <div className="flex justify-between items-center pb-[10px] 2xl:pb-[16px]">
                                        <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] ">Speed</h3>
                                        <button onClick={() => setSpeed(1)} type='button' className="flex gap-[6px] items-center outline-none">
                                            <img src={reset_icon} alt="Reset" />
                                            <span className="font-Inter font-medium text-xs text-[#FFFFFF]">Reset</span>
                                        </button>
                                    </div>
                                    <div className="flex justify-between items-center pb-[10px] w-[100%] ">
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-start">0.50</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">0.75</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.00</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.25</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.50</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-center">1.75</p>
                                        <p className="font-Inter font-medium text-[#FFFFFF] text-[10px] 2xl:text-[12px] text-end">2.00</p>
                                    </div>
                                    <input
                                        type="range"
                                        className={`bg-[#ABABC0] w-full ${isDrag ? "cursor-grabbing" : "cursor-grab"}`}
                                        value={speed}
                                        onMouseDown={(e) => { setIsDrag(true) }}
                                        onMouseUp={(e) => { setIsDrag(false) }}
                                        onChange={handleRangeChange}
                                        step="0.25"
                                        min={0.50}
                                        max={2}
                                    />
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px] " />


                                {/* Add Button  */}
                                <div className="flex justify-center px-[10px] 2xl:px-[20px] py-[20px]">
                                    <button
                                        className="py-[10px] rounded w-full bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] mx-auto outline-none"
                                        type='submit'
                                    >
                                        Add
                                    </button>
                                </div>

                                <button className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible outline-none">
                                    Preview
                                </button>
                                <div className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible">
                                    {audioData && (
                                        <audio id="audioPlayer" controls onLoadedData={() => {
                                            const audioLength = ref.current?.duration ?? 0;
                                            setFormatedAudioLength(formatTimeToMinSec(audioLength));
                                        }}>
                                            {/* <source src={`https://hooc.b-cdn.net/${audioData}`} type="audio/mpeg" /> */}
                                            <source src={audioData} type="audio/mpeg" />
                                        </audio>
                                    )}
                                </div>

                            </div>
                        </form>
                    )
                    }
                    {engine === 'elevenLabs' && (
                        <form onSubmit={handleAddButtonClick}>
                            <div>
                                {/* text  */}
                                <div className="p-[10px] 2xl:p-[20px]">
                                    <div className="flex justify-between items-center relative">
                                        <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">Text</h3>
                                        <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                                            setMergDropDown(true)
                                        }} >
                                            <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                                            <img src={merge_list_icon} alt="MergList" />
                                        </div>

                                        {mergDropDown && (
                                            <div ref={ref} className='bg-[#1C1C26] absolute min-w-[100px] z-[1] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent top-[25px] right-[0px] text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px]' >
                                                {!store?.singleCampaignData?.readyToExport && <div onClick={() => {
                                                    setVariableModal(true)
                                                    setMergDropDown(false)
                                                }} className=" p-[3px] mb-2 text-[#fff] cursor-pointer bg-[#5446d0] text-center rouned-[3px] rounded-[3px] text-[13px]">Add</div>}
                                                {headers && headers?.map((employee, index) => (
                                                    <div key={index} onClick={() => handlePopupElevenlab(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F] rounded-[3px]">
                                                        {employee.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <textarea
                                            value={elevenlabText}
                                            onChange={(e) => setElevenLabText(e.target.value)}
                                            className="bg-[#3B3B4F] rounded-[4px] p-[8px] 2xl:p-[10px] h-[60px] 2xl:h-[100px] w-full mt-[16px] text-[#FFFFFF] outline-none"
                                            onDoubleClick={handleDoubleClick}
                                            onKeyDown={handleBackspaceForElevenLab}
                                            placeholder="Default Text"
                                            id='elevanlab'
                                            required
                                        />
                                        {popupVisible && headers?.length > 0 && (
                                            <div ref={mergeTagsPopupRef} className='bg-[#1C1C26] absolute top-0 right-0  text-[#ABABC0] rounded-[6px] gap-[5px] p-[10px] max-h-[200px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                                                {headers && headers?.map((employee, index) => (
                                                    <div key={index} onClick={() => handlePopupElevenlab(employee.name)} className="cursor-pointer p-[5px] hover:bg-[#3B3B4F]">
                                                        {employee.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                                <div className="p-[10px] 2xl:p-[20px] w-full">
                                    <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Voices</h1>
                                    <div className=' relative mt-[16px]'>
                                        <Select
                                            closeMenuOnSelect={true}
                                            name="speaker"
                                            menuPlacement="auto" // Automatically decide placement based on available space
                                            menuShouldScrollIntoView={false}
                                              menuPortalTarget={document.body} // Render the dropdown in a fixed position relative to the body
                                            menuPosition="fixed"
                                            required
                                            options={ElevenVoiceOptions}
                                            value={ElevenVoiceOptions
                                                ? ElevenVoiceOptions.find((ele) => ele.value === voiceEleven)
                                                : null // Reset speaker when the value is empty
                                            }
                                            styles={customStyles}
                                            ref={selectRef}
                                            placeholder="Select Voice"
                                            onChange={(options) => {
                                                setVoiceEleven(options?.value);
                                            }}
                                            components={{
                                                Option: customOptionForElevenLab,
                                            }}
                                        />
                                        <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[45%] transform translate-x-[-50%]'></img>
                                    </div>
                                </div>

                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                                <div className="p-[10px] 2xl:p-[20px]">
                                    <h1 className="font-Inter font-medium text-sm text-[#ABABC0]">Models</h1>
                                    <div onClick={() => { setScreen('models') }} className=' relative bg-[#3B3B4F] mt-[16px] min-h-[40px] p-[9px] cursor-pointer'>
                                        <p className=' font-Inter text-[#fff] font-light  text-[14px]' >{elevenModal ? elevenModal?.name : 'Model Name'}</p>
                                        <img src={droparrow} alt='droparrow' className=' absolute right-2 top-[50%] -rotate-90 transform translate-x-[-50%]'></img>
                                    </div>
                                </div>
                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />


                                {/* Stability  */}
                                <div className="px-[10px] 2xl:px-[20px] py-[10px]">
                                    <div className="flex justify-between items-center pb-[10px] 2xl:pb-[16px]">
                                        <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] ">Stability</h3>
                                    </div>
                                    <div className="flex justify-between items-center pb-[5px] w-[100%] ">
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-start">More variable</p>
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-end">More stable</p>
                                    </div>
                                    <div className=' relative'>
                                        {ISDragStability && (
                                            <div
                                                className="absolute top-[-30px] left-0 text-white bg-[#333] px-2 py-1 rounded text-sm"
                                                style={{ transform: `translateX(${tooltipPosition - 15}px)` }} // Center the tooltip
                                            >
                                                {stability}
                                            </div>
                                        )}
                                        <input
                                            type="range"
                                            className={`bg-[#ABABC0] w-full ${ISDragStability ? "cursor-grabbing" : "cursor-grab"}`}
                                            value={stability}
                                            onMouseDown={(e) => { setISDragStability(true) }}
                                            onMouseUp={(e) => { setISDragStability(false) }}
                                            onChange={(e) => {
                                                setStability(e.target.value)
                                                const inputElement = e.target;
                                                const percentage = (e.target.value - inputElement.min) / (inputElement.max - inputElement.min);
                                                const newTooltipPosition = percentage * inputElement.offsetWidth;
                                                setTooltipPosition(newTooltipPosition);
                                            }}
                                            min={0}
                                            max={100}
                                        />
                                    </div>
                                </div>

                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                                {/* Similarity  */}
                                <div className="px-[10px] 2xl:px-[20px] py-[10px]">
                                    <div className="flex justify-between items-center pb-[10px] 2xl:pb-[16px]">
                                        <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] ">Similarity</h3>
                                    </div>
                                    <div className="flex justify-between items-center pb-[5px] w-[100%] ">
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-start">Low</p>
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-end">High</p>
                                    </div>
                                    <div className=' relative'>
                                        {ISDragSimilarity && (
                                            <div
                                                className="absolute top-[-30px] left-0 text-white bg-[#333] px-2 py-1 rounded text-sm"
                                                style={{ transform: `translateX(${tooltipPosition - 15}px)` }} // Center the tooltip
                                            >
                                                {similarity}
                                            </div>
                                        )}
                                        <input
                                            type="range"
                                            className={`bg-[#ABABC0] w-full ${ISDragSimilarity ? "cursor-grabbing" : "cursor-grab"}`}
                                            value={similarity}
                                            onMouseDown={(e) => { setISDragSimilarity(true) }}
                                            onMouseUp={(e) => { setISDragSimilarity(false) }}
                                            onChange={(e) => {
                                                setSimilarity(e.target.value)
                                                const inputElement = e.target;
                                                const percentage = (e.target.value - inputElement.min) / (inputElement.max - inputElement.min);
                                                const newTooltipPosition = percentage * inputElement.offsetWidth;
                                                setTooltipPosition(newTooltipPosition);
                                            }}
                                            min={0}
                                            max={100}
                                        />
                                    </div>
                                </div>

                                <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

                                {/* Style  */}
                                {elevenModal && elevenModal.can_use_style && <div className="px-[10px] 2xl:px-[20px] py-[10px]">
                                    <div className="flex justify-between items-center pb-[10px] 2xl:pb-[16px]">
                                        <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] ">Style Exaggeration</h3>
                                    </div>
                                    <div className="flex justify-between items-center pb-[5px] w-[100%] ">
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-start">None</p>
                                        <p className="font-Inter font-medium text-[#ababc0] text-[12px] text-end">Exaggerated</p>
                                    </div>
                                    <div className=' relative'>
                                        {isDragStyleExaggeration && (
                                            <div
                                                className="absolute top-[-30px] left-0 text-white bg-[#333] px-2 py-1 rounded text-sm"
                                                style={{ transform: `translateX(${tooltipPosition - 15}px)` }} // Center the tooltip
                                            >
                                                {styleExaggeration}
                                            </div>
                                        )}
                                        <input
                                            type="range"
                                            className={`bg-[#ABABC0] w-full ${isDragStyleExaggeration ? "cursor-grabbing" : "cursor-grab"}`}
                                            value={styleExaggeration}
                                            onMouseDown={(e) => { setIsDragStyleExaggeration(true) }}
                                            onMouseUp={(e) => { setIsDragStyleExaggeration(false) }}
                                            onChange={(e) => {
                                                setStyleExaggeration(e.target.value)
                                                const inputElement = e.target;
                                                const percentage = (e.target.value - inputElement.min) / (inputElement.max - inputElement.min);
                                                const newTooltipPosition = percentage * inputElement.offsetWidth;
                                                setTooltipPosition(newTooltipPosition);
                                            }}
                                            min={0}
                                            max={100}
                                        />
                                    </div>
                                </div>}

                                <div className={`${elevenModal && elevenModal.can_use_speaker_boost ? ' flex items-center justify-between' : ' flex  justify-end'} px-[10px] 2xl:px-[20px] py-[10px]`}>
                                    {elevenModal && elevenModal.can_use_speaker_boost && <label
                                        className="flex items-center cursor-pointer"
                                    >
                                        <p className=' font-Inter text-[#ababc0] font-medium mr-2 text-[14px]'>Speaker boost</p>
                                        <div className=' relative'>
                                            <input
                                                type="checkbox"
                                                id="toggleB"
                                                className="sr-only"
                                                checked={speakerBoost}
                                                onChange={(e) => setSpeakerBoost(e.target.checked)}
                                            />
                                            <div
                                                className={`block bg-[#3B3B4F] w-[42px] h-[22px] rounded-full ${speakerBoost ? "bg-[#5446d0]" : "bg-gray-500"
                                                    }`}
                                            ></div>
                                            <div
                                                className={` absolute ${speakerBoost ? "right-[1px]" : "left-[1px] "
                                                    } top-[1px] bg-white w-[20px] h-[20px] rounded-full transition`}
                                            ></div>
                                        </div>
                                    </label>}
                                    <button onClick={(e) => { handelReset(e) }} type='button' className="flex gap-[6px] items-center outline-none">
                                        <img src={reset_icon} alt="Reset" />
                                        <span className="font-Inter font-medium text-xs text-[#FFFFFF]"> Reset</span>
                                    </button>
                                </div>


                                {/* Add Button  */}
                                <div className="flex justify-center px-[10px] 2xl:px-[20px] py-[20px]">
                                    <button
                                        className="py-[10px] rounded w-full bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] mx-auto outline-none"
                                        type='submit'
                                    >
                                        Add
                                    </button>
                                </div>

                                <button className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible outline-none">
                                    Preview
                                </button>
                                <div className="px-12 py-3 border-2 shadow-lg mt-2 hover:bg-[#D3D3D3] invisible">
                                    {audioData && (
                                        <audio id="audioPlayer" controls onLoadedData={() => {
                                            const audioLength = ref.current?.duration ?? 0;
                                            setFormatedAudioLength(formatTimeToMinSec(audioLength));
                                        }}>

                                            <source src={audioData} type="audio/mpeg" />
                                        </audio>
                                    )}
                                </div>

                            </div>
                        </form>
                    )
                    }
                </div>
            }
            {screen === "sidepanel" &&
                <SpeechSidePanel />
            }
            {screen === "models" &&
                <div className=' p-[10px] '>
                    <img
                        src={left_arrow_icon}
                        alt="arrow"
                        className="cursor-pointer p-[10px] rounded bg-[#1C1C26]"
                        onClick={() => {
                            setScreen("speech");
                        }}
                    />
                    {ElevenModalList?.filter(ele => ele?.can_do_text_to_speech)?.map((ele, i) => {
                        return (
                            <div key={i} onClick={() => {
                                setElevenModal(ele)
                                setScreen('speech')
                            }} className={`relative ${elevenModal?.model_id === ele?.model_id ? 'border border-[#5446d0]' : ''} hover:shadow-xl bg-[#3B3B4F] mt-[16px] rounded-[6px] p-[9px] cursor-pointer`}>
                                <p className=' font-Inter text-[#fff] font-medium text-[14px]'>{ele.name}</p>
                                <p className=' font-Inter text-[#ababc0] font-light mt-1 text-[13px]'>{ele.description}</p>
                                <div className=' flex flex-wrap gap-1 2xl:gap-2 mt-2'>
                                    {ele.languages && ele?.languages?.slice(0, 3).map((lang, i) => {
                                        return (
                                            <p key={i} className=' font-Inter text-[#ababc0] bg-[#1e1e29] p-[7px]  rounded-[6px] font-light text-[12px]'>{lang.name}</p>
                                        )
                                    })}
                                    <div className='group relative'>
                                    {ele.languages?.length > 3 && <p className=' font-Inter text-[#ababc0] bg-[#1e1e29] p-[7px] rounded-[6px] font-light text-[12px]'>+{ele.languages.length - 3}</p>}
                                <div className='group-hover:block hidden absolute top-[35px] z-[999] max-h-[150px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent 2xl:right-0 bg-[#1C1C26] p-[5px] rounded-[6px]'>
                                {ele?.languages && ele?.languages?.slice(3).map((lang, i) => {
                                        return (
                                            <p key={i} className='font-Inter text-[#ababc0] font-light text-[12px]'>
                                                {lang.name}
                                            </p>
                                        );
                                    })}
                                </div>
                                </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}
        </>
    )
}

export default observer(SpeechPanel)