import React, { useEffect, useRef, useState } from "react";
import plus_icon from "../../assets/icons/plus.svg";
import video_upload from "../../assets/icons/video_upload.svg";
import rename from "../../assets/rename_edit.svg";
import menu_icon from "../../assets/menu_icon.png";
import no_campaign from "../../assets/no_campaign.png";
import exclamation_mark from "../../assets/icons/exclamation_mark_red.png";
import { localStorageNames } from "../../configs/constants";
import APICallLoader from "../../components/ui/common/APILoaderRing";
import { useStore } from "../../utility/hooks/UseStore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import deleteicon from "../../assets/delete_icon.png";
import round_clear from "../../assets/round_close.png";
import { decryptData } from "../../utility/utils";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import UseOutsideClick from "../../components/commanComponents/closeToggel";

const { __USER_DATA , __PLAN_DATA } = localStorageNames;

const Video = () => {
  const navigate = useNavigate();
  //get Store Here
  const store = useStore();
  // state For Create New Campaign
  const [createCampaignModel, setCreateCampaignModel] = useState(false);
  const [runValidator, setRunValidator] = useState(false);
  const [apiCallLoader, setApiCallLoader] = useState(true);
  // const [loader, setLoader] = useState(true);
  const [error, setError] = useState({});
  const [createCampaignData, setCreateCampaignData] = useState({
    campaign: "Untitled video",
    video: {},
  });
  const [page, setPage] = useState(1)

  const ref= useRef();
  UseOutsideClick(ref, () => {
    if (createCampaignModel) {
      setCreateCampaignModel(false);
    }
});
  // set State On input Change For Both Rename Campaign & Create New Campaign
  // function onInputHandler(e, from) {
  //   const { name, value, files } = e.target;
  //   if (from === "renameCampaign") {
  //     setRenameCampaign((prevData) => ({
  //       ...prevData,
  //       [name]: value.trimStart(),
  //     }));
  //   } else {
  //     setCreateCampaignData((prevData) => ({
  //       ...prevData,
  //       [name]:
  //         e.target.type !== "file"
  //           ? value.trimStart()
  //           : files?.length > 0
  //             ? files[0]
  //             : {},
  //     }));
  //     if (e.target.type === "file") {
  //       const video = document.createElement("video");
  //       video.src = files?.length > 0 && URL.createObjectURL(files[0]);
  //       video.addEventListener("loadedmetadata", function () {
  //         const duration = video.duration;
  //         setDuration(duration);
  //       });
  //     }
  //   }
  // }
  // Function to handle input changes
function onInputHandler(e, from) {
  const { name, value, files } = e.target;

  if (from === "renameCampaign") {
    setRenameCampaign((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  } else {
    setCreateCampaignData((prevData) => ({
      ...prevData,
      [name]:
        e.target.type !== "file"
          ? value.trimStart()
          : files?.length > 0
          ? files[0]
          : {},
    }));

    if (e.target.type === "file") {
      // Validate the uploaded video
      if (files?.length > 0) {
        const videoFile = files[0];
        const video = document.createElement("video");
        video.src = URL.createObjectURL(videoFile);

        video.addEventListener("loadedmetadata", () => {
          const duration = video.duration;
          const sizeMB = videoFile.size / (1024 * 1024);

          setDuration(duration);
          validateVideo(duration, sizeMB);
        });

        video.addEventListener("error", () => {
          setError((prevError) => ({
            ...prevError,
            video: "Invalid video file. Please upload a valid MP4 file.",
          }));
        });
      }
    }
  }
}

// Video validation logic
function validateVideo(duration, sizeMB) {
  const maxSize = Number(savedPlanData?.maxVideoSize);
  const maxDuration = Number(savedPlanData?.maxVideoSec);
  let errorMessage = "";

  if (sizeMB > maxSize) {
    errorMessage += `Video size exceeds ${maxSize} MB. `;
  }
  if (duration > maxDuration) {
    errorMessage += `Video duration exceeds ${maxDuration} seconds.`;
  }

  setError((prevError) => ({
    ...prevError,
    video: errorMessage || "",
  }));

  return errorMessage.length > 0;
}

var nameRegex =  /^[a-zA-Z0-9 _&!-]+$/;
// Validation function for the form
function validateRegisterData() {
  let error = {};
  let isErrorFound = false;

  if (!createCampaignData.campaign.trim()) {
    error.campaign = "Campaign name is required.";
    isErrorFound = true;
  } else if (!nameRegex.test(createCampaignData.campaign)) {
    error.campaign = "No special characters are allowed in the name.";
    isErrorFound = true;
  } else {
    delete error.campaign;
  }

  if (!createCampaignData.video.name) {
    error.video = "Video is required.";
    isErrorFound = true;
  } else if (!createCampaignData.video.name.endsWith(".mp4")) {
    error.video = "Only MP4 format is allowed for video files.";
    isErrorFound = true;
  } else if (
    createCampaignData.video.size / 1024 / 1024 > Number(savedPlanData?.maxVideoSize)) {
    error.video = `Video exceeds allowed size ${savedPlanData?.maxVideoSize} MB.`;
    isErrorFound = true;
  }else if(Number(duration) > Number(savedPlanData?.maxVideoSec)){
    error.video = `Video duration exceeds ${savedPlanData?.maxVideoSec} seconds.`;
    isErrorFound = true;
  }else {
    delete error.video;
  }

  setError(error);
  return isErrorFound;
}

// Reset video state when clearing input
function clearVideoInput() {
  setCreateCampaignData((prevData) => ({
    ...prevData,
    video: {},
  }));
  setDuration(null);
  setError((prevError) => ({
    ...prevError,
    video: "",
  }));
  if (fileinputRef.current) {
    fileinputRef.current.value = ""; // Reset the file input field
  }
}

  const [duration, setDuration] = useState();

  const savedPlanData = decryptData((localStorage.getItem(__PLAN_DATA)));
  // Final submit Create Campaign


  function createCampaignHandler() {
    const isError = validateRegisterData();
    setRunValidator(true);
    if (isError) return;
    setApiCallLoader(true);
    var formdata = new FormData();
    formdata.append("campaignName", createCampaignData.campaign);
    formdata.append("video", createCampaignData.video);
    formdata.append("duration", duration);
    formdata.append('size' , parseFloat(createCampaignData.video.size / 1024 / 1024).toFixed(2))

    store.videoStore.CreateCampaign({
      formdata,
      loader: setApiCallLoader,
      setCreateCampaignModel,
      setCampaignListFromStore,
      limit: 9,
      page: 1,
       history
    });
    // history({
    //   pathname: "/videos",
    //   search: `?limit=${9}&page=${1}`,
    // });
  }


  // clear State of Create New Campaign When Modal Close
  useEffect(() => {
    if (!createCampaignModel) {
      setRunValidator(false);
      setError({});
      setCreateCampaignData({
        campaign: "Untitled video",
        video: {},
      });
    }
  }, [createCampaignModel]);

  // state for Rename Campaign
  const [campaignNamePopup, setCampaignNamePopup] = useState(false);
  const [renameCampaign, setRenameCampaign] = useState({
    campaignID: "",
    updatedCampaignName: "",
    errorOfCampaignName: "",
    runValidation: false,
  });


  // Open Popup For campaign Rename
  function handleCampaignRenamePopUp(campaign) {
    setCampaignNamePopup(true);
    setRenameCampaign((prevData) => ({
      ...prevData,
      updatedCampaignName: campaign?.campaignName,
      campaignID: campaign._id,
    }));
  }

  // Final Submit For Rename Campaign
  function RenameCampaignHandel() {
    const error = validateRenameCampaign();
    setRenameCampaign((prevData) => ({
      ...prevData,
      runValidation: true,
    }));
    if (error) return;
    const finalData = {
      campaignName: renameCampaign.updatedCampaignName,
    };
    store.videoStore.UpdateCampaignName({
      finalData,
      campaignID: renameCampaign.campaignID,
      loader: setApiCallLoader,
      setCampaignNamePopup,
      setCampaignListFromStore,
      // limit: limit,
      // page : newpage
    });
  }

  // validation For Create Campaign
  function validateRenameCampaign() {
    let isErrorFound = false;
    if (!renameCampaign.updatedCampaignName.trim()) {
      setRenameCampaign((prevData) => ({
        ...prevData,
        errorOfCampaignName: "Campaign name is required.",
      }));
      isErrorFound = true;
    } else if (!nameRegex.test(renameCampaign.updatedCampaignName)) {
      setRenameCampaign((prevData) => ({
        ...prevData,
        errorOfCampaignName: "No Special Character Allow in Name.",
      }));
      isErrorFound = true;
    } else {
      setRenameCampaign((prevData) => ({
        ...prevData,
        errorOfCampaignName: "",
      }));
    }
    return isErrorFound;
  }

  // for Run Validation When User Enter in any Input Field For Rename Campaign
  useEffect(() => {
    const timer = setTimeout(() => {
      if (renameCampaign.runValidation) {
        validateRenameCampaign();
      }
    }, 150);
    return () => clearTimeout(timer);
  }, [renameCampaign.updatedCampaignName]);

  // For Clear All Campaign Data When Rename Campaign Popup Close
  useEffect(() => {
    if (!campaignNamePopup) {
      setRenameCampaign({
        campaign: "",
        updatedCampaignName: "",
        errorOfCampaignName: "",
        runValidation: false,
      });
    }
  }, [campaignNamePopup]);

  // campaign Delete state
  const [deleteCampaign, setDeleteCampaign] = useState({
    campaignID: "",
    popupOpen: false,
  });

  //campaign list states
  const [campaignListFromStore, setCampaignListFromStore] = useState(false);
  const [campaignList, setCampaignList] = useState("");
  const [campaignLimit, setcampaignLimit] = useState(9);
  const location = useLocation();
  const history = useNavigate();
  //default call For Campaign list
  const param = new URLSearchParams(location.search);
  const limit = param.get("limit");

  useEffect(() => {
    const sds = location.search.slice(1).split("&");
    const limit = param.get("limit");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/videos",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            limit: limit ? limit : campaignLimit,
            page: page
          }).toString(),
      });
    } else {
      history({
        pathname: "/videos",
        search: `?limit=${limit ? limit : campaignLimit}&page=${page}`,
      });
    }
    store.videoStore.getCampaignList({
      setCampaignListFromStore,
      loader: setApiCallLoader,
      limit: limit ? limit : campaignLimit,
      page: page
    });
  }, [campaignLimit]);

  //get Campaign List From Store and set in state
  const [totalCampaign, setTotalCampaign] = useState('')
  useEffect(() => {
    if (campaignListFromStore) {
      setCampaignList(store.videoStore.campaignList?.campaign);
      setTotalCampaign(store.videoStore.campaignList.totalCampaign)
      setCampaignListFromStore(false);
    }
  }, [campaignListFromStore]);


  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [templateId, setTemplateId] = useState("");

  const viewMoreCampaign = () => {
    const sds = location.search.slice(1).split("&");
    const limit = param.get("limit");
    const newpage = param.get("page");
    setPage(Number(page) + Number(1))
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/videos",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            limit: Number(limit),
            page: Number(newpage) + Number(1)
          }).toString(),
      });
    } else {
      history({
        pathname: "/videos",
        search: `?limit=${Number(limit)}&page=${Number(newpage) + Number(1)}`,
      });
    }
    store.videoStore.getCampaignList({
      setCampaignListFromStore,
      loader: setApiCallLoader,
      limit: Number(limit),
      page: Number(newpage) + Number(1),
      viewMore : true
    });
  };

  const LessMoreCampaign = () => {
    const sds = location.search.slice(1).split("&");
    const limit = param.get("limit");
    const newpage = param.get("page");
    setPage(Number(page) - Number(1))
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/videos",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            limit: Number(limit),
            page: Number(newpage) - Number(1)
          }).toString(),
      });
    } else {
      history({
        pathname: "/videos",
        search: `?limit=${Number(limit)}&page=${Number(newpage) - Number(1)}`,
      });
    }
    const startIndex = (newpage - 1) * 9;
    const endIndex = startIndex + 9;

    // Create a new array excluding campaigns for the current page
    const newCampaigns = [
      ...campaignList.slice(0, startIndex),
      ...campaignList.slice(endIndex),
    ];

    // Update the campaign list
    setCampaignList(newCampaigns);
  };


  const fileinputRef = useRef(null);

  const querypage = param.get("page");

  
  const lastEditedDate = (dateString) => {
    const date = new Date(dateString);
    return  `${String(date.getDate()).padStart(2, '0')}/${String(
  date.getMonth() + 1
).padStart(2, '0')}/${date.getFullYear()}`;
  }

  return (
    <>
      {/* Loader When api Call */}
      <div
        className={`${apiCallLoader ? "flex" : "hidden"
          } fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}
      >
        <APICallLoader />
      </div>

      {/* Create New Campaign with excel and video  */}
      {createCampaignModel && (
        <div
          className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}
        >
          <div ref={ref} className="min-w-[420px] relative flex justify-center items-center">
            <div
              onClick={() => {
                setCreateCampaignModel(false);
              }}
              className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1] "
            ></div>
          
              <div className=" w-full py-[12px] px-[20px] bg-[#272836] flex flex-col items-center z-[1] rounded-[8px]">
                <img
                  src={plus_icon}
                  alt=""
                  onClick={() => {
                    setCreateCampaignModel(false);
                  }}
                  className="cursor-pointer rotate-45 self-end"
                />
                <div className="w-[100%]">
                  <div className="w-[100%] relative">
                    <label
                      htmlFor="campaign"
                      className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
                    >
                      Campaign  Name <span className="text-[#FF5364]">*</span>
                    </label>
                    <input
                      name="campaign"
                      className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                      placeholder="Enter campaign name"
                      maxLength={40}
                      type="text"
                      autoComplete="off"
                      value={createCampaignData.campaign}
                      onChange={(e) => {
                        onInputHandler(e);
                      }}
                    ></input>
                    <p className="text-[12px] leading-[14.52px] font-medium text-[#FF5364] absolute bottom-[-18px]">
                      {error.campaign ? error.campaign : ""}
                    </p>
                    {error.campaign && (
                      <img
                        src={exclamation_mark}
                        alt="*"
                        className="absolute top-[50px] right-[10px] w-[20px]"
                      />
                    )}
                  </div>
                  <div className="w-[100%] relative my-[20px]">
                    <label
                      htmlFor="video"
                      className="font-Inter font-semibold text-[14px] text-[#fff]"
                    >
                      Video <span className="text-[#FF5364]">*</span>
                    </label>
                    <div
                      className={`mt-[10px] py-[8.5px] px-[8px]  rounded-[6px] border border-[#3B3B4F] transition-all w-[100%] flex items-center `}
                    >
                      <input
                        onChange={(e) => {
                          onInputHandler(e);
                        }}
                        ref={fileinputRef}
                        type="file"
                        name="video"
                        accept=".mp4"
                        autoFocus
                        placeholder="Enter Folder Name Here..."
                        className={`absolute w-[100%] h-[54%] top-[38px] rounded-[3px] left-[0px] p-[10px] cursor-pointer opacity-[0]`}
                      />
                      <p className="h-[100%] text-[#ABABC0] bg-[#3B3B4F] flex p-[8px] rounded-[3px] items-center font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] mr-[10px]">
                        <img
                          src={video_upload}
                          alt="video"
                          className="mr-[10px]"
                        />{" "}
                        Choose Files
                      </p>
                      {createCampaignData?.video?.name && <img
                        src={round_clear}
                        alt=""
                        onClick={() => {
                          clearVideoInput()
                        }
                      }
                        className="cursor-pointer absolute w-[17px] top-[52px] right-[35px]"
                      />}
                      {createCampaignData.video.name !== undefined ? (
                        <span className="font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] text-[#ABABC0]">
                          {String(createCampaignData.video.name).length < 15
                            ? createCampaignData.video.name
                            : `${createCampaignData.video.name.substring(
                              0,
                              15
                            )}...`}
                        </span>
                      ) : (
                        <span className="font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] text-[#ABABC0]">
                          Max file size{" "}
                          <span className="text-[#FFFFFF]">{Number(savedPlanData?.maxVideoSize)}MB</span>
                        </span>
                      )}

                      {error.video && (
                        <img
                          src={exclamation_mark}
                          alt="*"
                          className="absolute top-[50px] right-[10px] w-[20px]"
                        />
                      )}
                    </div>
                    <p className="text-[12px] leading-[14.52px] absolute bottom-[-18px] font-medium text-[#FF5364]">
                      {error.video ? error.video : ""}
                    </p>
                  </div>
                  <div className="w-[100%] flex justify-center items-center mt-[30px] mb-[8px]">
                    <button
                      className="rounded-[6px] border-[1px] text-[14px] 2xl:text-[16px] min-w-[130px] py-[11px] px-[36px] text-[#FFFFFF] border-[#5446D0] font-Inter font-medium text=[14px] leading-[16.94px] tracking-[0.48px]"
                      onClick={() => {
                        setCreateCampaignModel(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="rounded-[6px] border-[1px] text-[14px] 2xl:text-[16px] py-[11px] min-w-[130px] px-[35px] text-[#FFFFFF] bg-[#5446D0] border-[#5446D0] font-Inter font-medium text=[14px] leading-[16.94px] tracking-[0.48px] ml-[12px]"
                      onClick={() => {
                        createCampaignHandler();
                      }}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      )}

      {/* Rename Component PopUp */}
      {campaignNamePopup && (
        <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]">
          <div className="w-[100%] h-[100%] relative flex justify-center items-center">
            <div
              onClick={() => {
                setCampaignNamePopup(false);
              }}
              className="w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]"
            ></div>
            <div className="w-[450px] rounded-[8px] p-[20px] overflow-hidden z-[1] bg-[#272836]">
              <img
                src={plus_icon}
                alt=""
                onClick={() => {
                  setCampaignNamePopup(false);
                }}
                className="cursor-pointer rotate-45  ml-auto"
              />
              <label
                htmlFor="updatedCampaignName"
                className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
              >
                Campaign Name <span className="text-[#FF5364]">*</span>
              </label>
              <div className="relative">
                <input
                  name="updatedCampaignName"
                  className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                  placeholder="Enter campaign name"
                  maxLength={40}
                  type="text"
                  autoComplete="off"
                  value={renameCampaign.updatedCampaignName}
                  onChange={(e) => {
                    onInputHandler(e, "renameCampaign");
                  }}
                ></input>
                {renameCampaign.errorOfCampaignName.length > 0 && (
                  <p className="text-[12px] font-medium leading-[14.52px] absolute bottom-[-18px] text-[#FF5364] mt-[4px]">
                    {renameCampaign.errorOfCampaignName.length !== 0
                      ? renameCampaign.errorOfCampaignName
                      : ""}
                  </p>
                )}
                {renameCampaign.errorOfCampaignName.length > 0 && (
                  <img
                    src={exclamation_mark}
                    alt="*"
                    className="absolute top-[25px] right-[10px] w-[20px]"
                  />
                )}
              </div>
              <div
                className={`flex justify-center items-center mt-[30px] gap-[12px]`}
              >
                <button
                  className="border-[#5446D0] font-medium text-[14px] 2xl:text-[16px] border-[1px] py-[9px] 2xl:py-[7px] px-[10px] text-[#FFFFFF] rounded-md w-[130px] outline-none"
                  onClick={() => {
                    setCampaignNamePopup(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="border-[#5446D0] font-medium text-[14px] 2xl:text-[16px] bg-[#5446D0] py-[10px] 2xl:py-[8px] px-[20px] text-[#FFFFFF] rounded-md w-[130px] outline-none"
                  onClick={() => {
                    RenameCampaignHandel();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* campaign Delete PopUp */}
      {deleteCampaign.popupOpen && (
        <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]">
          <div className="w-[100%] h-[100%] relative flex justify-center items-center">
            <div
              onClick={() => {
                setDeleteCampaign((prevData) => ({
                  ...prevData,
                  popupOpen: false,
                }));
              }}
              className="w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]"
            ></div>
            <div className="w-[440px] rounded-[6px] p-[24px] overflow-hidden z-[1] bg-[#272836]">
              <p className="text-[#FFFFFF] text-[18px] font-medium leading-[21.78px] mb-[24px]">
                Video Templates
              </p>
              <p className="text-[#ABABC0] text-[14px] font-medium leading-[16.94px]">
                {" "}
                Are you sure you want to delete this templates?
              </p>
              <div className={`flex justify-end mt-[24px] gap-[12px]`}>
                <button
                  className="border-[#5446D0] border-[1px] py-[6px] px-[10px] text-[#FFFFFF] rounded-[6px] w-[100px] outline-none"
                  onClick={() => {
                    setDeleteCampaign((prevData) => ({
                      ...prevData,
                      popupOpen: false,
                    }));
                  }}
                >
                  Cancel
                </button>
                <button
                  className="border-[#5446D0] bg-[#5446D0] border-[1px] py-[6px] px-[10px] text-[#FFFFFF] rounded-[6px] w-[100px] outline-none"
                  onClick={() => { }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* main video component */}
      <div className={`bg-[#17171E] w-full min-h-screen ${campaignList?.length === 0 && 'flex items-center justify-center'}  p-[24px] font-Inter text-[#FFFFFF]`}>
        {/* <h1 className="font-Inter font-semibold  text-2xl text-[#FFFFFF]">
          Videos
        </h1> */}
        {campaignList?.length > 0 && <div className="">
          <button
            className="bg-[#272836] rounded-md font-Inter font-medium flex items-center justify-between p-[20px] min-w-[250px] 2xl:min-w-[280px] hover:bg-[#5446D0] cursor-pointer"
            onClick={() => {
              setCreateCampaignModel(true);
            }}
          >
            <h3 className="2xl:text-[18px]">Create New Campaign</h3>
            <img src={plus_icon} alt="plus" />
          </button>
        </div>}

        {/* Your Templates   */}
        <div className={`${campaignList?.length > 0 ? 'py-[20px] 2xl:py-[30px]' : ''} h-full`}>
          {campaignList?.length > 0 && <h3 className="font-Inter font-medium text-lg">Your Campaign</h3>}
          <div className=" grid  grid-cols-12 gap-[16px] 2xl:gap-[40px] pt-[20px] overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
            {campaignList?.length > 0 &&
               campaignList?.map((template, index) => (
                <div key={template._id} className="col-span-4">
                  <div
                    className="border border-[#2C2D3C] p-[12px] rounded-[8px] "
                  // onClick={() => {
                  //   window.open(`/video-editor/${template._id}`, "_blank");
                  // }}
                  >
                    <div className=" grid grid-cols-12 gap-x-[12px]">
                      {/* video section  */}

                      <div
                        className="col-span-12  cursor-pointer w-full h-full min-h-[190px] max-h-[190px] 2xl:min-h-[250px] 2xl:max-h-[250px] self-center"
                      // onClick={() => {
                      //   window.open(
                      //     `/video-editor/${template._id}`,
                      //     "_blank"
                      //   );
                      // }}
                      >
                        <Link to={`/video-editor/${template._id}`} onClick={() => store.videoStore.clearCanvas()}>
                          <img
                            className=" w-full h-full object-cover  rounded-[4px]"
                            src={process.env.REACT_APP_MEDIA_URL + template.thumbnailUrl}
                          >
                          </img>
                        </Link>
                      </div>

                      {/* video details section  */}

                      <div className="col-span-12 mt-[12px] self-center ">
                        <div className="flex justify-between items-center">
                          <div className=" w-[70%]">
                            <p className=" font-Inter text-ellipsis whitespace-nowrap truncate  text-[16px] xl:text-[18px]">
                              {template.campaignName}
                            </p>
                            <p className=" font-Inter font-normal text-[#ABABC0] text-[13px] mt-[3px]">
                              {/* {Math.round(
                                (Date.now() / 1000 - template.created / 1000) /
                                (60 * 60 * 24)
                              ) < 1
                                ? "Today"
                                : Math.round(
                                  (Date.now() / 1000 -
                                    template.created / 1000) /
                                  (60 * 60 * 24)
                                ) + " days ago"} */}
                                Last edited {lastEditedDate(template.updated)}
                            </p>
                          </div>
                          <div className="text-right">
                            <Menu>
                              <MenuButton className="inline-flex items-center gap-2 rounded-mdtext-sm/6 font-semibold text-white shadow-inner ">
                                <img src={menu_icon} className=" rotate-90"></img>
                              </MenuButton>

                              <MenuItems
                                transition
                                anchor="bottom end"
                                className="w-[120px] origin-top-right z-50 rounded-[8px] mt-[-13px] border border-white/5  bg-[#1e1e29] text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                              >
                                <MenuItem>
                                  <button onClick={() => { handleCampaignRenamePopUp(template); }} className="group flex w-full items-center gap-2  py-1.5 px-3 data-[focus]:bg-[#5446d0]">
                                    <img src={rename} className=" w-[25px]" alt="rename"></img>
                                    Rename
                                  </button>
                                </MenuItem>
                                <MenuItem>
                                  <button onClick={() => {
                                    setOpenDeleteModal(true);
                                    setTemplateId(template._id);
                                  }} className="group flex w-full items-center gap-2  py-1.5 px-3 data-[focus]:bg-[#5446d0]">
                                    <svg
                                      className="mx-[5px] cursor-pointer"
                                      width="14"
                                      height="16"
                                      viewBox="0 0 14 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_928_599)">
                                        <path
                                          d="M12.25 2H9.5V1.5C9.5 0.672906 8.82709 0 8 0H6C5.17291 0 4.5 0.672906 4.5 1.5V2H1.75C1.06075 2 0.5 2.56075 0.5 3.25V5C0.5 5.27612 0.723875 5.5 1 5.5H1.27325L1.70522 14.5713C1.74338 15.3725 2.4015 16 3.2035 16H10.7965C11.5985 16 12.2567 15.3725 12.2948 14.5713L12.7268 5.5H13C13.2761 5.5 13.5 5.27612 13.5 5V3.25C13.5 2.56075 12.9392 2 12.25 2ZM5.5 1.5C5.5 1.22431 5.72431 1 6 1H8C8.27569 1 8.5 1.22431 8.5 1.5V2H5.5V1.5ZM1.5 3.25C1.5 3.11216 1.61216 3 1.75 3H12.25C12.3878 3 12.5 3.11216 12.5 3.25V4.5H1.5V3.25ZM11.2959 14.5238C11.2899 14.6522 11.2347 14.7733 11.1417 14.862C11.0487 14.9507 10.925 15.0001 10.7965 15H3.2035C3.07497 15.0001 2.95133 14.9507 2.85831 14.862C2.76529 14.7733 2.71005 14.6522 2.70409 14.5238L2.27437 5.5H11.7256L11.2959 14.5238Z"
                                          fill="#ababc0"
                                        />
                                        <path
                                          d="M7 14C7.27612 14 7.5 13.7761 7.5 13.5V7C7.5 6.72387 7.27612 6.5 7 6.5C6.72388 6.5 6.5 6.72387 6.5 7V13.5C6.5 13.7761 6.72384 14 7 14ZM9.5 14C9.77612 14 10 13.7761 10 13.5V7C10 6.72387 9.77612 6.5 9.5 6.5C9.22388 6.5 9 6.72387 9 7V13.5C9 13.7761 9.22384 14 9.5 14ZM4.5 14C4.77613 14 5 13.7761 5 13.5V7C5 6.72387 4.77613 6.5 4.5 6.5C4.22387 6.5 4 6.72387 4 7V13.5C4 13.7761 4.22384 14 4.5 14Z"
                                          fill="#ababc0"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_928_599">
                                          <rect
                                            width="13"
                                            height="16"
                                            fill="#ababc0"
                                            transform="translate(0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    Delete
                                  </button>
                                </MenuItem>
                              </MenuItems>
                            </Menu>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
             )}
            {campaignList?.length === 0 && !apiCallLoader &&  <div className="col-span-12 ">
                <div className="flex items-center justify-center">
                  <div className=" text-center">
                    <img src={no_campaign} alt="no_campaign"></img>
                    <p className="text-[18px] text-[#fff] mt-[20px] font-Inter font-semibold text-center">
                      Your Campaign will show up here
                    </p>
                    <p className="text-[14px] text-[#ABABC0] font-medium mt-[14px] text-center">
                      Upload your video to start personalization.
                    </p>
                    <button
                      className="bg-[#5446d0] rounded-md font-Inter mx-auto font-semibold text-[14px] mt-[28px] text-center px-[12px] py-[6px] cursor-pointer"
                      onClick={() => {
                        setCreateCampaignModel(true);
                      }}
                    >
                      Create New Campaign
                    </button>
                  </div>
                </div>
              </div>
}
          </div>
        </div>
        <div className=" flex items-center justify-center">
          {campaignList?.length > 0 && campaignList?.length !== totalCampaign && totalCampaign > 9 &&
            <button
              onClick={() => viewMoreCampaign()}
              className="border hover:bg-[#5446d0] border-[#5446D0] text-[12px] text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium"
            >
              View More
            </button>
          }
          {querypage > 1 && (
            <button
              onClick={() => LessMoreCampaign()}
              className="border hover:bg-[#5446d0] border-[#5446D0] text-[12px] ml-5 text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium"
            >
              View Less
            </button>
          )}
        </div>
      </div>

      {openDeleteModal && (
        <div
          className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}
        >
          <div className="w-[100%] h-[100%] relative flex justify-center items-center">
            <div
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]"
            ></div>
            <div className="w-[400px] p-[20px] bg-[#272836] flex flex-col items-center z-[1] rounded-[8px]">
              <img
                src={deleteicon}
                alt="deleteicon"
                className=" mx-auto w-[100px] h-auto object-contain"
              ></img>
              <p className="text-[#ABABC0] my-[20px]  font-Inter font-normal text-[14px] leading-[16.94px] text-center tracking-[0.48px]">
                Are you sure you want to delete this campaign?
              </p>
              <div className="flex justify-center items-center text-sm gap-[12px]">
                <button
                  onClick={() => setOpenDeleteModal(false)}
                  className="border border-[#5446D0] text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium"
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    store.videoStore.DeleteCampaignList({
                      camID: templateId,
                      setCampaignListFromStore,
                      loader: setApiCallLoader,
                      limit: limit,
                      page : page
                    });
                    setOpenDeleteModal(false);
                  }}
                  className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold text-[#fff]"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default Video;
