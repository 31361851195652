import video_icon from "../assets/icons/video.svg"
import conf_icon from "../assets/icons/video_upload.svg"
import your_data_icon from "../assets/icons/your_data.svg"
import text_icon from "../assets/icons/text.svg"
import text_to_speech_icon from "../assets/icons/text_to_speech.svg"
import button_icon from "../assets/icons/button.svg"
import layers_icon from "../assets/icons/layers.svg"
import upload_icon from "../assets/icons/upload.svg"


export const menuOptions = [

    {
        name: "Video",
        image: video_icon,
        slug: "video"
    },
    {
        name: "Text",
        image: text_icon,
        slug: "text"
    },
    {
        name: "Button",
        image: button_icon,
        slug: "button"
    },
    {
        name: "Audio",
        image: text_to_speech_icon,
        slug: "text to speech"
    },
    {
        name : 'Media',
        image : upload_icon,
        slug: 'uploads'
    },
    {
        name: "Data",
        image: your_data_icon,
        slug: "Excels"
    },
    {
        name: "Layers",
        image: layers_icon,
        slug: 'layers'
    },
    {
        name : 'Conf',
        image : conf_icon,
        slug : 'conf'
    }
]