import React, { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../utility/hooks/useOutsideClick';
import merge_list_icon from '../../assets/icons/mergeMenu.svg';
import { useStore } from '../../utility/hooks/UseStore';

const EditableJsonRenderer = ({ jsonStructure, dataChangeHandler, dataForModel }) => {

    const [editableConfigs, setEditableConfigs] = useState([])

    const store = useStore();

    const renderEditableInputs = (node) => {
        if (node.editable === true) {
            const contentForPush = []
            node.editableContent.map((item) => {
                const itemForPush = { ...item }
                if (item.isAttribute === true) {
                    itemForPush.value = node.attributes[item.name]
                } else {
                    itemForPush.value = node[item.name]
                }
                itemForPush.id = node.id
                contentForPush.push(itemForPush)
            })
            setEditableConfigs((prev) => {
                return [...prev, ...contentForPush]
            })
        }
        if (node.children && node.children.length > 0) {
            node.children.map((item) => renderEditableInputs(item))
        }
    }


    // useEffect(() => {
    //     console.log(editableConfigs, "this is my editable config")
    // }, [editableConfigs])

    useEffect(() => {
        renderEditableInputs(jsonStructure)
    }, [])



    const inputChangeHandler = (id, fieldName, value) => {
        const jsonCopy = JSON.parse(JSON.stringify(jsonStructure))
        const updatedJson = updateJsonById(jsonCopy, id, fieldName, value)
        dataChangeHandler((prev) => {
            return {
                ...prev,
                json: updatedJson
            }
        })
        setEditableConfigs((prev) => {
            const copyArray = JSON.parse(JSON.stringify(prev))
            const arrayForReplace = []
            copyArray.map((item) => {
                if(item.id === id && item.name === fieldName){
                    const itemCopy = { ...item }
                    itemCopy.content = value
                    itemCopy.value = value
                    arrayForReplace.push(itemCopy)
                }else{
                    arrayForReplace.push(item)
                }
            })

            return arrayForReplace
        })
        // setEditableConfigs([])
        // renderEditableInputs(updatedJson)
    }


    const updateJsonById = (json, id, updatedField, updatedValue) => {
        if (json.id === id) {
            if (updatedField === 'content') {
                json.content = updatedValue;
            } else if (json.attributes && updatedField in json.attributes) {
                json.attributes[updatedField] = updatedValue;
            }
            return json;
        }
        if (json.children && json.children.length > 0) {
            json.children = json.children.map((child) =>
                updateJsonById(child, id, updatedField, updatedValue)
            );
        }
        return json;
    };

    const [visiblePopupIndex, setVisiblePopupIndex] = useState(null); // Track the popup for each input
    const ref = useRef(null);
    
    useOutsideClick(ref, () => {
        if (visiblePopupIndex !== null) {
            setVisiblePopupIndex(null); // Close popup when clicking outside
        }
    });

    const handleAddVariable = (id, name, variable) => {
        // Find the item in editableConfigs using the provided id
        const currentItem = editableConfigs?.find((config) => config.id === id);
    
        if (currentItem) {
            // Find the input/textarea element by id or another identifier
            const inputElement = document.querySelector(`[data-id="${id}"]`);
    
            if (inputElement) {
                const cursorPosition = inputElement.selectionStart;
                const currentValue = currentItem.value || '';
    
                // Insert the variable at the cursor position
                const updatedValue = 
                    currentValue.slice(0, cursorPosition) +
                    `{{${variable}}}` +
                    currentValue.slice(cursorPosition);
    
                // Update the value using the inputChangeHandler
                inputChangeHandler(id, name, updatedValue);
    
                // Reposition the cursor after the inserted variable
                setTimeout(() => {
                    inputElement.setSelectionRange(cursorPosition + `{{${variable}}}`.length, cursorPosition + `{{${variable}}}`.length);
                    inputElement.focus();
                }, 0);
            }
        }
    
        setVisiblePopupIndex(null); // Close popup after selecting a variable
    };
    

  const headers = store.videoStore.singleCampaignData.headers;

  const handleKeyDown = (e, id, fieldName) => {
    if (e.key === 'Backspace') {
        const input = e.target;
        const cursorPosition = input.selectionStart;
        const value = input.value;

        // Find all {{...}} blocks
        const regex = /{{[^}]*}}/g;
        const matches = [...value.matchAll(regex)];

        for (const match of matches) {
            const startIndex = match.index;
            const endIndex = startIndex + match[0].length;

            // Check if the cursor is inside this block
            if (cursorPosition > startIndex && cursorPosition <= endIndex) {
                e.preventDefault(); // Prevent default backspace behavior

                // Remove the {{...}} block
                const updatedValue = value.slice(0, startIndex) + value.slice(endIndex);

                // Update the input value
                inputChangeHandler(id, fieldName, updatedValue);

                // Reposition the cursor
                setTimeout(() => {
                    input.setSelectionRange(startIndex, startIndex);
                }, 0);

                return; // Exit after handling the match
            }
        }
    }
};


    return (
        <>
            {editableConfigs?.map((item, i) => {
                if (item.field === 'text') {
                    return (
                        <div key={`JSONRendere${i}${item.id}`} className='w-[100%] relative mt-2'>
                            <div className=' flex items-center justify-between relative'>
                                <label className=" font-Inter text-[12px] 2xl:text-[14px] font-medium text-[#ababc0]">{item.label}</label>
                              {item.isVariable &&  <div
                                    className="flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer"
                                    onClick={() => {
                                        setVisiblePopupIndex(item.id);
                                    }}
                                >
                                    <p className="mr-[2px] 2xl:mr-[10px] text-[12px]">{`{{ Variable }}`}</p>
                                    <img src={merge_list_icon} alt="MergList" />
                                </div>}
                                {visiblePopupIndex === item.id && (
                                    <div
                                    ref={ref}
                                    className="absolute bg-[#1C1C26] min-w-[100px] p-[10px] gap-[5px] z-50 max-h-[200px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  overflow-auto rounded-[6px] text-[#ABABC0] top-[25px] right-[0px]"
                                    >
                                    {headers &&
                                        headers?.map((header, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleAddVariable(item.id, item.name, header.name)}
                                            className="p-[5px] cursor-pointer hover:bg-[#3B3B4F] rounded-[3px]"
                                        >
                                            {header.name}
                                        </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {item.name === 'src' ?
                            <input  data-id={item.id} onKeyDown={(e) => handleKeyDown(e, item.id, item.name)}
                            onChange={(e) => { inputChangeHandler(item.id, item.name, e.target.value) }} className=" w-full font-Inter rounded-[6px] font-light bg-[#272836] text-[#fff] placeholder:text-[#ABABC0] py-[9px] my-[10px] px-[10px] text-[14px] outline-none" placeholder="Enter your value" type="text" value={item.value} ></input>
                                :
                            <textarea  data-id={item.id} onKeyDown={(e) => handleKeyDown(e, item.id, item.name)}
                            rows={2} onChange={(e) => { inputChangeHandler(item.id, item.name, e.target.value) }} className=" w-full font-Inter rounded-[6px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent font-light bg-[#272836] text-[#fff] placeholder:text-[#ABABC0] py-[9px] my-[10px] px-[10px] text-[14px] outline-none" placeholder="Enter your value" value={item.value} ></textarea>}
                            </div>
                    )
                }
            })}
        </>
    )
};

export default EditableJsonRenderer;
