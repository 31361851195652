import { action, makeAutoObservable, set } from 'mobx';
import { fabric } from 'fabric';
import { getUid, isHtmlAudioElement, isHtmlImageElement, isHtmlVideoElement } from '../utility/videoEditorUtils';
import anime from 'animejs';
import { FabricUitls } from '../utility/fabricUtils';
import { FFmpeg } from '@ffmpeg/ffmpeg';
// import { toBlobURL } from '@ffmpeg/util';
import deleteicone from "../assets/icons/delete.svg";
import animationicon from "../assets/icons/animation.svg";
import copy from "../assets/icons/copy.svg";
import merge from "../assets/icons/merge.svg";
import { errorStatusHandler } from '../utility/utils';
import apiService from '../configs/axios';
import toast from 'react-hot-toast';
import CustomRectText from '../utility/customFabricClass/customText';
import CustomRectButton from '../utility/customFabricClass/customButton';



class CustomButton extends fabric.Group {

    linkUrl;
    scaleX;
    scaleY;


    constructor(
        text,
        options) {

        // const tempCanvas = document.createElement('canvas');
        // const tempCtx = tempCanvas.getContext('2d');
        // if (!tempCtx) {
        //   throw new Error('Unable to create temporary canvas context.');
        // }

        // tempCtx.font = `${options.fontWeight || 'normal'} ${options.fontStyle || 'normal'} ${options.fontSize || 16}px ${options.fontFamily}`;
        // const textWidth = tempCtx.measureText(text).width;
        // const rectWidth = textWidth + 20 < (options.width || 100) ? options.width : textWidth + 20;
        const rectHeight = options.height || 40;


        const rect = new fabric.Rect({
            width: options.width,
            height: rectHeight,
            fill: options.backgroundColor || 'blue',
            stroke: options.stroke || 'black',
            strokeWidth: options.strokeWidth || 0,
            rx: options.borderRadius || 0,
            hasControls: false,
            originX: "center",
            originY: "center",
            opacity: options.btnOpacity,
        });


        const buttonText = new fabric.IText(text, {
            fontSize: options.fontSize || 16,
            fill: options.textColor || 'white',
            textAlign: 'center',
            width: options.width,
            height: rectHeight,
            editable: true,
            underline: options.underline,
            fontFamily: options.fontFamily,
            fontStyle: "italic",
            fontWeight: options.fontWeight,
            originX: "center",
            originY: "center",
            selectable: true,
            opacity: options.txtOpacity,
            charSpacing: options.charSpacing || 0,
            lineHeight: options.lineHeight || 1,
        });

        // const buttonText = new fabric.IText(text, {
        // 	fontSize: 16,
        // 	fill: 'white',
        // 	textAlign: 'center',
        // 	width: 200,
        // 	height: 200,
        // 	editable: true,
        // 	underline: false,
        // 	fontFamily: "Arial",
        // 	fontStyle: "normal",
        // 	fontWeight: options.fontWeight,
        // 	originX: "center",
        // 	originY: "center",
        // 	selectable: true,
        // 	opacity: options.txtOpacity,
        // 	charSpacing: options.charSpacing || 0,
        // 	lineHeight: options.lineHeight || 1,
        // });

        super([rect, buttonText], {
            ...options,
            noScaleCache: false,
            selectable: false,
            cornerSize: 8,
            cornerColor: '#3B3B4F',
            cornerStrokeColor: '#3B3B4F',
            transparentCorners: false,
            borderColor: '#3B3B4F',
            lockRotation: true,
            lockUniScaling: true,
            width: options.width,
            lockScalingFlip: true,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
        });


        this.linkUrl = options.linkUrl;

        this.scaleX = 1
        this.scaleY = 1

    }
}

class CustomText extends fabric.Group {


    scaleX;
    scaleY;

    constructor(text, options) {
        const rectHeight = options.height || 40;

        const rect = new fabric.Rect({
            width: options.width,
            height: rectHeight,
            fill: options.backgroundColor || '',
            stroke: options.stroke || '',
            strokeWidth: options.strokeWidth || 1,
            rx: options.borderRadius || 0,
            hasControls: false,
            originX: "center",
            originY: "center",
            opacity: options.btnOpacity,
            angle: options.angle,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,


        });

        const TText = new fabric.IText(text, {
            fontSize: options.fontSize || 16,
            fill: options.textColor || 'white',
            textAlign: 'center',
            width: options.width,
            height: rectHeight,
            underline: options.underline,
            fontFamily: options.fontFamily,
            fontStyle: options.fontStyle || 'normal',
            fontWeight: options.fontWeight,
            originX: "center",
            originY: "center",
            selectable: false,
            lineHeight: options.lineHeight || 1,
            charSpacing: options.charSpacing || 0,
            opacity: options.txtOpacity,
            stroke: options.outline,
            strokeWidth: options.outlineWidth || 1,
            angle: options.angle,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
        });

        super([rect, TText], {
            ...options,
            noScaleCache: false,
            selectable: false,
            cornerSize: 8,
            cornerColor: '#3B3B4F',
            cornerStrokeColor: '#3B3B4F',
            transparentCorners: false,
            borderColor: '#3B3B4F',
            lockRotation: true,
            lockUniScaling: false,
            width: options.width,
            lockScalingFlip: true,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
        });

        this.scaleX = 1;
        this.scaleY = 1;


        // const originalSet = this.set.bind(this);
        // this.set = (key, value) => {
        // 	originalSet(key, value);

        // 	if (key === 'scaleX' || key === 'scaleY') {
        // 		const newWidth = this.width * this.scaleX;
        // 		const newHeight = this.height * this.scaleY;
        // 	}

        // 	return this;
        // };
    }
}

export class PreviewStore {
    canvas
    backgroundColor

    selectedMenuOption
    audios
    videos
    images
    files

    oldVideoID
    videoConfigs = [];
    textConfigs = [];
    textObject = null;
    inputText = '';
    editorElements
    selectedElement;
    maxTime
    audioDurationMs
    animations
    animationTimeLine
    playing
    currentKeyFrame
    fps
    possibleVideoFormats = ['mp4', 'webm'];
    selectedVideoFormat;
    history
    historyIndex
    updatedVideoWidth
    updatedVideoHeight
    btnTop
    btnLeft
    videoDuration
    textTop
    textLeft
    timeLineZoom
    buttonConfigs = [];
    ExcelListForSingleCampaign = [];
    configData 
    videoURL;
    singleCampaignData
    campaignList
    videoSizePercentage

    constructor() {
        this.canvas = null;
        this.videos = [];
        this.images = [];
        this.audios = [];
        this.files = [];
        this.oldVideoID = false;
        this.editorElements = [];
        this.backgroundColor = '#111111';
        this.maxTime = 30;
        this.audioDurationMs = 30;
        this.playing = false;
        this.currentKeyFrame = 0;
        this.selectedElement = null;
        this.fps = 60;
        this.animations = [];
        this.animationTimeLine = anime?.timeline();
        this.selectedMenuOption = 'Video';
        this.selectedVideoFormat = 'mp4';
        this.updatedVideoHeight = 0;
        this.updatedVideoWidth = 960;
        this.btnTop = 0;
        this.btnLeft = 0;
        this.videoDuration = 0;
        this.textLeft = 0;
        this.textTop = 0;
        this.history = [];
        this.timeLineZoom = 100;
        this.historyIndex = -1;
        this.videoURL = "";
        this.campaignList = []
        this.singleCampaignData = []
        this.configData = []
        this.ExcelListForSingleCampaign = []
        this.videoSizePercentage = 0
        makeAutoObservable(this, {
            saveHistory: action,
        });
    }

    get currentTimeInMs() {
        return this.currentKeyFrame * 1000 / this.fps;
    }


    setVideoURL(url) {
        this.videoURL = url
    }

    setCurrentTimeInMs(time) {
        this.currentKeyFrame = Math.floor(time / 1000 * this.fps);
    }

    addVideoResource(videoUrl) {
        this.videos = [videoUrl];
    }

    // Add this method to handle adding video configurations
    addVideoConfig(videoConfig) {
        this.videoConfigs.push(videoConfig);
    }

    createVideoObject(videoElement, element) {
        return new fabric.CoverVideo(videoElement, {
            name: element.id,
            left: element.placement.x,
            top: element.placement.y,
            width: videoElement.width,
            height: videoElement.height,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            angle: element.placement.rotation,
            objectCaching: false,
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
        });
    }

    handleTimeLineZoom(value) {
        this.timeLineZoom = value
    }

    // Add this method to handle adding text configurations
    addTextConfig(textConfig) {

        const newConFig = [textConfig]
        this.textConfigs = newConFig
    }
    // Assuming setSelectedMenuOption is a method inside a class
    // setSelectedMenuOption(selectedMenuOption: MenuOption) {
    //   const canvas = this.canvas;
    //   if (!canvas) {
    //     console.error("Canvas element not found.");
    //     return;
    //   }
    //   this.selectedMenuOption = selectedMenuOption;
    // }

    setSelectedMenuOption(selectedMenuOption) {
        this.selectedMenuOption = selectedMenuOption;
    }

    setCanvas(canvas) {
        this.canvas = canvas;
        if (canvas) {
            canvas.backgroundColor = this.backgroundColor;
        }
    }

    addElement(element) {
        this.editorElements.push(element);
        this.saveHistory();
        this.renderElements();
    }

    removeElement(element) {
        const index = this.editorElements.indexOf(element);
        if (index !== -1) {
            this.editorElements.splice(index, 1);
            this.saveHistory();
            this.renderElements();
        }
    }

    saveHistory() {
        const clonedElements = this.editorElements.map(element => ({ ...element }));
        this.history.splice(this.historyIndex + 1);
        this.history.push(clonedElements);
        this.historyIndex++;
    }

    undo() {
        if (this.historyIndex > 0) {
            this.historyIndex--;
            this.editorElements = this.history[this.historyIndex].slice();
            this.renderElements();
        }
    }

    redo() {
        if (this.historyIndex < this.history.length - 1) {
            this.historyIndex++;
            this.editorElements = this.history[this.historyIndex].slice();
            this.renderElements();
        }
    }

    renderElements() {
        if (!this.canvas) {
            return;
        }
        this.canvas.clear();
    }

    setBackgroundColor(backgroundColor) {
        if (this.canvas) {
            this.canvas.backgroundColor = backgroundColor;
            // this.saveHistory(); 
        }
    }

    updateEffect(id, effect) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorVideoElement(element) || isEditorImageElement(element)) {
            element.properties.effect = effect;
        }
        this.refreshElements();
    }

    setVideos(videos) {
        this.videos = videos;
    }



    addAudioResource(audio) {
        this.audios = [...this.audios, audio];
    }

    addImageResource(image) {
        this.images = [...this.images, image];
    }

    addAnimation(animation) {
        this.animations = [...this.animations, animation];

        this.refreshAnimations();

        // Retrieve textConfigs from local storage
        const storedTextConfigsString = localStorage.getItem("textConfigs");

        if (!storedTextConfigsString) return;

        const textConfigs = JSON.parse(storedTextConfigsString);

        // Find the last added text configuration and update its animationType
        const lastAddedTextConfigIndex = textConfigs.length - 1;
        if (lastAddedTextConfigIndex >= 0) {
            textConfigs[lastAddedTextConfigIndex].animationType = animation.type;
        }

        // Save the updated textConfigs back to local storage
        localStorage.setItem("textConfigs", JSON.stringify(textConfigs));
        this.addTextConfig(textConfigs[lastAddedTextConfigIndex]);
    }

    updateAnimation(id, animation) {
        const index = this.animations.findIndex((a) => a.id === id);
        this.animations[index] = animation;
        this.refreshAnimations();
    }

    refreshAnimations() {
        anime?.remove(this.animationTimeLine);
        this.animationTimeLine = anime?.timeline({
            duration: this.maxTime,
            autoplay: false,
        });

        for (let i = 0; i < this.animations.length; i++) {
            const animation = this.animations[i];
            const editorElement = this.editorElements.find((element) => element.id === animation.targetId);
            const fabricObject = editorElement?.fabricObject;
            if (!editorElement || !fabricObject) {
                continue;
            }

            fabricObject.clipPath = undefined;

            switch (animation.type) {
                case "fadeIn": {
                    this.animationTimeLine.add({
                        opacity: [0, 1],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "fadeOut": {
                    this.animationTimeLine.add({
                        opacity: [1, 0],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, (editorElement.timeFrame.end - animation.duration));
                    break
                }
                case "slideIn": {
                    const direction = animation.properties.direction;
                    const targetPosition = {
                        left: (this.canvas.width * editorElement.placement.x) / 100,
                        top: (this.canvas.height * editorElement.placement.y) / 100,
                    }
                    const startPosition = {
                        left: (direction === "left" ? Number((0 - (editorElement.fabricObject.width * editorElement.placement.scaleX)))
                            : direction === "right" ? this.canvas?.width
                                : (this.canvas.width * editorElement.placement.x) / 100),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                            : direction === "bottom" ? this.canvas?.height
                                : ((this.canvas.height * editorElement.placement.y) / 100)),
                    }
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        top: [startPosition.top, targetPosition.top],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break
                }

                case "slideOut": {
                    const direction = animation.properties.direction;
                    const startPosition = {
                        left: (this.canvas.width * editorElement.placement.x) / 100,
                        top: (this.canvas.height * editorElement.placement.y) / 100,
                    }

                    const targetPosition = {
                        left: (direction === "left" ? Number((0 - (editorElement.fabricObject.width * editorElement.placement.scaleX)))
                            : direction === "right" ? this.canvas?.width
                                : (this.canvas.width * editorElement.placement.x) / 100),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                            : direction === "bottom" ? this.canvas?.height
                                : ((this.canvas.height * editorElement.placement.y) / 100)),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                        : direction === "bottom" ? this.canvas?.height
                            : ((this.canvas.height * editorElement.placement.y) / 100)),
                    }

                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }

                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        top: [startPosition.top, targetPosition.top],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.end - animation.duration);
                    break
                }

                case "breathe": {
                    const itsSlideInAnimation = this.animations.find((a) => a.targetId === animation.targetId && (a.type === "slideIn"));
                    const itsSlideOutAnimation = this.animations.find((a) => a.targetId === animation.targetId && (a.type === "slideOut"));
                    const timeEndOfSlideIn = itsSlideInAnimation ? editorElement.timeFrame.start + itsSlideInAnimation.duration : editorElement.timeFrame.start;
                    const timeStartOfSlideOut = itsSlideOutAnimation ? editorElement.timeFrame.end - itsSlideOutAnimation.duration : editorElement.timeFrame.end;
                    if (timeEndOfSlideIn > timeStartOfSlideOut) {
                        continue;
                    }

                    const duration = timeStartOfSlideOut - timeEndOfSlideIn;
                    const easeFactor = 4;
                    const suitableTimeForHeartbeat = 1000 * 60 / 72 * easeFactor
                    const upScale = 1.05;
                    const currentScaleX = fabricObject.scaleX ?? 1;
                    const currentScaleY = fabricObject.scaleY ?? 1;
                    const finalScaleX = currentScaleX * upScale;
                    const finalScaleY = currentScaleY * upScale;
                    const totalHeartbeats = Math.floor(duration / suitableTimeForHeartbeat);
                    if (totalHeartbeats < 1) {
                        continue;
                    }

                    const keyframes = [];
                    for (let i = 0; i < totalHeartbeats; i++) {
                        keyframes.push({ scaleX: finalScaleX, scaleY: finalScaleY });
                        keyframes.push({ scaleX: currentScaleX, scaleY: currentScaleY });
                    }

                    this.animationTimeLine.add({
                        duration: animation.duration,
                        targets: fabricObject,
                        keyframes,
                        easing: 'linear',
                        loop: true
                    }, timeEndOfSlideIn);
                    break
                }

                case "zoomIn": {
                    this.animationTimeLine.add({
                        scaleX: [0, 1],
                        scaleY: [0, 1],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "zoomOut": {
                    this.animationTimeLine.add({
                        scaleX: [1, 0],
                        scaleY: [1, 0],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.end - animation.duration);
                    break
                }

                case "bounceIn": {
                    this.animationTimeLine.add({
                        scaleY: [0, 1],
                        scaleX: [0, 1],
                        duration: animation.duration,
                        easing: 'easeInBounce',
                        targets: fabricObject,
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "bounceOut": {
                    this.animationTimeLine.add({
                        scaleX: [1, 0.7, 0],
                        scaleY: [1, 0.7, 0],
                        duration: animation.duration,
                        easing: 'easeOutBounce',
                        targets: fabricObject,
                        update: (anim) => {
                            if (fabricObject.scaleX === 0 || fabricObject.scaleY === 0) {
                                fabricObject.scaleX = 1;
                                fabricObject.scaleY = 1;
                            }
                        }
                    }, editorElement.timeFrame.end - animation.duration);


                    break;
                }

                case "lightspeedOut": {
                    const direction = animation.properties.direction;
                    
                    // Get the current position of the fabricObject directly
                    const startPosition = {
                        left: fabricObject.left,  // Use the current left position of the fabric object
                        top: fabricObject.top,    // Use the current top position of the fabric object
                    };
                
                    // Calculate the target position based on the direction
                    const targetPosition = {
                        left: (direction === "left" ? 
                            0 - (editorElement.fabricObject.width * editorElement.placement.scaleX) : 
                            direction === "right" ? this.canvas?.width : 
                            (this.canvas.width * editorElement.placement.x) / 100),
                        top: fabricObject.top, // Keep top position unchanged
                    };
                
                    // Apply the clip path if necessary
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle);
                    }
                
                    // Add the animation with the start position from fabricObject
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],  // Animate only the 'left' position
                        top: [startPosition.top, targetPosition.top],    // Keep 'top' unchanged
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'easeOutBack',
                    }, editorElement.timeFrame.end - animation.duration);
                
                    break;
                }

                case "lightspeedIn": {
                    const direction = animation.properties.direction;
                    const targetPosition = {
                        left: (this.canvas.width * editorElement.placement.x) / 100,
                        top: (this.canvas.height * editorElement.placement.y) / 100,
                    }
                    const startPosition = {
                        left: (direction === "left" ? Number((0 - (editorElement.fabricObject.width * editorElement.placement.scaleX)))
                            : direction === "right" ? this.canvas?.width
                                : (this.canvas.width * editorElement.placement.x) / 100),
                    }
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'easeOutBack',
                    }, editorElement.timeFrame.start);
                    break
                }

                case "typewriter": {
                    if (fabricObject instanceof fabric.Text) {
                        const textValue = fabricObject.text;
                        if (textValue !== undefined) {
                            const durationPerCharacter = animation.duration / textValue.length;
                            let currentIndex = 0;

                            const animateNextCharacter = () => {
                                if (currentIndex < textValue.length) {
                                    const newText = textValue.slice(0, currentIndex + 1);
                                    fabricObject.set('text', newText);
                                    fabricObject.setCoords();
                                    this.canvas?.renderAll();
                                    currentIndex++;
                                    if (currentIndex < textValue.length) {
                                        setTimeout(animateNextCharacter, durationPerCharacter);
                                    } else {
                                        this.animationTimeLine.restart();
                                    }
                                }
                            };
                            // Function to start the animation
                            const startTypewriterAnimation = () => {
                                currentIndex = 0; // Reset currentIndex each time animation starts
                                animateNextCharacter();
                            };
                            // Clear or restart the animation timeline before starting the animation
                            this.animationTimeLine.restart();

                            this.animationTimeLine.add({
                                begin: startTypewriterAnimation,
                                duration: animation.duration
                            }, editorElement.timeFrame.start);

                        }
                    }
                    break;
                }
            }
        }
    }

    removeAnimation(id) {
        this.animations = this.animations.filter(
            (animation) => animation.id !== id
        );
        this.refreshAnimations();
    }

    setSelectedElement(selectedElement) {
        this.selectedElement = selectedElement;
        if (this.canvas) {
            if (selectedElement?.fabricObject)
                this.canvas.setActiveObject(selectedElement.fabricObject);
            else
                this.canvas.discardActiveObject();
        }
    }

    updateSelectedElement() {
        this.selectedElement = this.editorElements.find((element) => element.id === this.selectedElement?.id) ?? null;
    }

    setEditorElements(editorElements) {
        this.editorElements = editorElements;
        this.updateSelectedElement();
        this.refreshElements();
        this.refreshAnimations();
    }

    updateEditorElement(editorElement) {
        this.setEditorElements(this.editorElements.map((element) =>
            element.id === editorElement.id ? editorElement : element
        ));
    }

    updateEditorElementTimeFrame(editorElement, timeFrame) {
        if (timeFrame.start !== undefined && timeFrame.start < 0) {
            timeFrame.start = 0;
        }
        if (timeFrame.end !== undefined) {
            if (timeFrame.end > this.maxTime) {
                timeFrame.end = this.maxTime;
            }
            if (timeFrame.end > this.audioDurationMs) {
                this.audioDurationMs = timeFrame.end;
            }
        }

        const newEditorElement = {
            ...editorElement,
            timeFrame: {
                ...editorElement.timeFrame,
                ...timeFrame,
            }
        };

        this.updateVideoElements();
        this.updateAudioElements();
        this.updateEditorElement(newEditorElement);
        this.refreshAnimations();
    }

    addEditorElement(editorElement) {
        this.setEditorElements([...this.editorElements, editorElement]);
        this.refreshElements();
        // this.setSelectedElement(this.editorElements[this.editorElements.length - 1]);
    }

    removeEditorElement(id) {
        this.setEditorElements(this.editorElements.filter(
            (editorElement) => editorElement.id !== id
        ));

        const newData = this.setEditorElements(this.editorElements.filter(
            (editorElement) => editorElement.id !== id
        ))
        this.refreshElements();
    }

    setMaxTime(maxTime) {
        this.maxTime = maxTime;
    }

    setPlaying(playing) {
        this.playing = playing;
        this.updateVideoElements();
        this.updateAudioElements();
        const audioElement = document.getElementById('audioPlayer');
        // this.updateTextToSpeechElement();

        if (playing) {
            this.startedTime = Date.now();
            this.startedTimePlay = this.currentTimeInMs;
            requestAnimationFrame(() => {
                this.playFrames();
            });
            // if (audioElement) {
            //     audioElement.play();
            // }
        } else {
            // if (audioElement) {
            //     audioElement.pause();
            // }
        }
    }

    startedTime = 1;


    startedTimePlay = 0;

    playFrames() {
        if (!this.playing) {
            return;
        }

        const elapsedTime = Date.now() - this.startedTime;
        const newTime = this.startedTimePlay + elapsedTime;
        this.updateTimeTo(newTime);
        if (newTime > this.maxTime) {
            this.currentKeyFrame = 0;
            this.setPlaying(false);
        } else {
            requestAnimationFrame(() => {
                this.playFrames();
            });
        }
    }

    updateTimeTo(newTime) {
        this.setCurrentTimeInMs(newTime);
        this.animationTimeLine.seek(newTime);
        if (this.canvas) {
            this.canvas.backgroundColor = this.backgroundColor;
        }

        this.editorElements.forEach(e => {
            if (!e.fabricObject) return;
            const isInside = e.timeFrame.start <= newTime && newTime <= e.timeFrame.end;

            e.fabricObject.visible = isInside;
            if (e.type === 'audio') {
                const audio = document.getElementById(e.properties.elementId);
                if (audio) {
                    if (this.playing && (this.currentTimeInMs < e.timeFrame.end) && (this.currentTimeInMs >= e.timeFrame.start)) {
                        if (audio.paused) {
                            audio.play().catch((error) => {
                                console.error('Failed to play audio:', error);
                            });
                        }
                    } else {
                        if (!audio.paused) {
                            audio.pause();
                        }
                    }
                }
            }
        });
    }

    handleSeek(seek) {
        if (this.playing) {
            this.setPlaying(false);
        }
        this.updateTimeTo(seek);
        this.updateVideoElements();
        this.updateAudioElements();
    }

    addVideo(file, volume, ID, afterLoadFunction) {
        var videoElement = document.createElement('video');
        const id = ID;
        videoElement.src = file;
        videoElement.addEventListener('loadedmetadata', () => {
            this.videoDuration = videoElement.duration;
            const videoVolume = videoElement.volume;
            const videoConfig = {
                url: file,
                duration: this.videoDuration,
                volume: videoVolume,
            };

            // Push videoConfig to videoConfigs array
            this.videoConfigs = [videoConfig];

            // Store videoConfig in local storage
            // localStorage.setItem(`videoConfig-${id}`, JSON.stringify(videoConfig));

            this.addEditorElement({
                id,
                isVisible: true,
                name: `Media(video)`,
                type: "video",
                placement: {
                    x: 0,
                    y: 0,
                    width: 800,
                    height: 500,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
                timeFrame: {
                    start: 0,
                    end: this.videoDuration * 1000,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `video-${id}`,
                    src: file,
                    effect: {
                        type: "none",
                    },
                    volume: 100,
                    width: 0,
                    height: 0,
                },
            });
            this.maxTime = this.videoDuration * 1000

            afterLoadFunction()
        });


    }

    isVisible(id, value) {
        const index = this.editorElements.findIndex((element) => element.id === id)
        const element = this.editorElements[index]
        if (element.timeFrame.start < this.maxTime) {
            element.timeFrame.UpdateStart = element.timeFrame.start
            element.timeFrame.UpdateEnd = element.timeFrame.end
            element.timeFrame.start = this.maxTime
        } else {
            element.timeFrame.start = element.timeFrame.UpdateStart
            element.timeFrame.end = element.timeFrame.UpdateEnd
            element.timeFrame.UpdateEnd = 0
            element.timeFrame.UpdateStart = 0
        }
        this.refreshElements()
    }

    addImage(options) {

        var imageElement = document.createElement('img')
        imageElement.src = options.src
        imageElement.addEventListener('load', () => {
            const id = getUid();
            this.addEditorElement(
                {
                    id: options.elementId,
                    isVisible: options.isVisible,
                    name: `Media(image)`,
                    type: "image",
                    placement: {
                        x: options.x,
                        y: options.y,
                        width: options.width,
                        height: options.height,
                        rotation: options.rotation,
                        scaleX: options.scaleX,
                        scaleY: options.scaleY,
                    },
                    timeFrame: {
                        start: options.start,
                        end: options.end,
                        UpdateStart: options.UpdateStart,
                        UpdateEnd: options.UpdateEnd,
                    },
                    properties: {
                        elementId: options.elementId,
                        src: options.src,
                        opacity : options.opacity,
                        created: options.created,
                        effect: {
                            type: "none",
                        }
                    },
                },
            );
        })
    }

    addAudio(index) {
        const audioElement = document.getElementById(`audio-${index}`)
        if (!isHtmlAudioElement(audioElement)) {
            return;
        }
        const audioDurationMs = audioElement.duration * 1000;

        const id = getUid();
        this.addEditorElement(
            {
                id,
                isVisible: true,
                name: `Media(audio) ${index + 1}`,
                type: "audio",
                placement: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
                timeFrame: {
                    start: 0,
                    end: audioDurationMs,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `audio-${id}`,
                    src: audioElement.src,
                    text: ""
                }
            },
        );
    }

    addText(options) {
        const id = getUid();
        const index = this.editorElements.length;
        const canvas = this.canvas;
        if (!canvas) return;

        const text = new CustomRectText({
            left: options.left || 0,
            top: options.top || 0,
            text: options.text,
            fontSize: options.fontSize,
            fontFamily: options.fontFamily,
            fontWeight: options.fontWeight,
            fill: options.fill || options.textColor,
            selectable: true,
            backgroundColor: options.btnBg || options.backgroundColor,
            width: options.btnWidth || options.btnWidth,
            height: options.btnHeight || options.height,
            underline: options.underline,
            fontStyle: options.italic || options.fontStyle,
            textAlign: 'center',
            borderRadius: options.borderRadius,
            scaleX: 1,
            scaleY: 1,
            lineHeight: options.lineHeight,
            charSpacing: options.charSpacing,
            txtOpacity: options.txtOpacity,
            btnOpacity: options.btnOpacity,
            outline: options.outline,
            outlineWidth: options.outlineWidth,
            angle: options.angle,
            paddingX: options.paddingX,
            paddingY: options.paddingY,
            stroke: options.stroke,
            strokeWidth: options.strokeWidth,
            from : "preview",
            rotation : options.rotation,
            created: options.created,
        }, canvas)

        canvas.add(text)

        // Add the text element to the editor
        this.addEditorElement(
            {
                id: options.id,
                isVisible: true,
                name: `Text ${index + 1}`,
                type: "text",
                placement: {
                    x: options.left || 0,
                    y: options.top || 0,
                    width: options.width || 100,
                    height: options.height,
                    rotation: options.rotation || 0,
                    scaleX: options.scaleX || 1,
                    scaleY: options.scaleY || 1,
                },
                timeFrame: {
                    start: options.start || 0,
                    end: options.end || this.maxTime,
                    UpdateStart: options.UpdateStart || 0,
                    UpdateEnd: options.UpdateEnd || 0
                },
                properties: {
                    // animationType: options.animationType,
                    text: options.text,
                    fontSize: options.fontSize,
                    fontWeight: options.fontWeight,
                    textColor: options.textColor,
                    backgroundColor: options.backgroundColor,
                    fontFamily: options.fontFamily,
                    // width: options.btnWidth || options.btnWidth,
                    // height: options.btnHeight || options.height,
                    underline: options.underline || false,
                    fontStyle: options.fontStyle,
                    textAlign: options.textAlign,
                    borderRadius: options.borderRadius,
                    lineHeight: options.lineHeight,
                    charSpacing: options.charSpacing,
                    txtOpacity: options.txtOpacity,
                    btnOpacity: options.btnOpacity,
                    outline: options.outline,
                    outlineWidth: options.outlineWidth,
                    angle: options.angle,
                    fixedWidth: options.fixedWidth || false,
                    paddingX: options.paddingX || 15,
                    paddingY: options.paddingY || 15,
                    stroke: options.stroke,
                    strokeWidth: options.strokeWidth,
                    created: options.created
                },
            });

        this.saveHistory()
    }

    addButton(options) {

        const id = getUid();
        const index = this.editorElements.length;


        const canvas = this.canvas;
        if (!canvas) return;
        const button = new CustomRectButton({
            text: options.text,
            x: options.x || 0,
            y: options.y || 0,
            paddingX: options.paddingX,
            paddingY: options.paddingY,
            backgroundColor: options.backgroundColor,
            borderRadiusX: options.borderRadiusX,
            borderRadiusY: options.borderRadiusY,
            originX: options.originX,
            originY: options.originY,
            stroke: options.stroke,
            strokeWidth: options.strokeWidth,
            fontSize: options.fontSize,
            textColor: options.textColor,
            txtOpacity: options.txtOpacity,
            btnOpacity: options.btnOpacity,
            fontFamily: options.fontFamily,
            linkUrl: options.linkUrl,
            underline: options.underline || false,
            charSpacing: options.charSpacing || 0,
            textAlign: options.textAlign,
            from : "preview",
            rotation : options.rotation,
            created: options.created
        }, canvas)

        canvas.add(button);

        this.addEditorElement({
            id,
            isVisible: true,
            name: `Button${index + 1}`,
            type: "button",
            placement: {
                x: options?.x || 0,
                y: options?.y || 0,
                width: '',
                height: '',
                rotation: options?.rotation || 0,
                scaleX: options?.scaleX || 1,
                scaleY: options?.scaleY || 1,
            },
            timeFrame: {
                start: options.start || 0,
                end: options.end || this.maxTime,
                UpdateStart: 0,
                UpdateEnd: 0
            },
            properties: {
                text: options.text,
                fontSize: options.fontSize,
                fontWeight: options.fontWeight,
                textColor: options.textColor,
                backgroundColor: options.backgroundColor,
                fontFamily: options.fontFamily,
                linkUrl: options.linkUrl,
                width: "",
                height: "",
                underline: options.underline || false,
                fontStyle: options.fontStyle,
                textAlign: options.originX || "center",
                strokeWidth: options.strokeWidth,
                stroke: options.stroke,
                borderRadius: options.borderRadius,
                btnOpacity: options.btnOpacity,
                txtOpacity: options.txtOpacity || 1,
                lineHeight: options.lineHeight || 1.12,
                charSpacing: options.charSpacing || 0,
                paddingX: options.paddingX || 15,
                paddingY: options.paddingY || 15,
                angle: options.angle || 0,
                created: options.created
            },
        });
    }

    addtexttospeech(audioData, inputValue, oldID, timeFrame) {

        const audioElement = document.createElement('audio');
        if (audioData && audioData.includes("https")) {
            audioElement.src = audioData;
        } else {
            audioElement.src = audioData;
        }
        // audioElement.src = `${audioData}`;

        audioElement.controls = true;
        audioElement.onloadedmetadata = () => {
            const audioDurationMs = audioElement.duration * 1000;

            if (isNaN(audioDurationMs)) {
                return;
            }

            // Update audioDurationMs dynamically
            this.audioDurationMs = audioDurationMs;

            const canvas = this.canvas;

            if (!canvas) {
                return;
            }


            const fabricAudio = new fabric.Textbox('Audio Element', {
                left: 100,
                top: 100,
                width: 200,
                height: 50,
                fill: 'transparent',
                hasControls: false,
                hasBorders: false,
                selectable: false,
                fontFamily: 'Arial',
                fontSize: 14,
                originX: 'left',
                originY: 'top',
            });

            canvas.add(fabricAudio);

            let id
            if (oldID && oldID !== null && oldID !== undefined) {
                id = oldID
            } else {
                id = getUid();
            }

            this.addEditorElement({
                id,
                fabricObject: fabricAudio,
                isVisible: true,
                name: `Media(audio)`,
                type: "audio",
                placement: {
                    x: 0,
                    y: 0,
                    width: 800,
                    height: 500,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
                timeFrame: {
                    start: timeFrame.start,
                    end: timeFrame.end,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `audio-${id}`,
                    src: audioElement.src,
                    text: inputValue,
                },
            });
        };
    }

    // int his when addtexttospeech
    updateVideoElements() {
        this.editorElements.filter(
            (element) =>
                element.type === "video"
        )
            .forEach((element) => {
                const video = document.getElementById(element.properties.elementId);
                if (isHtmlVideoElement(video)) {
                    const videoTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                    video.currentTime = videoTime;
                    if (this.playing) {
                        video.play();
                    } else {
                        video.pause();
                    }
                }
            })
    }

    updateAudioElements() {

        this.editorElements.filter(
            (element) =>
                element.type === "audio"
        )
            .forEach((element) => {
                const audio = document.getElementById(element.properties.elementId);

                if (isHtmlAudioElement(audio)) {
                    const audioTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                    audio.currentTime = audioTime;
                    if (this.playing && (this.currentTimeInMs < element.timeFrame.end) && (this.currentTimeInMs >= element.timeFrame.start)) {
                        if (audio.paused) {
                            audio.play().catch((error) => {
                                console.error('Failed to play audio:', error);
                            });
                        }
                    } else {
                        if (!audio.paused) {
                            audio.pause();
                        }
                    }
                }
            })
    }

    updateTextToSpeechElement() {
        this.editorElements.filter(
            (element) =>
                element.type === "audio"
        )
            .forEach((element) => {

            })
    }

    async addAudioElement({ campaignID , data , setAudioDatastore , loader }) {
        loader(true)
        try {
            const response = await apiService.post(`/user/campaign/preview/${campaignID}`, data)
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                // this.VariableListForSingleCampaign = FinalResponse.data
                // this.getSingleCampaignData({ campaignID: data.campaignID })
                this.configData = FinalResponse.data || []
                setAudioDatastore(true)
                // toast.success(FinalResponse.message)
                // loader(false)
                return FinalResponse.data
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data?.statusCode, error.response?.data?.error)
            if (!isAuthFailed) {
                toast.error(error.response?.data?.error)
                // loader(false)
            }
        }
    }

    setVideoFormat(format) {
        this.selectedVideoFormat = format;
    }

    saveCanvasToVideoWithAudio() {
        this.saveCanvasToVideoWithAudioWebmMp4();
    }

    saveCanvasToVideoWithAudioWebmMp4() {
        let mp4 = this.selectedVideoFormat === 'mp4'
        const canvas = document.getElementById("canvas");
        const stream = canvas.captureStream(30);
        const audioElements = this.editorElements.filter(isEditorAudioElement)
        const audioStreams = [];
        audioElements.forEach((audio) => {
            const audioElement = document.getElementById(audio.properties.elementId);
            let ctx = new AudioContext();
            let sourceNode = ctx.createMediaElementSource(audioElement);
            let dest = ctx.createMediaStreamDestination();
            sourceNode.connect(dest);
            sourceNode.connect(ctx.destination);
            audioStreams.push(dest.stream);
        });
        audioStreams.forEach((audioStream) => {
            stream.addTrack(audioStream.getAudioTracks()[0]);
        });

        const video = document.createElement("video");

        video.height = 500;
        video.width = 800;

        video.play().then(() => {
            const mediaRecorder = new MediaRecorder(stream);
            const chunks = [];
            mediaRecorder.ondataavailable = function (e) {
                chunks.push(e.data);
            };

            mediaRecorder.onstop = async function (e) {
                const blob = new Blob(chunks, { type: "video/webm" });

                if (mp4) {
                    // lets use ffmpeg to convert webm to mp4
                    const data = new Uint8Array(await (blob).arrayBuffer());
                    const ffmpeg = new FFmpeg();
                    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd"
                    await ffmpeg.load({
                        // coreURL: await toBlobURL(${baseURL}/ffmpeg-core.js, 'text/javascript'),
                        // wasmURL: await toBlobURL(${baseURL}/ffmpeg-core.wasm, 'application/wasm'),
                        // workerURL: await toBlobURL(${baseURL}/ffmpeg-core.worker.js, 'text/javascript'),
                    });
                    await ffmpeg.writeFile('video.webm', data);
                    await ffmpeg.exec(["-y", "-i", "video.webm", "-c", "copy", "video.mp4"]);
                    // await ffmpeg.exec(["-y", "-i", "video.webm", "-c:v", "libx264", "video.mp4"]);

                    const output = await ffmpeg.readFile('video.mp4');
                    const outputBlob = new Blob([output], { type: "video/mp4" });
                    const outputUrl = URL.createObjectURL(outputBlob);
                    const a = document.createElement("a");
                    a.download = "video.mp4";
                    a.href = outputUrl;
                    a.click();

                    const getOutput = await ffmpeg.deleteFile('video.mp4');

                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "video.webm";
                    a.click();
                }

            };
            mediaRecorder.start();
            setTimeout(() => {
                mediaRecorder.stop();
            }, this.maxTime);
            video.remove();
        })
    }

    refreshElements() {
        const store = this;
        if (!store.canvas) return;
        const canvas = store.canvas;
        store.canvas.remove(...store.canvas.getObjects());

        const mainElement = store.editorElements.slice().sort((a, b) => a.properties.created - b.properties.created)


        for (let index = 0; index < store.editorElements.length; index++) {
            const element = mainElement[index];
            switch (element.type) {
                case "video": {

                    if (document.getElementById(element.properties.elementId) == null) continue;

                    const videoElement = document.getElementById(element.properties.elementId);
                    if (!isHtmlVideoElement(videoElement)) continue;

                    let updatedVideoWidth, updatedVideoHeight;
                    const canvas = this.canvas;

                    const video = document.getElementById(element.properties.elementId);
                    const container = document.getElementById('container');

                    const parentElement = container.parentElement;
                    const parentElementStyle = window.getComputedStyle(parentElement);
                    const paddingLeft = parseFloat(parentElementStyle.paddingLeft);
                    const paddingRight = parseFloat(parentElementStyle.paddingRight);
                    const paddingTop = parseFloat(parentElementStyle.paddingTop);
                    const paddingBottom = parseFloat(parentElementStyle.paddingBottom);
                    const parentWidth = parentElement.clientWidth - (paddingLeft + paddingRight);
                    const parentHeight = parentElement.clientHeight - (paddingTop + paddingBottom);

                    if (video.videoWidth === video.videoHeight) {
                        let finalVideoSizePerc
                        if (this.videoSizePercentage === 0) {
                            const calc = (parentHeight / video.videoWidth) * 100;
                            finalVideoSizePerc = Number(calc.toFixed(2));
                        } else {
                            finalVideoSizePerc = this.videoSizePercentage;
                        }
                        const scale = finalVideoSizePerc / 100;
                        const totalWidth = video.videoWidth * scale;
                        const totalHeight = video.videoHeight * scale;

                        if (totalWidth > parentWidth) {
                            let adjustedVideoPerc
                            const innerCalc = (parentWidth / video.videoWidth) * 100;
                            adjustedVideoPerc = Number(innerCalc.toFixed(2));
                            const finalScale = adjustedVideoPerc / 100;
                            const finalWidth = video.videoWidth * finalScale;
                            const finalHeight = video.videoHeight * finalScale;
                            container.style.width = `${finalWidth}px`;
                            const videoAspectRatio = finalWidth / finalHeight;
                            videoElement.width = finalWidth;
                            videoElement.height = finalWidth / videoAspectRatio;
                            container.style.height = `${finalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = finalWidth;
                            updatedVideoHeight = finalWidth / videoAspectRatio;
                        } else {
                            container.style.width = `${totalWidth}px`;
                            const videoAspectRatio = totalWidth / totalHeight;
                            videoElement.width = totalWidth;
                            videoElement.height = totalWidth / videoAspectRatio;
                            container.style.height = `${totalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = totalWidth;
                            updatedVideoHeight = totalWidth / videoAspectRatio;
                        }

                    }
                    else if (video.videoWidth > video.videoHeight) {
                        let finalVideoSizePerc
                        const calc = (parentHeight / video.videoHeight) * 100;
                        finalVideoSizePerc = Number(calc.toFixed(2));
                        const scale = finalVideoSizePerc / 100;
                        // if (video.videoWidth < (parentWidth * scale)) {                            
                        const totalWidth = video.videoWidth * scale
                        const totalHeight = video.videoHeight * scale

                        if (totalWidth > parentWidth) {
                            // if()
                            let adjustedVideoPerc
                            const innerCalc = (parentWidth / video.videoWidth) * 100;
                            adjustedVideoPerc = Number(innerCalc.toFixed(2));
                            function findPerfectSize(lastVideoPercentage) {
                                const calculateSize = video.videoWidth * (lastVideoPercentage / 100)
                                if (calculateSize > totalWidth) {
                                    findPerfectSize(Number(lastVideoPercentage - 0.01))
                                } else {
                                    const finalScale = lastVideoPercentage / 100
                                    const finalWidth = video.videoWidth * finalScale
                                    const finalHeight = video.videoHeight * finalScale
                                    container.style.width = `${finalWidth}px`;
                                    const videoAspectRatio = finalWidth / finalHeight;
                                    videoElement.width = finalWidth;
                                    videoElement.height = finalWidth / videoAspectRatio;
                                    container.style.height = `${finalWidth / videoAspectRatio}px`;
                                    updatedVideoWidth = finalWidth;
                                    updatedVideoHeight = finalWidth / videoAspectRatio;
                                    return lastVideoPercentage
                                }
                            }
                            const returnedValue = findPerfectSize(adjustedVideoPerc)
                            this.videoSizePercentage = returnedValue
                        } else {
                            container.style.width = `${totalWidth}px`;
                            const videoAspectRatio = totalWidth / totalHeight;
                            videoElement.width = totalWidth;
                            videoElement.height = totalWidth / videoAspectRatio;
                            container.style.height = `${totalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = totalWidth;
                            updatedVideoHeight = totalWidth / videoAspectRatio;
                        }

                        // }
                        // else {
                        //     let diffrence
                        //     if(this.videoSizePercentage === 0 || this.videoSizePercentage === false || this.videoSizePercentage === null || this.videoSizePercentage === undefined){
                        //         const calc = (container.clientWidth / video.videoWidth) * 100;
                        //         diffrence = Number(calc.toFixed(2));
                        //         this.videoSizePercentage = diffrence
                        //     }else {
                        //         diffrence = this.videoSizePercentage;
                        //     }
                        //     const innerScale = diffrence / 100;
                        //     const totalWidth = Number(video.videoWidth * innerScale).toFixed(0);
                        //     const totalHeight = Number(video.videoHeight * innerScale).toFixed(0);
                        //     container.style.width = `${totalWidth}px`;
                        //     const videoAspectRatio = totalWidth / totalHeight;
                        //     videoElement.width = totalWidth;
                        //     videoElement.height = totalWidth / videoAspectRatio;
                        //     container.style.height = `${totalWidth / videoAspectRatio}px`;
                        //     updatedVideoWidth = totalWidth;
                        //     updatedVideoHeight = totalWidth / videoAspectRatio;
                        // }

                    }
                    else if (video.videoWidth < video.videoHeight) {
                        let finalVideoSizePerc

                        const calc = (parentHeight / video.videoHeight) * 100;
                        finalVideoSizePerc = Number(calc.toFixed(2));
                        const scale = finalVideoSizePerc / 100;
                        const totalWidth = video.videoWidth * scale
                        const totalHeight = video.videoHeight * scale

                        if (totalWidth > parentWidth) {
                            let adjustedVideoPerc
                            const innerCalc = (parentWidth / video.videoWidth) * 100;
                            adjustedVideoPerc = Number(innerCalc.toFixed(2));
                            function findPerfectSize(lastVideoPercentage, videoZoomHandler) {
                                const calculateSize = video.videoWidth * (lastVideoPercentage / 100)
                                if (calculateSize > totalWidth) {
                                    findPerfectSize(Number(lastVideoPercentage - 0.01))
                                } else {
                                    const finalScale = lastVideoPercentage / 100
                                    const finalWidth = video.videoWidth * finalScale
                                    const finalHeight = video.videoHeight * finalScale
                                    container.style.width = `${finalWidth}px`;
                                    const videoAspectRatio = finalWidth / finalHeight;
                                    videoElement.width = finalWidth;
                                    videoElement.height = finalWidth / videoAspectRatio;
                                    container.style.height = `${finalWidth / videoAspectRatio}px`;
                                    updatedVideoWidth = finalWidth;
                                    updatedVideoHeight = finalWidth / videoAspectRatio;
                                    return lastVideoPercentage
                                }
                            }
                            const returnedValue = findPerfectSize(adjustedVideoPerc)
                            this.videoSizePercentage = returnedValue
                        } else {
                            container.style.width = `${totalWidth}px`;
                            const videoAspectRatio = totalWidth / totalHeight;
                            videoElement.width = totalWidth;
                            videoElement.height = totalWidth / videoAspectRatio;
                            container.style.height = `${totalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = totalWidth;
                            updatedVideoHeight = totalWidth / videoAspectRatio;
                            this.videoSizePercentage = finalVideoSizePerc
                        }
                    }




                    // Update canvas size
                    if (canvas) {
                        canvas.setWidth(updatedVideoWidth);
                        canvas.setHeight(updatedVideoHeight);
                        const videoObject = this.createVideoObject(videoElement, element);


                        element.fabricObject = videoObject;
                        element.properties.imageObject = videoObject;
                        // element.properties.width = videoElement.videoWidth
                        // element.properties.height = videoElement.videoWidth

                        // Set the width and height of the video element
                        videoElement.width = 100;
                        videoElement.height = (updatedVideoHeight * 100) / updatedVideoWidth;


                        canvas.add(videoObject);
                        canvas.sendToBack(videoObject)

                        canvas.on("object:modified", function (e) {
                            if (!e.target) return;
                            const target = e.target;
                            if (target != videoObject) return;
                            const placement = element.placement;
                            const newPlacement = {
                                ...placement,
                                x: target.left ?? placement.x,
                                y: target.top ?? placement.y,
                                rotation: target.angle ?? placement.rotation,
                                width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
                                height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
                                scaleX: 1,
                                scaleY: 1,
                            };
                            const newElement = {
                                ...element,
                                placement: newPlacement,
                            };
                            store.updateEditorElement(newElement);
                        });

                        videoObject.on('mousedown', (e) => {
                            store.setSelectedElement(element)
                        })
                    }

                    break;
                }
                case "image": {
                    const imageElement = document.getElementById(element.properties.elementId);
                    if (!isHtmlImageElement(imageElement)) {
                        return;
                    }
                    const aspectRatio = imageElement?.naturalHeight / imageElement?.naturalWidth
                    const imageObject = new fabric.CoverImage(imageElement, {
                        name: element.id,
                        left: (element.placement.x * canvas.width) / 100,
                        top: (element.placement.y * canvas.height) / 100,
                        angle: element.placement.rotation,
                        objectCaching: false,
                        selectable: true,
                        lockUniScaling: true,
                        customFilter: element.properties.effect.type,
                        opacity: element.properties.opacity
                    });
                    element.fabricObject = imageObject;
                    element.properties.imageObject = imageObject;
                    const width = (element.placement.width / 100) * canvas.width
                    const height = aspectRatio * width
                    const image = {
                        w: width,
                        h: height,
                    };
                    imageObject.width = image.w;
                    imageObject.height = image.h;
                    imageElement.width = image.w;
                    imageElement.height = image.h;
                    imageObject.scaleX = element.placement.scaleX;
                    imageObject.scaleY = element.placement.scaleY;
                    canvas.add(imageObject);
                    // canvas.on("object:modified", function (e) {
                    //     if (!e.target) return;
                    //     const target = e.target;
                    //     if (target !== imageObject) return;
                    //     const placement = element.placement;
                    //     let fianlScale = 1;
                    //     if (target.scaleX && target.scaleX > 0) {
                    //         fianlScale = target.scaleX
                    //     }
                    //     const fromLeft = (target.left / canvas.width) * 100
                    //     const fromTop = (target.top / canvas.height) * 100
                    //     const newPlacement = {
                    //         ...placement,
                    //         x: fromLeft ?? element.placement.x,
                    //         y: fromTop ?? element.placement.y,
                    //         rotation: target.angle ?? placement.rotation,
                    //         scaleX: fianlScale,
                    //         scaleY: fianlScale,
                    //     };
                    //     const newElement = {
                    //         ...element,
                    //         placement: newPlacement,
                    //     };
                    //     store.updateEditorElement(newElement);
                    // });
                    break;
                }

                case "audio": {
                    // if(document.getElementById(element.properties.elementId) === null) continue

                    // const audioElement = document.getElementById(element.properties.elementId)
                    // if(!isHtmlAudioElement(audioElement)) continue;

                    // const canvas = this.canvas

                    // if(canvas){
                    // 	const audioObject = new fabric.
                    // 	element.fabricObject = audioElement
                    // }

                    break;
                }

                case "text": {
                    if (element.isVisible === true) {

                        const textObject = new CustomRectText({
                            text: element.properties.text,
                            x: element.placement.x,
                            y: element.placement.y,
                            fontSize: element.properties.fontSize,
                            fontFamily: element.properties.fontFamily,
                            fontWeight: element.properties.fontWeight,
                            textColor: element.properties.textColor,
                            selectable: true,
                            // animationType: element.properties.animationType,
                            backgroundColor: element.properties.backgroundColor,
                            width: element.properties.width,
                            height: element.properties.height,
                            underline: element.properties.underline,
                            fontStyle: element.properties.fontStyle,
                            textAlign: element.properties.textAlign,
                            borderRadius: element.properties.borderRadius,
                            scaleX: element.placement.scaleX,
                            scaleY: element.placement.scaleY,
                            lineHeight: element.properties.lineHeight,
                            charSpacing: element.properties.charSpacing,
                            txtOpacity: element.properties.txtOpacity,
                            btnOpacity: element.properties.btnOpacity,
                            outline: element.properties.outline,
                            outlineWidth: element.properties.outlineWidth,
                            angle: element.properties.angle,
                            fixedWidth: element.properties.fixedWidth,
                            // originX : 'right'
                            paddingX: element.properties.paddingX,
                            paddingY: element.properties.paddingY,
                            stroke: element.properties.stroke,
                            strokeWidth: element.properties.strokeWidth,
                            from : "preview",
                            rotation : element.placement.rotation || 0
                        }, canvas)

                        element.fabricObject = textObject;

                        canvas.add(textObject);

                    }
                    break;
                }
                case "button": {

                    if (element.isVisible === true) {
                        const buttonObject = new CustomRectButton({
                            x: element.placement.x,
                            y: element.placement.y,
                            fontSize: element.properties.fontSize,
                            fontFamily: element.properties.fontFamily,
                            fontWeight: element.properties.fontWeight,
                            // fill: element.properties.fill,
                            textColor: element.properties.textColor,
                            linkUrl: element.properties.linkUrl,
                            backgroundColor: element.properties.backgroundColor,
                            width: "",
                            height: "",
                            underline: element.properties.underline,
                            fontStyle: element.properties.fontStyle,
                            textAlign: element.properties.textAlign,
                            strokeWidth: element.properties.strokeWidth,
                            stroke: element.properties.stroke,
                            borderRadius: element.properties.borderRadius,
                            btnOpacity: element.properties.btnOpacity,
                            txtOpacity: element.properties.txtOpacity,
                            lineHeight: element.properties.lineHeight,
                            charSpacing: element.properties.charSpacing,
                            scaleX: element.placement.scaleX,
                            scaleY: element.placement.scaleY,
                            paddingX: element.properties.paddingX,
                            paddingY: element.properties.paddingY,
                            text: element.properties.text,
                            from : "preview",
                            rotation : element.placement.rotation || 0
                        }, canvas);

                        element.fabricObject = buttonObject;
                        canvas.add(buttonObject);

                        element.fabricObject = buttonObject
                        // Add event listener for button click
                        buttonObject.on('mouseup', () => {
                            if (buttonObject.linkUrl) {
                                window.open(buttonObject.linkUrl, '_blank')
                            }
                        });
                        buttonObject.hoverCursor = 'pointer'
                        // buttonObject.on("mouseover",() => {
                        //     canvas.defaultCursor = 'pointer'
                        // })
                        // buttonObject.on("mouseout",() => {
                        //     canvas.defaultCursor = 'default'
                        // })

                        // buttonObject.on('mousedown', (e) => {
                        //     this.setSelectedElement(element)
                        //     canvas.setActiveObject(buttonObject)
                        //     store.setSelectedMenuOption("Button Link")
                        // })

                        // canvas.on("object:modified", function (e) {
                        //     if (!e.target) return;
                        //     const target = e.target;

                        //     if (!target) return
                        //     if (target != buttonObject) return;
                        //     const newElement = {
                        //         ...element,
                        //         placement: {
                        //             x: target.left ?? element.placement.x,
                        //             y: target.top ?? element.placement.y,
                        //             rotation: target.angle ?? element.placement.rotation,
                        //             scaleX: target.scaleX ?? element.placement.scaleX,
                        //             scaleY: target.scaleY ?? element.placement.scaleY,
                        //         },
                        //         properties: {
                        //             ...element.properties,
                        //         },
                        //     };

                        //     store.updateEditorElement(newElement);
                        // })
                    }
                    break;
                }
                case "audio": {
                    if (element.isVisible === true) {
                    }
                    break;
                }
                default: {
                    throw new Error("Not implemented");
                }
            }
            if (element.fabricObject) {
                element.fabricObject.on("selected", function (e) {
                    store.setSelectedElement(element);
                });
            }
        }

        const selectedEditorElement = store.selectedElement;
        if (selectedEditorElement && selectedEditorElement.fabricObject) {
            canvas.setActiveObject(selectedEditorElement.fabricObject);
        }
        this.refreshAnimations();
        this.updateTimeTo(this.currentTimeInMs);
        store.canvas.renderAll();
    }

}

export function isEditorAudioElement(
    element
) {
    return element.type === "audio";
}

export function isEditorVideoElement(
    element
) {
    return element.type === "video";
}

export function isEditorImageElement(
    element
) {
    return element.type === "image";
}

export function isEditorTextElement(
    element
) {
    return element.type === "text";
}

export function isEditorButtonElement(
    element
) {
    return element.type === "button";
}

function getTextObjectsPartitionedByCharacters(textObject, element) {
    let copyCharsObjects = [];
    // replace all line endings with blank
    const characters = (textObject.text ?? "").split('').filter((m) => m !== '\n');
    const charObjects = textObject.__charBounds;
    if (!charObjects) return [];
    const charObjectFixed = charObjects.map((m, index) => m.slice(0, m.length - 1).map(m => ({ m, index }))).flat();
    const lineHeight = textObject.getHeightOfLine(0);
    for (let i = 0; i < characters.length; i++) {
        if (!charObjectFixed[i]) continue;
        const { m: charObject, index: lineIndex } = charObjectFixed[i];
        const char = characters[i];
        const scaleX = textObject.scaleX ?? 1;
        const scaleY = textObject.scaleY ?? 1;
        const charTextObject = new fabric.Text(char, {
            left: charObject.left * scaleX + (element.placement.x),
            scaleX: scaleX,
            scaleY: scaleY,
            top: lineIndex * lineHeight * scaleY + (element.placement.y),

            fontSize: textObject.fontSize,
            fontWeight: textObject.fontWeight,
            fill: '#fff',
        });
        copyCharsObjects.push(charTextObject);
    }
    return copyCharsObjects;
}