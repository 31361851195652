import { fabric } from 'fabric';

const CustomRectButton = fabric.util.createClass(fabric.Group, {
    type: 'customRectButton',

    
    initialize: function (options, canvas) {

        options || (options = {});
        this.canvas = canvas


        this.paddingX = options.paddingX || 10;
        this.paddingY = options.paddingY || 10;
        this.rotation = options.rotation || 0


        this.outerRect = new fabric.Rect({
            // width: options.outerRectWidth + options.paddingX  || 100,
            // height: options.outerRectHeight + options.paddingY  || 50,
            fill: options.backgroundColor || 'blue',
            rx: (this.canvas.width * options.borderRadius) / 100,
            ry: (this.canvas.width * options.borderRadius) / 100,
            originX: 'center', //  If you dont know than Please Dont touch this
            originY: 'center', // If you dont know than Please Dont touch this also
            stroke: options.stroke || '#fff', // Border color
            strokeWidth: (this.canvas.width * options.strokeWidth) / 100, // Border width
            alpha: 1, // Border width
            // textAlign: options.textAlign,
            opacity: options.btnOpacity || 0,
        })



        this.innerRect = new fabric.Rect({
            // width: options.innerRectWidth || 100,
            // height: options.innerRectHeight || 50,
            fill: 'transparent',
            rx: options.borderRadius || 0,
            ry: options.borderRadius || 0,
            originX: "center",
            originY: 'center',
            strokeWidth : 0.1,
            opacity: options.btnOpacity || 0,
        });

        this.textS = new fabric.IText(options.text || 'Button', {
            fontSize: (options.fontSize * this.canvas.width) / 100,
            fill: options.textColor || 'white',
            originX: 'center',
            originY: 'center',
            fontFamily: options.fontFamily,
            lineHeight: options.lineHeight || 1,
            charSpacing: options.charSpacing || 0,
            fontWeight: options.fontWeight || 400,
            underline: options.underline || false,
            fontStyle: options.fontStyle || 'normal',
            opacity: options.txtOpacity || 0,
            textAlign: options.textAlign,
        });

        this.callSuper('initialize', [this.outerRect, this.innerRect, this.textS], options);
        this._updateDimensions(options);
    },

    _updateDimensions: function (options) {
        // Get the dimensions of the text
        const textWidth = this.textS.getScaledWidth();
        const textHeight = this.textS.getScaledHeight();


        this.outerRect.set({
            width: textWidth + (this.canvas.width * this.paddingX) / 100,
            height: textHeight + (this.canvas.width * this.paddingY) / 100,
        });
        this.innerRect.set({
            width: textWidth,
            height: textHeight,
        });

        this.set({
            width: this.outerRect.width,
            height: this.outerRect.height,
            linkUrl: options.linkUrl,
            left: (this.canvas.width * options.x) / 100,
            top: (this.canvas.height * options.y) / 100,
            angle : this.rotation
        });
        if(options.from && options.from === "preview"){
            this.set({
                lockMovementX: true,
                lockMovementY: true,
                lockRotation : true,
                lockScalingFlip : true,
                lockScalingX : true,
                lockScalingY : true,
                lockUniScaling : true,
                lockSkewingX : true,
                lockSkewingY : true,
            });
        }
    },
});

export default CustomRectButton;
