import { useEffect, useState } from "react";

import add from "../../../assets/icons/add.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import plus_icon from "../../../assets/icons/plus.svg";
import { observer } from "mobx-react";
import { useStore } from "../../../utility/hooks/UseStore";
import { localStorageNames } from "../../../configs/constants";
import APICallLoader from "../../../components/ui/common/APILoaderRing";
import deleteicon from "../../../assets/delete_icon.png";
import { decryptData } from "../../../utility/utils";

const { __USER_DATA } = localStorageNames;
const Configuration = () => {
  const store = useStore();
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteModalFor, setDeleteModalFor] = useState("");
  const [newDomain, setNewDomain] = useState(false);
  const [newOpenAiApiModel, setNewOpenAiApiModel] = useState(false);
  const [canGetDataFromStore, SetCanGetDataFromStore] = useState(false);
  const [canGetOpenAIAPIFromStore, SetCanGetOpenAIAPIFromStore] =
    useState(false);
  const [runValidator, setRunValidator] = useState(false);
  const [apiCallLoader, SetApiCallLoader] = useState(false);
  const [error, setError] = useState();
  const [errorForOpenAi, setErrorForOpenAi] = useState();
  const [domainList, SetDomainList] = useState([]);
  const [openAiKey, SetOpenAiKey] = useState([]);
  const [dataForNewDomain, setDataForNewDomain] = useState({
    domain: "",
    // cname: "",
    // target: ""
  });

  const hadelsubmit = (e) => {
    e.preventDefault();
    const isError = validation();
    setRunValidator(true);
    if (isError) return;
    setNewDomain(false);
    SetApiCallLoader(true);
    const finalData = {
      userID: decryptData(localStorage.getItem(__USER_DATA))._id,
      domain: dataForNewDomain.domain,
      // cName: dataForNewDomain.cname,
      // target: dataForNewDomain.target,
      isActive: true,
    };
    store.userRegister.addDomain({
      finalData,
      SetCanGetDataFromStore,
      loader: SetApiCallLoader,
      setDataForNewDomain,
      setRunValidator,
    });
  };

  useEffect(() => {
    const userData = decryptData(localStorage.getItem(__USER_DATA));
    // store.userRegister.getListOfDomain({
    //   userID: userData._id,
    //   SetCanGetDataFromStore,
    // });
    store.userRegister.getListOfOpenIaAPI({
      SetCanGetOpenAIAPIFromStore,
    });
  }, []);

  useEffect(() => {
    if (canGetDataFromStore) {
      SetDomainList(store.userRegister.userDomainList.domainData);
      SetCanGetDataFromStore(false);
      setError();
    }
  }, [canGetDataFromStore]);

  useEffect(() => {
    if (canGetOpenAIAPIFromStore) {
      SetOpenAiKey(store.userRegister.userOpenAiAPIKeys.openAI);
      SetCanGetOpenAIAPIFromStore(false);
      setErrorForOpenAi();
    }
  }, [canGetOpenAIAPIFromStore]);

  const getConfig = decryptData(localStorage.getItem('Amns_BR_jyw12'))

  function setDataForNewDomainFunction(e) {
    const { name, value } = e.target;
    setDataForNewDomain((prev) => {
      return {
        ...prev,
        [name]: value.trim(),
      };
    });
  }

  function validation() {
    const errorahandler = {};
    let isErrorFound = false;

    if (!dataForNewDomain.domain.trim()) {
      errorahandler.domain = "Domain is required";
      isErrorFound = true;
    } else if (
      !/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        dataForNewDomain.domain
      )
    ) {
      errorahandler.domain = "Invalid Domain";
      isErrorFound = true;
    } else {
      delete errorahandler.domain;
    }

    // if (!dataForNewDomain.cname.trim()) {
    //     errorahandler.cname = "Cname is required"
    //     isErrorFound = true
    // } else if (!/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(dataForNewDomain.cname)) {
    //     errorahandler.cname = "Invalid Cname"
    //     isErrorFound = true
    // } else {
    //     delete errorahandler.cname
    // }

    // if (!dataForNewDomain.target.trim()) {
    //     errorahandler.target = "Target is required"
    //     isErrorFound = true
    // } else if (!/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(dataForNewDomain.target)) {
    //     errorahandler.target = "Invalid Target"
    //     isErrorFound = true
    // } else {
    //     delete errorahandler.target
    // }
    setError(errorahandler);
    return isErrorFound;
  }

  useEffect(() => {
    if (runValidator) {
      validation();
    }
  }, [dataForNewDomain]);

  function Delete(deleteModalFor) {
    setDeleteModel(false);
    SetApiCallLoader(true);
    if (deleteModalFor === "openAi") {
      store.userRegister.deleteOpenAiAPI({
        userID: decryptData(localStorage.getItem(__USER_DATA))._id,
        SetCanGetOpenAIAPIFromStore,
        loader: SetApiCallLoader,
      });
    } else {
      store.userRegister.deleteDomain({
        userID: decryptData(localStorage.getItem(__USER_DATA))._id,
        domainID: domainList._id,
        SetCanGetDataFromStore,
        loader: SetApiCallLoader,
      });
    }
  }

  const [isActive, setIsActive] = useState(false);
  const [tempIsActive, setTempIsActive] = useState(false);

  useEffect(() => {
    if (domainList) {
      setIsActive(domainList?.isActive);
      setTempIsActive(domainList?.isActive);
    }
  }, [domainList?.isActive]);

  const [statusUpdateModel, setStatusUpdateModel] = useState(false);

  const toggleActive = () => {
    setTempIsActive(!tempIsActive);
    setStatusUpdateModel(true);
  };

  function UpdateStatusofDomain() {
    SetApiCallLoader(true);
    const finalData = {
      isActive: tempIsActive,
    };
    store.userRegister.updateDomainStatus({
      userID: decryptData(localStorage.getItem(__USER_DATA))._id,
      finalData,
      domainID: domainList._id,
      SetCanGetDataFromStore,
      loader: SetApiCallLoader,
      setStatusUpdateModel,
    });
  }

  const [apiKey, setApiKey] = useState("");
  const [runValidatorForOpenAI, setRunValidatorForOpenAI] = useState("");

  const handelSubmitForOpenAI = (e) => {
    e.preventDefault();
    const isError = validationForOpenAI();
    setRunValidatorForOpenAI(true);
    if (isError) return;
    setNewDomain(false);
    SetApiCallLoader(true);
    const finalData = {
      key: apiKey,
    };
    store.userRegister.addOpenAiAPI({
      finalData,
      SetCanGetOpenAIAPIFromStore,
      loader: SetApiCallLoader,
      setApiKey,
      setRunValidatorForOpenAI,
      setNewOpenAiApiModel,
    });
  };

  function validationForOpenAI() {
    const errorahandler = {};
    let isErrorFound = false;

    if (!apiKey.trim()) {
      errorahandler.apiKey = "Api Key is required.";
      isErrorFound = true;
    } else {
      delete errorahandler.apiKey;
    }
    setErrorForOpenAi(errorahandler);
    return isErrorFound;
  }
  useEffect(() => {
    if (runValidatorForOpenAI) {
      validationForOpenAI();
    }
  }, [apiKey]);

  const [isActiveForOpenAi, setIsActiveForOpenAi] = useState(false);
  const [tempIsActiveForOpenAi, setTempIsActiveForOpenAi] = useState(false);

  useEffect(() => {
    if (openAiKey) {
      setIsActiveForOpenAi(openAiKey?.status);
      setTempIsActiveForOpenAi(openAiKey?.status);
    }
  }, [openAiKey?.status]);

  const [statusUpdateModelOpenAi, setStatusUpdateModelOpenAi] = useState(false);

  const toggleActiveForOpenAI = () => {
    // setTempIsActiveForOpenAi(!tempIsActiveForOpenAi);
    setStatusUpdateModelOpenAi(true);
  };

  function UpdateStatusOfOpenAi() {
    SetApiCallLoader(true);
    const finalData = {
      status: tempIsActiveForOpenAi === true ? false : true,
    };
    store.userRegister.updateOpenAiStatus({
      finalData,
      SetCanGetOpenAIAPIFromStore,
      loader: SetApiCallLoader,
      setStatusUpdateModelOpenAi,
    });
  }
  return (
    <>
      {/* Delete Model  */}
      {deleteModel && (
        <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
            {/* <h1 className="font-medium text-lg">
              {deleteModalFor === "openAi" ? "OpenAI API" : "Custom Domain"}
            </h1> */}
              <img
                src={deleteicon}
                alt="deleteicon"
                className=" mx-auto w-[100px] h-auto object-contain"
              ></img>
            <p className="text-sm font-medium text-[#ABABC0] py-[15px] text-center">
              Are you sure you want to delete this Api Key?
            </p>
            <div className="flex justify-center items-center text-sm gap-[12px]">
              <button
                onClick={() => setDeleteModel(false)}
                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  Delete(deleteModalFor);
                }}
                className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-semibold"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {/* domain Model  for open ai*/}
      {statusUpdateModelOpenAi && (
        <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
            <h1 className="font-medium text-lg">OpenAI API</h1>
            <p className="text-sm font-medium text-[#ABABC0] py-[15px]">{`Are you sure you want to ${
              tempIsActiveForOpenAi ?  "inactive" : "active"
            } your OpenAI API key?`}</p>
            <div className="flex justify-end items-center text-sm gap-[12px]">
              <button
                onClick={() => setStatusUpdateModelOpenAi(false)}
                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  UpdateStatusOfOpenAi();
                }}
                className=" bg-[#5446D0] min-w-[88px] rounded-md px-[20px] py-[12px] text-center font-semibold"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* domain Model  */}
      {statusUpdateModel && (
        <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
            <h1 className="font-medium text-lg">Custom Domain</h1>
            <p className="text-sm font-medium text-[#ABABC0] py-[30px]">{`Are you sure you want to ${
              tempIsActive ? "active" : "inactive"
            } your domain?`}</p>
            <div className="flex justify-end items-center text-sm gap-[12px]">
              <button
                onClick={() => setStatusUpdateModel(false)}
                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  UpdateStatusofDomain();
                }}
                className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-semibold"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {/* New Domain  */}
      {newDomain && (
        <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
            <h1 className="font-medium text-lg">Custom Domain</h1>

            <form onSubmit={hadelsubmit}>
              <div className="mt-[30px]">
                <label
                  htmlFor="domain"
                  className=" font-Inter text-[14px] font-semibold text-[#fff]"
                >
                  Enter your domain
                </label>
                <input
                  type="text"
                  name="domain"
                  value={dataForNewDomain.domain}
                  className=" w-full rounded-[6px] border border-[#2C2D3C]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[11px] mt-[8px] px-[10px] text-[14px] mb-[12px] outline-none"
                  placeholder="www.yourdomain.com"
                  autoComplete="off"
                  onChange={(e) => {
                    setDataForNewDomainFunction(e);
                  }}
                />
                {error && error.domain && (
                  <p className="text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]">
                    {error.domain}
                  </p>
                )}
              </div>
              {/* <div>
                                <label htmlFor="cname" className=" font-Inter text-[14px] font-semibold text-[#fff]">
                                    CNAME
                                </label>
                                <input
                                    type="text"
                                    name="cname"
                                    value={dataForNewDomain.cname}
                                    className=" w-full rounded-[6px] border border-[#2C2D3C]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[11px] mt-[8px] px-[10px] text-[14px] mb-[12px] outline-none"
                                    placeholder="www.yourdomain.com"
                                    autoComplete="off"
                                    onChange={(e) => { setDataForNewDomainFunction(e) }}
                                />
                                {error && error.cname &&
                                    <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error.cname}</p>
                                }
                            </div> */}
              {/* <div className="w-full py-[30px] px-[20px]">
                                <ul className="space-y-1 list-disc list-outside w-full font-normal text-sm text-[#ABABC0]">
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                </ul>
                            </div> */}
              {/* <div>
                                <label htmlFor="target" className=" font-Inter text-[14px] font-semibold text-[#fff]">
                                    Target
                                </label>
                                <input
                                    type="text"
                                    name="target"
                                    value={dataForNewDomain.target}
                                    className=" w-full rounded-[6px] border border-[#2C2D3C]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[11px] mt-[8px] px-[10px] text-[14px] mb-[12px] outline-none"
                                    placeholder="www.yourdomain.com"
                                    autoComplete="off"
                                    onChange={(e) => { setDataForNewDomainFunction(e) }}
                                />
                                {error && error.target &&
                                    <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error.target}</p>
                                }
                            </div> */}
              <div className="flex justify-end items-center text-sm gap-[12px] pt-[30px]">
                <button
                  onClick={() => {
                    setNewDomain(false);
                  }}
                  className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-semibold"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {newOpenAiApiModel && (
        <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
          <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[450px]">
            <img
              src={plus_icon}
              alt=""
              onClick={() => {
                setNewOpenAiApiModel(false);
                setErrorForOpenAi("");
              }}
              className="cursor-pointer rotate-45  ml-auto"
            />

            <form onSubmit={handelSubmitForOpenAI}>
              <div >
                <label
                  htmlFor="domain"
                  className=" font-Inter text-[14px] font-semibold text-[#fff]"
                >
                  Enter Open AI API <span className="text-[#FF5364]">*</span>
                </label>
                <div className=" relative">
                  <input
                    type="text"
                    name="domain"
                    value={apiKey}
                    className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[11px] mt-[8px] px-[10px] text-[14px] mb-[12px] outline-none"
                    placeholder="Enter Your Open AI API"
                    autoComplete="off"
                    onChange={(e) => {
                      setApiKey(e.target.value.trim());
                    }}
                  />
                  {errorForOpenAi && errorForOpenAi.apiKey && (
                    <p className="text-[12px] leading-[14.52px] absolute bottom-[-18px] font-medium text-[#FF5364] mb-[12px]">
                      {errorForOpenAi.apiKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center text-sm gap-[12px] mt-[10px] 2xl:mt-[30px]">
                <button
                  onClick={() => {
                    setNewOpenAiApiModel(false);
                    setErrorForOpenAi("");
                  }}
                  className=" border border-[#5446D0] rounded-md px-[20px] min-w-[130px] py-[9px] text-center font-medium"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center min-w-[130px] font-medium"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Loader When api Call */}
      <div
        className={`${
          apiCallLoader ? "flex" : "hidden"
        } fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}
      >
        <APICallLoader />
      </div>

      {/* Domain addd */}
      {/* <div className="pt-[24px]">
                <div className="flex justify-between items-center">
                    <h3 className="font-Inter font-medium text-[18px] leading-[21.78px] text-[#FFFFFF]">Custom Domain ( cname )</h3>
                    <button onClick={() => { (domainList === null || domainList === undefined || domainList.length === 0) && setNewDomain(true) }} className={`${domainList === null || domainList === undefined || domainList.length === 0 ? "" : "opacity-[0.5] cursor-not-allowed"} flex items-center justify-center gap-[10px] bg-[#5446D0] rounded-[6px] px-[20px] py-[10.5px] font-Inter font-semibold text-[16px] leading-[19.36px] tracking-[0.48px] text-[#FFFFFF]`}><img src={add} alt="add" />Assign New Domain</button>
                </div>
                <div className="w-full overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-gray-100 pt-[15.5px]">
                    <table className="ApiTable w-[100%] text-left  border border-[#272836] rounded ">
                        <thead>
                            <tr className=" bg-[#272836] text-[#FFFFFF] text-[16px] font-normal leading-[19.36px] tracking-[0.32px]">
                                <th className="pl-[14px] w-[25%] 2xl:w-[45%] py-[15.5px]"> Domain </th>
                                <th className="py-[15.5px] w-[10%]"> Status </th>
                                <th className="py-[15.5px] w-[20%] 2xl:w-[15%]"> Created At </th>
                                <th className="py-[15.5px] w-[5%] 2xl:w-[15%] text-center"> Action </th>
                                <th className="py-[15.5px] w-[15%] text-center"> Check Connected </th>
                            </tr>
                        </thead>
                        <tbody>
                            {domainList === null || domainList === undefined || domainList.length === 0 ?
                                <tr className="border-b border-[#272836]">
                                    <td className="pl-[14px] py-[15.5px] text-[#ABABC0] text-[14px]">No Domain Added</td>
                                </tr>
                                :
                                <tr className="border-b border-[#272836] text-[#ABABC0] text-[14px]">
                                    <td className="pl-[14px] py-[15.5px]">{domainList.domain}</td>
                                    <td className="py-[15.5px] ">
                                        <div className="relative w-[42px] h-[22px] cursor-pointer" onClick={toggleActive}>
                                            <input type="checkbox" id="toggleB" className="sr-only" checked={isActive} onChange={toggleActive} />
                                            <div className={`block bg-[#3B3B4F] w-[42px] h-[22px] rounded-full ${isActive ? 'bg-green-500' : 'bg-gray-500'}`}></div>
                                            <div className={` absolute ${isActive ? "right-[1px]" : 'left-[1px] '} top-[1px] bg-white w-[20px] h-[20px] rounded-full transition`}></div>
                                        </div>
                                    </td>
                                    <td className="py-[15.5px] ">{new Date(domainList.created).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                                    <td className="py-[15.5px]">
                                        <img onClick={() => { setDeleteModalFor("domain"); setDeleteModel(true) }} src={deleteIcon} alt="deleteIcon" className="cursor-pointer mx-auto" />
                                    </td>
                                    <td className="py-[15.5px] flex justify-center">
                                        <button className={`opacity-[0.2] flex items-center justify-center gap-[10px] bg-[#5446D0] rounded-[6px] p-[5px] font-Inter font-semibold text-[16px] leading-[19.36px] tracking-[0.48px] text-[#FFFFFF]`}>Check</button>

                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table >
                </div >
            </div > */}

      {/* open ai API */}
      <div className="pt-[24px] ">
        <div className="flex justify-between items-center">
          <h3 className="font-Inter font-medium text-[18px] leading-[21.78px] text-[#FFFFFF]">
            Open AI TTS
          </h3>
          <button
            onClick={() => {
              !(openAiKey?.endChar && openAiKey?.startChar) && getConfig?.openAiTTS === true &&
                setNewOpenAiApiModel(true);
            }}
            className={`${
              !(openAiKey?.endChar && openAiKey?.startChar) && getConfig?.openAiTTS === true
                ? ""
                : "opacity-[0.5] cursor-not-allowed"
            } flex items-center justify-center gap-[10px] bg-[#5446D0] rounded-[6px] px-[20px] py-[10.5px] font-Inter font-semibold text-[14px] 2xl:text-[16px] leading-[19px] tracking-[0.48px] text-[#FFFFFF]`}
          >
            <img src={add} alt="add" />
            Add API Key
          </button>
        </div>
        {/* Table  */}
        <div className="w-full h-[94%] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-gray-100 pt-[15.5px]">
          <table className="w-[100%] text-left  border border-[#272836] rounded ">
            <thead>
              <tr className="bg-[#272836] text-[#FFFFFF] text-[16px] font-normal leading-[19.36px] tracking-[0.32px]">
                <th className="pl-[14px] w-[25%] 2xl:w-[45%] py-[15.5px]">
                  {" "}
                  API Key{" "}
                </th>
                <th className="py-[15.5px] w-[10%]"> Status </th>
                {/* <th className="py-[15.5px] w-[15%]"> Created At </th> */}
                <th className="py-[15.5px] w-[5%] 2xl:w-[15%] text-center">
                  {" "}
                  Action{" "}
                </th>
                {/* <th className="py-[15.5px] w-[15%] text-center"> Check Connected </th> */}
              </tr>
            </thead>
            <tbody>
              {!(openAiKey?.endChar && openAiKey?.startChar) ? (
                <tr className="border-b border-[#272836]">
                  <td className="pl-[14px] py-[15.5px] text-[#ABABC0] text-[14px]">
                    No OpenAI API Key Added
                  </td>
                </tr>
              ) : (
                <tr className="border-b border-[#272836] text-[#ABABC0] text-[14px]">
                  <td className="pl-[14px] py-[15.5px]">{`${openAiKey?.startChar}***************${openAiKey?.endChar}`}</td>
                  <td className="py-[15.5px] ">
                    <div
                      className="relative w-[42px] h-[22px] cursor-pointer"
                      onClick={toggleActiveForOpenAI}
                    >
                      <input
                        type="checkbox"
                        id="toggleB"
                        className="sr-only"
                        checked={isActiveForOpenAi}
                        onChange={toggleActiveForOpenAI}
                      />
                      <div
                        className={`block bg-[#3B3B4F] w-[42px] h-[22px] rounded-full ${
                          isActiveForOpenAi ? "bg-[#5446d0]" : "bg-gray-500"
                        }`}
                      ></div>
                      <div
                        className={` absolute ${
                          isActiveForOpenAi ? "right-[1px]" : "left-[1px] "
                        } top-[1px] bg-white w-[20px] h-[20px] rounded-full transition`}
                      ></div>
                    </div>
                  </td>
                  {/* <td className="py-[15.5px] ">{new Date(openAiKey.date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td> */}
                  <td className="py-[15.5px]">
                    <img
                      onClick={() => {
                        setDeleteModalFor("openAi");
                        setDeleteModel(true);
                      }}
                      src={deleteIcon}
                      alt="deleteIcon"
                      className="cursor-pointer mx-auto"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default observer(Configuration);
