import Delete from "../../assets/icons/delete.svg"
import { useStore } from "../../utility/hooks/UseStore"
import APICallLoader from '../../components/ui/common/APILoaderRing'
import deleteicon from '../../assets/delete_icon.png'
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import useOutsideClick from "../../utility/hooks/useOutsideClick";
import ImportDataModal from "./ImportData"
import ReactPaginate from "react-paginate"
import plus from "../../assets/icons/plus.svg"


const ExcelListModal = ({ setExcelModel, setVariableModal, onSaveHandler }) => {

    const store = useStore()
    const [apiCallLoader, setApiCallLoader] = useState(false)
    const [deleteModel, setDeleteModel] = useState(false)
    const [confirmExport, setConfirmExport] = useState(false)
    const [importDataModal, setImportDataModal] = useState(false)
    const [excelListFromStore, setExcelDataListFromStore] = useState(false);
    const [excelDataList, setExcelDataList] = useState([])
    const { id } = useParams()

    const variables = store.videoStore?.singleCampaignData?.headers;

    useEffect(() => {
        if (excelListFromStore) {
            setExcelDataList(store.videoStore.excelDataList);
            setExcelDataListFromStore(false);
        }
    }, [excelListFromStore]);

    useEffect(() => {
        setHeaders(variables)
    }, [store.videoStore?.singleCampaignData, excelDataList])

    const [headers, setHeaders] = useState(variables);
    const [activeHeader, setActiveHeader] = useState(null); // Track which dropdown is open
    const [editValue, setEditValue] = useState(""); // Track the input field value

    // Handle dropdown toggle
    const toggleDropdown = (id, name) => {
        setActiveHeader((prev) => (prev === id ? null : id));
        setEditValue(name)
    };

    // Handle editing the header name
    const handleEdit = (id) => {
        // Find the header by its id
        const header = headers?.find(h => h.id === id);

        // Check if the editValue is different from the original value
        if (header && header?.name !== editValue) {
            setHeaders((prevHeaders) =>
                prevHeaders?.map((header) =>
                    header.id === id ? { ...header, name: editValue } : header
                )
            );
            setActiveHeader(null); // Close the dropdown after editing

            const data = {
                id: id,
                value: editValue
            };
            store.videoStore.updateVariableList({
                campaignID: store?.videoStore?.singleCampaignData?._id,
                data
            });
        }
    };

    const [headerID, setHeaderID] = useState("")

    // Handle deleting a header
    const handleDelete = () => {
        const data = {
            id: headerID,
        }
        store.videoStore.deleteVariableList({ campaignID: id, data })
        setHeaders((prevHeaders) => prevHeaders.filter((header) => header.id !== headerID));
        setDeleteModel(false)
        setTimeout(() => {
            setActiveHeader(null)
        }, 0)
    };

    const ref = useRef();
    useOutsideClick(ref, () => {
        if (activeHeader) {
            setActiveHeader(null);
        }
    });

    // for edit table cell
    const [editableCell, setEditableCell] = useState(null); // Track editable cell
    const [inputValue, setInputValue] = useState("");

    const handleEditTableCell = (id, placeholder) => {
        setEditableCell(id); // Set the editable cell by ID
        setInputValue(placeholder); // Initialize input with current value
    };

    const handleBlur = async (id) => {
        const matchingHeader = headers.find((header) => header.id === id);

        if (inputValue && matchingHeader?.placeholder !== inputValue) {
            const data = {
                id: id,
                value: inputValue
            };

            store.videoStore.updatePlaceholder({
                campaignID: store?.videoStore?.singleCampaignData?._id,
                data
            });
        }
        setEditableCell(null); // Exit edit mode
    };


    useEffect(() => {
        store.videoStore.getImportDataList({ campaignID: id, loader: setApiCallLoader, limit: 250, page: 1, setExcelDataListFromStore })
    }, [])

    useEffect(() => {
        if (excelListFromStore) {
            const updatedHeaders = headers.map(header => {
                const matchingData = excelDataList?.userData?.map(item => item.data[header.name]);
                return {
                    ...header,
                    placeholder: matchingData?.length > 0 ? matchingData : header.placeholder,
                };
            });

            setHeaders(updatedHeaders);
            setExcelDataList(store.videoStore.excelDataList);
            setExcelDataListFromStore(false);
        }
    }, [excelListFromStore, headers, excelDataList]);

    // pagination logic
    const [selectpage, setSelectPage] = useState();
    const [Itemperpage, setItemperpage] = useState("");
    const pageCount = Math.ceil(Number(excelDataList?.totalCount) / Number(250));

    useEffect(() => {
        if (pageCount && pageCount !== Infinity) {
            setItemperpage(pageCount);
        }
    }, [excelDataList]);

    const handlePageChange = (selected) => {
        setSelectPage(selected.selected);
        store.videoStore.getImportDataList({ campaignID: id, loader: setApiCallLoader, limit: 250, page: selected?.selected + 1, setExcelDataListFromStore })
    };

    const reactPaginate = {
        previousLabel: "<",
        nextLabel: ">",
        breakLabel: "",
        pageCount: Itemperpage,
        onPageChange: handlePageChange,
        containerClassName: "pagination",
        previousLinkClassName: "pagination__link",
        nextLinkClassName: "pagination__link",
        disabledClassName: "pagination__link--disabled",
        activeClassName: "pagination__link--active",
        forcePage: selectpage,
    };

    // Handle checkbox selection 
    const [selectedRows, setSelectedRows] = useState([]);

    // Function to handle checkbox change
    const handleCheckboxChange = (rowIndex) => {
        // Check if the row is already selected
        if (selectedRows.includes(rowIndex)) {
            // If selected, remove it from the array
            setSelectedRows(selectedRows?.filter(index => index !== rowIndex));
        } else {
            // If not selected, add it to the array
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    // generate Links
    const GenerateLinks = () => {
        const data = {
            ...(selectedRows?.length > 0 && { dataIds: selectedRows }),
            isSelected: selectedRows?.length > 0 ? true : false
        }
        store.videoStore.generateLinks({ campaignID: id, loader: setApiCallLoader, data: data, setExcelDataListFromStore })
        setSelectedRows([])
    }

    const toggleSelectAll = (isChecked) => {
        if (isChecked) {
            // Select all row IDs
            const allRowIds = excelDataList?.userData?.map((row) => row._id);
            setSelectedRows(allRowIds);
        } else {
            // Deselect all
            setSelectedRows([]);
        }
    };

    // delete import data
    const handleDeleteImportData = () => {
        const data = {
            ...(selectedRows?.length > 0 && { dataIds: selectedRows }),
            isSelected: selectedRows?.length > 0 ? true : false
        }
        store.videoStore.deleteImportData({ campaignID: id, loader: setApiCallLoader, data: data, setExcelDataListFromStore })
        setSelectedRows([])
    }

    // Export data in CSV
    const [exportDataAnalytics, setExportDataAnalytics] = useState(false)
    const [withAnalytics, setWithAnalytics] = useState()
    const handleExportData = () => {
        const data = {
            ...(selectedRows?.length > 0 && { dataIds: selectedRows }),
            isSelected: selectedRows?.length > 0 ? true : false,
            analytics: withAnalytics
        }
        store.videoStore.exportDataInCSV({ campaignID: id, loader: setApiCallLoader, data: data })
        setSelectedRows([])
        setWithAnalytics(false)
    }

    const [deleteModal, setDeleteModal] = useState(false)
    const [viewAnalytics, setViewAnalytics] = useState(false)
    return (
        <>
            {/* confirm Export modal here */}
            {confirmExport &&
                <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                        <p className="text-[15px] font-medium text-[#ABABC0] py-[15px] text-center">Are you sure your campaign is ready to export? Once finalized, you will not be able to edit it again.<br/> If yes, click 'Proceed' to finalize.</p>
                        <div className="flex justify-center items-center text-sm gap-[12px]">
                            <button onClick={() => setConfirmExport(false)} className="border border-[#5446D0] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                            <button onClick={() => {
                                onSaveHandler(false)
                                store.videoStore.readyToExportCampaign({ campaignID: id, setImportDataModal })
                                setConfirmExport(false)
                            }} className="bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold">Proceed</button>
                        </div>
                    </div>
                </div>
            }

            {/* Export Data Modal */}
            {exportDataAnalytics &&
                <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                        <div className=' flex items-center border-b border-[#3b3b4f] justify-between py-[10px] px-[20px]'>
                            <h1 className="text-[18px] font-medium font-Inter">Export Data</h1>
                            <img src={plus} className=' cursor-pointer rotate-45' onClick={() => setExportDataAnalytics(false)}></img>
                        </div>
                        <div className=" flex items-center justify-center my-5">
                            <div className="custom-checkbox">
                                <input
                                    type="checkbox" id="withAnalytics" name="withAnalytics" value={withAnalytics} onChange={() => setWithAnalytics(!withAnalytics)} // Check if all rows are selected
                                />
                                <label htmlFor={`withAnalytics`}></label>
                            </div>
                            <label htmlFor="withAnalytics" className="text-[#ABABC0] cursor-pointer text-[14px] font-Inter font-normal leading-[16px] ml-[10px]">Export Data With Analytics?</label>
                            {/* <input /> */}
                        </div>
                        <div className="flex justify-center items-center text-sm gap-[12px] mb-[20px]">
                            <button onClick={() => setExportDataAnalytics(false)} className="border border-[#5446D0] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                            <button onClick={() => {
                                handleExportData()
                                setExportDataAnalytics(false)
                            }} className="bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold">Export CSV</button>
                        </div>
                    </div>
                </div>
            }

            {/* Delete Model  */}
            {deleteModal && (
                <div className="fixed z-[50] top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                    <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                        <img
                            src={deleteicon}
                            alt="deleteicon"
                            className=" mx-auto w-[100px] h-auto object-contain"
                        ></img>
                        <p className="text-sm font-medium text-[#ABABC0] py-[15px] text-center">
                            Are you sure you want to delete this Data?
                        </p>
                        <div className="flex justify-center items-center text-sm gap-[12px]">
                            <button
                                onClick={() => setDeleteModal(false)}
                                className=" border border-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-medium"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    handleDeleteImportData();
                                    setDeleteModal(false);
                                }}
                                className=" bg-[#5446D0] rounded-md px-[20px] py-[12px] text-center font-semibold"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Import Data Modal */}
            {importDataModal &&
                <ImportDataModal setExcelDataListFromStore={setExcelDataListFromStore} setImportDataModal={setImportDataModal} id={id} setApiCallLoader={setApiCallLoader} />
            }

            {/* API Loader */}
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>

            {/* Import Data List Modal */}
            <div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}>
                <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                    <div onClick={() => { setExcelModel(false) }} className='w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]'></div>
                    <div className='relative min-w-[90%] max-w-[90%] pt-[15px] px-[20px]  bg-[#272836]  z-[1] rounded-[8px]'>
                        <div className='flex justify-between items-center w-[100%] border-2 border-[#3b3b4f] p-[10px] rounded-[8px]'>
                            <div>
                                <p className='text-[#ABABC0] font-Inter font-normal text-[14px] leading-[16px]'>{store.videoStore?.singleCampaignData?.campaignName}</p>
                                {excelDataList?.userData?.length > 0 && <p className='text-[#FFFFFF] font-Inter font-normal text-[14px] leading-[16px] mt-[7px]'>Total Import data : <span className="font-medium">{excelDataList?.totalCount}</span></p>}
                            </div>
                            <div className="flex items-center gap-[10px]">
                                {headers?.length > 0 && <button onClick={() => {
                                    if (!store.videoStore?.singleCampaignData?.readyToExport) {
                                        setConfirmExport(true)
                                    } else {
                                        setImportDataModal(true)
                                    }
                                }} className="bg-[#3b3b4f] font-Inter font-medium text-[14px] py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                    Import Data
                                </button>}
                                {excelDataList?.userData?.length > 0 && store.videoStore?.singleCampaignData?.readyToExport && selectedRows?.length === 0 &&
                                    <div className="gap-[10px] flex">
                                        <button onClick={() => GenerateLinks()} className="bg-[#3b3b4f] font-Inter font-medium text-[14px]  py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                            Generate All Links
                                        </button>
                                        <button onClick={() => setDeleteModal(true)} className="bg-[#3b3b4f] font-Inter font-medium text-[14px]  py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                            Delete All
                                        </button>
                                        <button onClick={() => setExportDataAnalytics(true)} className="bg-[#3b3b4f] font-Inter font-medium text-[14px]  py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                            Export All
                                        </button>
                                    </div>
                                }
                                {!store.videoStore?.singleCampaignData?.readyToExport && <button className="bg-[#5446D0] font-Inter font-medium text-[14px]  py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none" onClick={() => {
                                    setVariableModal(true)
                                }}>
                                    Add Variable
                                </button>}
                            </div>
                        </div>

                        {excelDataList?.userData?.length > 0 && <div className={`mt-[10px] ${selectedRows.length > 0 ? 'flex items-center justify-between' : 'flex items-center justify-end'} h-[30px] 2xl:h-[36px]`}>
                            {selectedRows.length > 0 && <div className=" flex items-center justify-start">
                                <p className=" text-[#ababc0] text-[14px] font-Inter font-medium">{selectedRows.length} {selectedRows.length > 1 ? 'rows' : 'row'} selected. Ready to apply changes!</p>
                                <button onClick={() => GenerateLinks()} className="bg-[#5446D0] font-Inter font-medium ml-2 text-[12px]  py-[5px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                    Generate Link
                                </button>
                                <button onClick={() => setExportDataAnalytics(true)} className="border border-[#5446D0] font-Inter font-medium ml-2 text-[12px]  py-[5px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                    Export
                                </button>
                                <button onClick={() => setDeleteModal(true)} className="border border-[#5446D0] font-Inter font-medium ml-2 text-[12px]  py-[5px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                    Delete
                                </button>
                            </div>}
                            <div className=" flex items-center justify-end">
                                <div className="custom-checkbox">
                                    <input
                                        id={`checkbox-view`}
                                        type="checkbox"
                                        onChange={(e) => setViewAnalytics(e.target.checked)}
                                        checked={viewAnalytics} // Check if all rows are selected
                                    />
                                    <label htmlFor={`checkbox-view`}></label>
                                </div>
                                <label htmlFor={`checkbox-view`} className="text-[#ABABC0] cursor-pointer text-[14px] font-Inter font-normal leading-[16px] ml-[10px]">View Analytics</label>
                            </div>
                        </div>
                        }

                        {/* Table */}

                        <div className={`w-[100%] ${excelDataList?.userData?.length > 0 || headers?.length > 0 ? '' : 'flex items-center justify-center'} my-[10px] min-h-[350px] max-h-[350px] overflow-auto scroll-smooth scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent border-[1px] border-[#3B3B4F]`}>
                            {excelDataList?.userData?.length > 0 || headers?.length > 0 ?
                                <table className='w-[100%]'>
                                    <thead className=' bg-[#1e1e29]  sticky top-[-1px] z-50'>
                                        <tr className='border-collapse'>
                                            {/* Map through headers to create table headers */}
                                            {excelDataList?.userData?.length > 0 && <th className="w-[50px]">
                                                <div className="custom-checkbox mx-auto">
                                                    <input
                                                        id={`checkbox-all`}
                                                        type="checkbox"
                                                        onChange={(e) => toggleSelectAll(e.target.checked)}
                                                        checked={selectedRows.length === excelDataList.userData.length} // Check if all rows are selected
                                                    />
                                                    <label htmlFor={`checkbox-all`}></label>
                                                </div>
                                            </th>}
                                            {headers?.map((header) => (
                                                <th key={header.id} onClick={() => toggleDropdown(header.id, header.name)} className='text-white cursor-pointer border border-[#3B3B4F] hover:bg-[#3b3b4f] relative text-left py-[10px] text-[14px] pl-[10px] min-w-[100px]'>
                                                    <div>
                                                        {header.name}
                                                    </div>
                                                    {/* Dropdown for edit/delete */}
                                                    {activeHeader === header?.id && excelDataList?.userData?.length === 0 && (
                                                        <div ref={ref} onClick={(e) => e?.stopPropagation()} className="absolute min-w-[100px] top-full left-0 bg-[#1e1e29] text-white shadow-md rounded mt-1 p-2 z-40">
                                                            <input
                                                                type="text"
                                                                value={editValue}
                                                                onChange={(e) => setEditValue(e.target.value?.trimStart()?.replace(/[^a-z/A-Z\s]/g, ""))}
                                                                placeholder="Edit header name"
                                                                onBlur={() => handleEdit(header.id)}
                                                                className="border border-[#3b3b4f] bg-[#272836] font-normal pl-2 outline-none text-white p-1 rounded mb-2 w-full"
                                                            />
                                                            <button
                                                                onClick={() => {
                                                                    setDeleteModel(true)
                                                                    setHeaderID(header.id)
                                                                }}
                                                                className=" flex items-center w-full text-left py-1 pl-2 rounded hover:bg-[#272836]"
                                                            >
                                                                <img src={Delete} alt="delete" className="w-4 h-4 inline-block mr-2" />
                                                                Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                    {deleteModel &&
                                                        <div className="fixed top-0 left-0 right-0 bottom-0 w-full bg-black bg-opacity-30 z-50">
                                                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] p-[24px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                                                                <img src={deleteicon} alt="deleteicon" className=" mx-auto w-[100px] h-auto object-contain"></img>
                                                                <p className="text-[#ABABC0] my-[20px]  font-Inter font-normal text-[14px] leading-[16.94px] text-center tracking-[0.48px]">Deleting this variable will remove it from the canvas and any associated data or connections. Are you sure you want to proceed?</p>
                                                                <div className="flex justify-center items-center text-sm gap-[12px]">
                                                                    <button onClick={() => {
                                                                        setDeleteModel(false)
                                                                        setTimeout(() => {
                                                                            setActiveHeader(null)
                                                                        }
                                                                            , 0)
                                                                    }} className="border border-[#5446D0] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                                                                    <button onClick={() => { handleDelete(headerID) }} className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold">Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                            ))}
                                            {excelDataList?.userData?.some(row => row?.metaData?.isExported) && (
                                                <th className="text-white cursor-pointer hover:bg-[#3b3b4f] border border-[#3B3B4F] text-left py-[10px] text-[14px] pl-[10px] min-w-[120px]">Links</th>
                                            )}
                                            {viewAnalytics &&
                                                <>
                                                    <th className="text-white cursor-pointer hover:bg-[#3b3b4f] border border-[#3B3B4F] text-left py-[10px] text-[14px] pl-[10px] min-w-[100px]">Watch</th>
                                                    <th className="text-white cursor-pointer hover:bg-[#3b3b4f] border border-[#3B3B4F] text-left py-[10px] text-[14px] pl-[10px] min-w-[120px]">Watch Count</th>
                                                    <th className="text-white cursor-pointer hover:bg-[#3b3b4f] border border-[#3B3B4F] text-left py-[10px] text-[14px] pl-[10px] min-w-[100px]">Device</th>
                                                    <th className="text-white cursor-pointer hover:bg-[#3b3b4f] border border-[#3B3B4F] text-left py-[10px] text-[14px] pl-[10px] min-w-[100px]">Screen Size</th>
                                                </>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {excelDataList?.userData?.length > 0 ? (
                                            excelDataList?.userData.map((row, rowIndex) => (
                                                <tr key={rowIndex} className="border-y-[1px] border-[#3B3B4F]">
                                                    <td>
                                                        <div className="custom-checkbox mx-auto">
                                                            <input
                                                                id={`checkbox-${row._id}`}
                                                                type="checkbox"
                                                                // Handle checkbox selection
                                                                onChange={() => handleCheckboxChange(row._id)}
                                                                // Mark checkbox as selected if the row is in the selectedRows array
                                                                checked={selectedRows.includes(row._id)}
                                                            />
                                                            <label htmlFor={`checkbox-${row._id}`}></label>
                                                        </div>
                                                    </td>
                                                    {headers?.map((header, headerIndex) => (
                                                        <td key={headerIndex} className="text-left border border-[#3B3B4F] py-2 pl-[10px] font-Inter text-[14px] leading-[16.94px] text-[#fff] relative">
                                                            {row.data[header.name] || "N/A"}
                                                        </td>
                                                    ))}
                                                    {row?.metaData?.isExported && (
                                                        <td className="text-left select-text border border-[#3B3B4F] py-2 px-[10px] font-Inter text-[14px] leading-[16.94px] text-[#ababc0] relative">
                                                            {`https://player.hyperclip.one/${row._id}`}
                                                        </td>
                                                    )}
                                                    {viewAnalytics &&
                                                        <>
                                                            <td className="text-left border border-[#3B3B4F] py-2 pl-[10px] font-Inter text-[14px] leading-[16.94px] text-[#ababc0] relative">
                                                                {row?.analytics?.watch === true && "Yes"}
                                                            </td>
                                                            <td className="text-left border border-[#3B3B4F] py-2 pl-[10px] font-Inter text-[14px] leading-[16.94px] text-[#ababc0] relative">
                                                                {row?.analytics?.watchCount === 0 ? "" : row?.analytics?.watchCount}
                                                            </td>
                                                            <td className="text-left border border-[#3B3B4F] py-2 pl-[10px] font-Inter text-[14px] leading-[16.94px] text-[#ababc0] relative">
                                                                {row?.analytics?.device}
                                                            </td>
                                                            <td className="text-left border border-[#3B3B4F] py-2 pl-[10px] font-Inter text-[14px] leading-[16.94px] text-[#ababc0] relative">
                                                                {row?.analytics?.screenSize}
                                                            </td>
                                                        </>
                                                    }
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className="border-y-[1px] border-[#3B3B4F]">
                                                {headers?.map((header) => (
                                                    <td
                                                        key={header.id}
                                                        className="text-left border border-[#3B3B4F] font-Inter font-medium text-[14px] leading-[16px] text-[#ABABC0] relative"
                                                        onClick={() => handleEditTableCell(header.id, header.placeholder)} // Make td clickable
                                                    >
                                                        <input
                                                            type="text"
                                                            value={editableCell === header.id ? inputValue : header.placeholder}
                                                            onChange={(e) => setInputValue(e.target.value)}
                                                            onBlur={() => handleBlur(header.id)}
                                                            className="w-full bg-transparent px-2 py-[10px] outline-none text-[#ABABC0]"
                                                            // disabled={editableCell !== header.id} // Disable input if not in edit mode
                                                            autoFocus={editableCell === header.id} // Focus when editable
                                                            style={{ height: "100%", boxSizing: "border-box" }} // Ensure consistent size
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        )}
                                    </tbody>
                                </table> :
                                <div className=" text-center">
                                    <p className="text-[#fff] font-Inter font-medium text-[18px] leading-[21px]">No Variables Found</p>
                                    <p className="text-[#ABABC0] font-Inter font-medium mt-[14px] text-[14px] leading-[20px]">You haven’t added any variables yet. <br /> Click Add Variable to create your first one and get started!
                                    </p>
                                </div>}
                        </div>

                        {/* Pagination */}
                        {excelDataList?.userData?.length > 0 && <div className=" absolute left-[50%] transform translate-x-[-40%] bottom-[25px]">
                            <ReactPaginate
                                {...reactPaginate}
                                breakLabel="..."
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                            />
                        </div>}
                        <button className={`mb-[15px] flex  ${excelDataList?.userData?.length > 0 ? 'ml-auto' : 'mx-auto'}  rounded-[4px] border border-[#5446d0] px-[32px] py-[11px] text-[#ABABC0] font-Inter font-medium text-[14px] leading-[16px]`} onClick={() => { setExcelModel(false) }}>Close</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default observer(ExcelListModal)