import { lazy } from "react"
import Integration from "../../../pages/dashboard/Integration"
// import Settings from "../../../pages/dashboard/settings"
// import PrivateRoute from "../../../components/routeTypes/PrivateRoute"
// import Account from "../../../pages/dashboard/account"
// import DashboardLayout from "../../../layout/DashboardLayout"



const PrivateRoute = lazy(() => import('../../../components/routeTypes/PrivateRoute'))
const Account = lazy(() => import('../../../pages/dashboard/account'))
const LoaderRing = lazy(() => import('../../../components/ui/common/LoaderRing'))
const DashboardLayout = lazy(() => import('../../../layout/DashboardLayout'))
const Dashboard = lazy(() => import('../../../pages/dashboard'))
const Settings = lazy(() => import('../../../pages/dashboard/settings'))


const dashboardRoutes = [
    {
        path: "/dashboard",
        element: <PrivateRoute><DashboardLayout><Dashboard /></DashboardLayout></PrivateRoute>,
        loader: <LoaderRing />,
    },
    {
        path: "/account/:id",
        element: <PrivateRoute><DashboardLayout><Account /></DashboardLayout></PrivateRoute>,
        loader: <LoaderRing />,
    },
    {
        path: "/integration/:id",
        element: <PrivateRoute><DashboardLayout><Integration/></DashboardLayout></PrivateRoute>,
        loader: <LoaderRing />,
    },
    // {
    //     path: "/settings",
    //     element: <PrivateRoute><DashboardLayout><Settings /></DashboardLayout></PrivateRoute>,
    //     loader: <LoaderRing />,
    // }
]
export default dashboardRoutes