import React, { useState } from 'react'
import plus_icon from "../../assets/icons/plus.svg";
import add_icon from "../../assets/add_variable.png";
import delete_icon from "../../assets/delete_variable.png";
import { useStore } from '../../utility/hooks/UseStore';
import { decryptData } from '../../utility/utils';
function AddVariable({ setVariableModal, setApiCallLoader }) {
    const store = useStore();
    const videoStore = store.videoStore;
    const initialFields = [{ name: '', placeholder: '' }];
    const [fields, setFields] = useState(initialFields);
    const [error, setError] = useState('');

    // Handle adding new input fields
    const addFields = () => {
        setFields([...fields, { name: '', placeholder: '' }]);
        setError('');
    };

    // Handle changes in input values
    const handleInputChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value;
        setFields(updatedFields);
        setError('');
    };

    // Handle deleting an input field
    const deleteField = (index) => {
        const updatedFields = [...fields];
        updatedFields.splice(index, 1);
        setFields(updatedFields.length ? updatedFields : initialFields);
        setError('');
    };

    // Validate fields and handle submit
    const handleSubmit = () => {
        // Check for empty fields
        const hasEmptyFields = fields.some(
            (field) => !field.name.trim() || !field.placeholder.trim()
        );

        // Check for duplicate variable names
        const variableNames = fields.map((field) => field.name.trim());
        const hasDuplicates =
            new Set(variableNames).size !== variableNames.length;

        const headers = videoStore?.singleCampaignData?.headers;

        const hasDuplicatesInHeaders = (variableNames, headers) => {
            // Extract 'name' properties from headers into a Set for quick lookup
            const headerNames = new Set(headers?.map((header) => header.name));

            // Check if any variable name exists in the 'name' properties of headers
            return variableNames?.some((name) => headerNames?.has(name));
        };

        const hasDuplicatesheaders = hasDuplicatesInHeaders(variableNames, headers);

        if (hasEmptyFields) {
            setError('Please fill out all fields before submitting.');
            return;
        }

        if (hasDuplicates) {
            setError('Duplicate variable names are not allowed.');
            return;
        } else if (hasDuplicatesheaders) {
            setError("Variable name already exists in headers");
            return;
        }

        const trimmedFields = fields.map((field) => ({
            ...field,
            name: field.name?.trim(),
            placeholder: field.placeholder?.trim(),
        }));

        const data = {
            campaignID: videoStore?.singleCampaignData?._id,
            variable: trimmedFields
        }
        videoStore.addVariableList({ data, setVariableModal, loader: setApiCallLoader });
        setError('');
    };

    // const planData = decryptData(localStorage.getItem('Amns_BR_jyw12'))
    // const headers = videoStore.singleCampaignData.headers;
    return (
        <div
            className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-[70] bg-opacity-80 flex justify-center items-center`}
        >
            <div className="min-w-[420px]  relative flex justify-center items-center">
                <div
                    onClick={() => {
                        setVariableModal(false);
                    }}
                    className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1] "
                ></div>

                <div className=" w-full py-[15px] px-[20px] bg-[#272836] flex flex-col items-start  z-[1] rounded-[8px]">
                    <div className=' flex items-center  w-full justify-between mb-5'>
                        <p className='text-[#FFFFFF] font-Inter font-semibold text-[18px] leading-[21.78px]'>Add Variables
                            {/* ({headers?.length} / {planData?.maxHeaders} slots used) */}
                        </p>
                        <img
                            src={plus_icon}
                            alt=""
                            onClick={() => {
                                setVariableModal(false);
                            }}
                            className="cursor-pointer rotate-45 self-end"
                        />
                    </div>
                    <div className='max-h-[450px]  overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                        {fields?.map((field, index) => (
                            <div key={index} className=' flex items-center mt-[10px]  gap-[10px]'>
                                <div key={index} className='flex gap-[10px]'>
                                    <input
                                        type="text"
                                        placeholder="Enter Variable name"
                                        value={field.name}
                                        onChange={(e) => {
                                            const value = e.target.value?.trimStart();
                                            if (/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/.test(value) || value === "") {
                                                handleInputChange(index, 'name', value);
                                            }
                                        }}
                                        className="w-full rounded-[6px] border border-[#3B3B4F] bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Enter Value"
                                        value={field.placeholder?.trimStart()}
                                        onChange={(e) => handleInputChange(index, 'placeholder', e.target.value)}
                                        className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px]  pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                                    />
                                </div>
                                <div className=' flex items-center justify-start'>
                                    {index === fields.length - 1 && (
                                        <button
                                            onClick={addFields}
                                            className='mr-[10px]'
                                        >
                                            <img src={add_icon} alt='add_icon'></img>
                                        </button>
                                    )}
                                    <button
                                        onClick={() => deleteField(index)}
                                    >
                                        <img src={delete_icon} alt='delete_icon'></img>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {error && <p className="text-[12px] mt-2 leading-[14.52px] font-medium text-[#FF5364]">{error}</p>}
                    <button
                        type='submit'
                        className="rounded-[6px] mt-5 border-[1px] mx-auto 2xl:text-[16px] py-[11px] min-w-[130px] px-[35px] text-[#FFFFFF] bg-[#5446D0] border-[#5446D0] font-Inter font-medium text-[14px] leading-[16.94px] tracking-[0.48px] "
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddVariable