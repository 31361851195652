'use client'
import React from "react";
import { observer } from "mobx-react";
import { MdDelete } from "react-icons/md";
import { useStore } from "../../../utility/hooks/UseStore";
import CustomSelect from "../../commanComponents/customSelectElement";


const ANIMATION_TYPE_TO_LABEL = {
  fadeIn: "Fade In",
  fadeOut: "Fade Out",
  slideIn: "Slide In",
  slideOut: "Slide Out",
  breath: "Breath",
  zoomIn: "Zoom In",
  zoomOut: "zoom Out",
  // flipInX : "Flip In X",
  // flipInY : "Flip In Y",
  bounceIn: "Bounce In",
  bounceOut: "Bounce Out",
  lightspeedOut: "LightSpeed Out",
  lightspeedIn: "LightSpeed In",
  rotate: "Rotate",
  typewriter: "TypeWriter"
};

export const AnimationResource = observer((props) => {
  const mainStore = useStore()
  const store = mainStore.videoStore
  return (
    <div className="rounded items-center bg-[#3B3B4F]  flex flex-col relative min-h-[70px] px-[16px] font-Inter text-[#FFFFFF]">
      <div className="flex flex-row justify-between w-full">
        {/* <div className="font-medium font-Inter py-1 text-base text-left w-full">
          {ANIMATION_TYPE_TO_LABEL[props.animation.type]}
        </div> */}
        {/* <button
          className=" bg-[rgba(0,0,0,.25)] rounded z-10 text-white absolute right-3 top-[2px] font-bold py-1 text-lg outline-none"
          onClick={() => store.removeAnimation(props.animation.id)}
        >
          <MdDelete size="20" />
        </button> */}
      </div>
      {props.animation.type === "fadeIn" ||
        props.animation.type === "fadeOut" ? (
        <FadeAnimation
          animation={props.animation}
        />
      ) : null}
      {props.animation.type === "slideIn" ||
        props.animation.type === "slideOut" ? (
        <SlideAnimation
          animation={props.animation}
        />
      ) : null}
      {props.animation.type === "zoomIn" ||
        props.animation.type === "zoomOut" ? (
        <ZoomAnimation
          animation={props.animation} />
      ) : null}
      {/* {props.animation.type === "flipInX" ||
       props.animation.type === "flipInY" ? (
        <FlipAnimation 
         animation={props.animation as FlipInAnimation | FlipInYAnimaion} />
      ): null} */}
      {props.animation.type === "bounceIn" ||
        props.animation.type === "bounceOut" ? (
        <BounceAnimation
          animation={props.animation} />
      ) : null}
      {props.animation.type === "lightspeedOut" ||
        props.animation.type === "lightspeedIn" ? (
        <LightSpeedAnimation
          animation={props.animation} />
      ) : null}
      {/* {props.animation.type === "rotate"  ? (
       <RotateAnimation
       animation={props.animation as RotateInAnimation } /> 
      ) : null} */}
      {props.animation.type === "typewriter" ? (
        <TypeAnimation
          animation={props.animation} />
      ) : null}
    </div>
  );
});

export const FadeAnimation = observer((props) => {
  const mainStore = useStore()
  const store = mainStore.videoStore
  return (
    <div className="flex flex-col w-full items-start">
      {/* duration */}
      <div className="flex flex-row items-center justify-between my-1">
        <div className="text-white text-xs">Duration(s)</div>
        <input
          className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none"
          type="number"
          step={0.1}
          min={0.1}
          max={store?.videoDuration}
          value={props.animation.duration ? props.animation.duration / 1000 : ''}
          onChange={(e) => {
            if(e.target.value < store?.videoDuration){
            let inputValue = e.target.value;

            // If input is empty, handle clearing the input field
            if (inputValue === '') {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: null, // or undefined, or a special "empty" value you choose
              });
              return;
            }
            if (e.target.value < 0.1) {
              e.target.value = 0.1;
            }
            
            const duration = Number(e.target.value) * 1000;
            const isValidDuration = duration > 0;
            let newDuration = isValidDuration ? duration : 0;
            if (newDuration < 10) {
              newDuration = 10;
            }
            store.updateAnimation(props.animation.id, {
              ...props.animation,
              duration: newDuration,
            });
          }
          }}
        />
      </div>
    </div>
  );
});

// Animation has direction 'left', 'right', 'top', 'bottom' in properties
// These properties can be selected by select element
export const SlideAnimation = observer(
  (props) => {
    const mainStore = useStore()
    const store = mainStore.videoStore

    function handleAnimationDirection(item) {
      store.updateAnimation(props.animation.id, {
        ...props.animation,
        properties: {
          ...props.animation.properties,
          direction: item.target.value,
        },
      });
    }
    function handleAnimationType(item) {
      store.updateAnimation(props.animation.id, {
        ...props.animation,
        properties: {
          ...props.animation.properties,
          textType: item.target.value,
        },
      });
    }

    return (
      <div className="flex flex-col w-full items-start">
        {/* duration */}
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Duration(s)</div>
          <input
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none"
            type="number"
            step={0.1}
            min={0.1}
            max={store?.videoDuration}
            value={props.animation.duration ? props.animation.duration / 1000 : ''}
            onChange={(e) => {
              if(e.target.value < store?.videoDuration){
              let inputValue = e.target.value;

              // If input is empty, handle clearing the input field
              if (inputValue === '') {
                store.updateAnimation(props.animation.id, {
                  ...props.animation,
                  duration: null, // or undefined, or a special "empty" value you choose
                });
                return;
              }
              if (e.target.value < 0.1) {
                e.target.value = 0.1;
              }
              const duration = Number(e.target.value) * 1000;
              const isValidDuration = duration > 0;
              let newDuration = isValidDuration ? duration : 0;
              if (newDuration < 10) {
                newDuration = 10;
              }
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: newDuration,
              });
            }
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-between my-1 w-full">
          <div className="text-white text-xs">Direction</div>
          {/* <select
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-full text-xs outline-none"
            value={props.animation.properties.direction}
            onChange={(e) => {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                properties: {
                  ...props.animation.properties,
                  direction: e.target.value,
                },
              });
            }}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
          </select> */}
          <CustomSelect stylesForOptions={"max-h-[65px] w-[100%] overflow-y-auto overflow-x-hidden z-[50] scrollbar-thin scrollbar-thumb-[#5446D0] capitalize scrollbar-track-transparent"} styles={"bg-[#272836] w-[100%] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none focus:outline-none scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent "} options={['left', 'right', 'top', 'bottom']} value={props.animation.properties.direction} onChange={handleAnimationDirection} name={"Direction"} />

        </div>
        {/* <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Use Mask</div>
          <input
            className="bg-slate-100 text-black rounded-lg px-2 py-1 ml-2 w-16 text-xs outline-none"
            type="checkbox"
            checked={props.animation.properties.useClipPath}
            onChange={(e) => {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                properties: {
                  ...props.animation.properties,
                  useClipPath: e.target.checked,
                },
              });
            }}
          />
        </div> */}
        {/* <div className="flex flex-row items-center justify-between my-1"> */}
          {/* <div className="text-white text-xs">Type</div> */}
          {/* <select
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-full text-xs outline-none"
            value={props.animation.properties.textType}
            onChange={(e) => {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                properties: {
                  ...props.animation.properties,
                  textType: e.target.value,
                },
              });
            }}
          >
            <option value="none">None</option>
            <option value="character">Character</option>
          </select> */}
          {/* <CustomSelect stylesForOptions={"max-h-[65px] w-[100%] z-[50] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-[#5446D0] capitalize scrollbar-track-transparent"} styles={"bg-[#272836] w-[100%] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none focus:outline-none scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent w-[130px]"} options={['none', 'character']} value={props.animation.properties.textType} onChange={handleAnimationType} name={"Type"} /> */}
        {/* </div> */}
      </div>
    );
  }
);

export const ZoomAnimation = observer(
  (props) => {
    const mainStore = useStore()
    const store = mainStore.videoStore
    return (
      <div className="flex flex-col w-full items-start">
        {/* duration */}
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Duration(s)</div>
          <input
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none"
            type="number"
            step={0.1}
            min={0.1}
            max={store?.videoDuration}
            value={props.animation.duration ? props.animation.duration / 1000 : ''}
            onChange={(e) => {
              if(e.target.value < store?.videoDuration){
              let inputValue = e.target.value;

              // If input is empty, handle clearing the input field
              if (inputValue === '') {
                store.updateAnimation(props.animation.id, {
                  ...props.animation,
                  duration: null, // or undefined, or a special "empty" value you choose
                });
                return;
              }
              if (e.target.value < 0.1) {
                e.target.value = 0.1;
              }
              const duration = Number(e.target.value) * 1000;
              const isValidDuration = duration > 0;
              let newDuration = isValidDuration ? duration : 0;
              if (newDuration < 10) {
                newDuration = 10;
              }
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: newDuration,
              });
            }
            }}
          />
        </div>
      </div>
    );
  }
);

// export const FlipAnimation = observer(
//   (props: { animation: FlipInAnimation | FlipInYAnimaion }) => {
//     const store = React.useContext(StoreContext);
//     return (
//       <div className="flex flex-col w-full items-start">
//         {/* duration */}
//         <div className="flex flex-row items-center justify-between my-1">
//           <div className="text-white text-xs">Duration(s)</div>
//           <input
//             className="bg-slate-100 text-black rounded-lg px-2 py-1 ml-2 w-16 text-xs"
//             type="number"
//             value={props.animation.duration / 1000}
//             onChange={(e) => {
//               const duration = Number(e.target.value) * 1000;
//               const isValidDuration = duration > 0;
//               let newDuration = isValidDuration ? duration : 0;
//               if (newDuration < 10) {
//                 newDuration = 10;
//               }
//               store.updateAnimation(props.animation.id, {
//                 ...props.animation,
//                 duration: newDuration,
//               });
//             }}
//           />
//         </div>
//       </div>
//     );
//   }
// );

export const BounceAnimation = observer(
  (props) => {
    const mainStore = useStore()
    const store = mainStore.videoStore
    return (
      <div className="flex flex-col w-full items-start">
        {/* duration */}
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Duration(s)</div>
          <input
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none"
            type="number"
            step={0.1}
            min={0.1}
            max={store?.videoDuration}
            value={props.animation.duration ? props.animation.duration / 1000 : ''}
            onChange={(e) => {
              if(e.target.value < store?.videoDuration){
              let inputValue = e.target.value;

              // If input is empty, handle clearing the input field
              if (inputValue === '') {
                store.updateAnimation(props.animation.id, {
                  ...props.animation,
                  duration: null, // or undefined, or a special "empty" value you choose
                });
                return;
              }
              if (e.target.value < 0.1) {
                e.target.value = 0.1;
              }
              const duration = Number(e.target.value) * 1000;
              const isValidDuration = duration > 0;
              let newDuration = isValidDuration ? duration : 0;
              if (newDuration < 10) {
                newDuration = 10;
              }
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: newDuration,
              });
            }
            }}
          />
        </div>
      </div>
    );
  }
);

export const LightSpeedAnimation = observer(
  (props) => {
    const mainStore = useStore()
    const store = mainStore.videoStore
    return (
      <div className="flex flex-col w-full items-start">
        {/* duration */}
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Duration(s)</div>
          <input
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs outline-none"
            type="number"
            step={0.1}
            min={0.1}
            max={store?.videoDuration}
            value={props.animation.duration ?  props.animation.duration / 1000 : ''}
            onChange={(e) => {
              if(e.target.value < store?.videoDuration){
              let inputValue = e.target.value;

              // If input is empty, handle clearing the input field
              if (inputValue === '') {
                store.updateAnimation(props.animation.id, {
                  ...props.animation,
                  duration: null, // or undefined, or a special "empty" value you choose
                });
                return;
              }
              if (e.target.value < 0.1) {
                e.target.value = 0.1;
              }
              const duration = Number(e.target.value) * 1000;
              const isValidDuration = duration > 0;
              let newDuration = isValidDuration ? duration : 0;
              // if (newDuration < 10) {
              //   newDuration = 10;
              // }
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: newDuration,
              });
            }
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Direction</div>
          <select
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-full text-xs outline-none"
            value={props.animation.properties.direction}
            onChange={(e) => {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                properties: {
                  ...props.animation.properties,
                  direction: e.target.value,
                },
              });
            }}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
          </select>
        </div>

        {/* <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Type</div>
          <select
            className="bg-slate-100 text-black rounded-lg px-2 py-1 ml-2 w-16 text-xs"
            value={props.animation.properties.textType}
            onChange={(e) => {
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                properties: {
                  ...props.animation.properties,
                  textType: e.target.value as L,
                },
              });
            }}
          >
            <option value="none">None</option>
            <option value="character">Character</option>
          </select>
        </div> */}
      </div>
    );
  }
);

// export const RotateAnimation = observer(
//   (props: { animation: RotateInAnimation }) => {
//     const store = React.useContext(StoreContext);
//     return (
//       <div className="flex flex-col w-full items-start">
//         {/* duration */}
//         <div className="flex flex-row items-center justify-between my-1">
//           <div className="text-white text-xs">Duration(s)</div>
//           <input
//             className="bg-slate-100 text-black rounded-lg px-2 py-1 ml-2 w-16 text-xs"
//             type="number"
//             value={props.animation.duration / 1000}
//             onChange={(e) => {
//               const duration = Number(e.target.value) * 1000;
//               const isValidDuration = duration > 0;
//               let newDuration = isValidDuration ? duration : 0;
//               if (newDuration < 10) {
//                 newDuration = 10;
//               }
//               store.updateAnimation(props.animation.id, {
//                 ...props.animation,
//                 duration: newDuration,
//               });
//             }}
//           />
//         </div>
//       </div>
//     );
//   }
// );



export const TypeAnimation = observer(
  (props) => {
    const mainStore = useStore()
    const store = mainStore.videoStore
    return (
      <div className="flex flex-col w-full items-start">
        {/* duration */}
        <div className="flex flex-row items-center justify-between my-1">
          <div className="text-white text-xs">Duration(s)</div>
          <input
            className="bg-[#272836] text-white rounded px-2 py-1 ml-2 w-16 text-xs"
            type="number"
            value={props.animation.duration / 1000}//  1/1000= 0.01
            min={0}
            minLength={0}




            onChange={(e) => {// 0.01 *1000
              const duration = Number(e.target.value) * 1000;
              const isValidDuration = duration > 0;
              let newDuration = isValidDuration ? duration : 0;
              // if (newDuration < 10) {
              //   newDuration = 10;
              // }
              store.updateAnimation(props.animation.id, {
                ...props.animation,
                duration: newDuration,
              });
            }}
          />
        </div>
      </div>
    );
  }
);