import React, { useEffect, useState } from 'react'
import upload_img from '../../../../assets/upload_files.png'
import plus from "../../../../assets/icons/plus.svg"
import excel_upload from "../../../../assets/icons/excel_upload.svg";
import { useStore } from '../../../../utility/hooks/UseStore';
import APICallLoader from "../../../ui/common/APILoaderRing";
import axios from 'axios';
import { getUid } from '../../../../utility/videoEditorUtils';
import delete_img from '../../../../assets/delete_img.png'
import plus_img from '../../../../assets/plus_img.png'
import deleteicon from '../../../../assets/delete_icon.png'
import upload_icon from '../../../../assets/icons/upload.svg'

const UploadPanel = () => {
    const [uploadModal, setuploadModal] = useState(false)
    const store = useStore()

    const [apiCallLoader, setApiCallLoader] = useState(false)
    const [imageListFromStore, setimageListFromStore] = useState(false);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const onInputHandler = (e) => {
        setApiCallLoader(true)
        var formData = new FormData();
        formData.append('file', e.target.files[0])
        formData.append('name', e.target.files[0]?.name.replaceAll(' ', '_'))
        setPage(1)
        store.videoStore.uploadSingleImages({ finalData: formData, setuploadModal: setuploadModal, loader: setApiCallLoader, setimageListFromStore, limit: 25, page: 1 })
    }


    useEffect(() => {
        store.videoStore.getAllMedia({ setimageListFromStore, limit: limit, page: page })
    }, [])

    const [allMedia, setAllMedia] = useState("")

    useEffect(() => {
        if (imageListFromStore) {
            setAllMedia(store.videoStore.getimage || []);
            setimageListFromStore(false);
        }
    }, [imageListFromStore])


    const addImageToCanvas = async (file) => {
        const id = getUid();
        const options = {
            src: file,
            isVisible: true,
            type: 'image',
            x: 50,
            y: 50,
            width: 15,
            height: 0,
            rotation: 0,
            scaleX: 1,
            scaleY: 1,
            start: 0,
            end: store.videoStore.maxTime,
            UpdateStart: 0,
            UpdateEnd: 0,
            elementId: id,
            opacity: 1,
            created: new Date()?.getTime()
        }
        store.videoStore.addImage(options)
        store.videoStore.setSelectedMenuOption('Image Edit Panel')
    }

    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const viewMoreImage = () => {
        setPage(Number(page) + Number(1))
        store.videoStore.getAllMedia({
            setimageListFromStore,
            limit: Number(limit),
            page: Number(page) + Number(1),
            viewMore: true
        });
    };

    const [imgPath, setImgPath] = useState("")

    const deleteImage = () => {    
        const data = {
        path : imgPath
    }
    setPage(1)
        store.videoStore.DeleteSingleImage({
            data,
            setimageListFromStore,
            loader: setApiCallLoader,
            limit: 25,
            page: 1
        });
        setOpenDeleteModal(false);
    }

    return (
        <div className=' p-[20px]'>
            <div
                className={`${apiCallLoader ? "flex" : "hidden"
                    } fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}
            >
                <APICallLoader />
            </div>
            <button onClick={() => setuploadModal(true)} className="flex gap-[10px] items-center justify-center w-full rounded bg-[#5446d0] p-[7px] outline-none">
                <img src={upload_img} alt='upload_img'></img>
                <span className="text-[#FFFFFF] font-medium  font-Inter">Upload files</span>
            </button>


            <div className=' grid grid-cols-12 gap-[5px] py-[20px] 2xl:gap-[10px]'>
                {allMedia && allMedia?.files?.map((item) => {
                    if (!item.isDirectory) {
                        return <div  key={item?.path} className=' col-span-4 flex items-center justify-center bg-[#1e1e29] h-[80px] overflow-hidden relative group'>
                            <img onClick={() => { addImageToCanvas(`${process.env.REACT_APP_MEDIA_URL}${item.path}`) }} src={`${process.env.REACT_APP_MEDIA_URL}${item.path}`} className='max-w-full cursor-pointer max-h-full w-auto h-auto '></img>
                                <img src={delete_img} alt='delete_img' onClick={() => {setOpenDeleteModal(true)
                                    setImgPath(item.path)
                                }} className=' hidden group-hover:flex absolute top-0 right-[-2px] cursor-pointer z-40'></img>
                        </div>
                    }
                }
                )}

            </div>

            {allMedia.files?.length > 0 && allMedia.files?.length !== allMedia?.count && allMedia?.count > 25 &&
                <div onClick={() => viewMoreImage()} className="border hover:bg-[#5446d0] border-[#5446D0] text-[12px] text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium cursor-pointer max-w-[120px] mx-auto">View More</div>
            }

            {/*Element Delete Modal */}

            {openDeleteModal && <div className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}>
                <div className="w-[100%] h-[100%] relative flex justify-center items-center">
                    <div
                        onClick={() => {
                            setOpenDeleteModal(false);
                        }}
                        className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]"
                    ></div>
                    <div className="w-[400px] p-[20px] bg-[#272836] flex flex-col items-center z-[1] rounded-[8px]">
                        <img src={deleteicon} alt="deleteicon" className=" mx-auto w-[100px] h-auto object-contain"></img>
                        <p className="text-[#ABABC0] my-[20px]  font-Inter font-normal text-[14px] leading-[16.94px] text-center tracking-[0.48px]">Are you sure you want to delete this Image?</p>
                        <div className="flex justify-center items-center text-sm gap-[12px]">
                            <button onClick={() => setOpenDeleteModal(false)} className="border border-[#5446D0] text-[#fff] rounded-md px-[20px] py-[9px] text-center font-medium">Cancel</button>
                            <button onClick={() => {
                              deleteImage()
                            }} className=" bg-[#5446D0] rounded-md px-[20px] py-[10px] text-center font-semibold text-[#fff]">Delete</button>
                        </div>
                    </div>
                </ div>
            </div>}


            {/* upload image modal */}

            {uploadModal && (
                <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]">
                    <div className="w-[100%] h-[100%] relative flex justify-center items-center">
                        <div
                            onClick={() => {
                                setuploadModal(false);
                            }}
                            className="w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]"
                        ></div>
                        <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 z-[1] -translate-y-1/2 bg-[#272836] p-[20px] rounded-md font-Inter text-[#FFFFFF] w-[400px]">
                            <div className=' flex items-center justify-between'>
                                <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Upload File</h1>
                                <img src={plus} className=' cursor-pointer rotate-45' onClick={() => setuploadModal(false)}></img>
                            </div>
                            <div className="relative h-[160px] rounded cursor-pointer border-dashed border-[1px] border-[#ABABC0]  flex justify-center items-center bg-[#3B3B4F] mt-[20px]">
                                <div className="absolute">
                                    <div className="flex flex-col items-center">
                                        <img src={upload_icon} alt="Upload" />
                                        <h3 className="font-Inter font-medium text-sm text-[#FFFFFF] my-[10px]">Upload a file</h3>
                                        <span className="block font-Inter font-normal text-xs text-[#ABABC0]">Drag & drop or browse to choose a file here</span>
                                    </div>
                                </div>
                                <input onChange={(e) => { onInputHandler(e) }} type="file"   // ref={excelinputRef}
                                    name="img"
                                    accept=".jpg, .jpeg, .png" className=" cursor-pointer h-full w-full opacity-0" />
                            </div>
                            {/* <div
                                className={`mt-[10px] rounded-md relative border border-[#3B3B4F] transition-all w-[100%] flex items-center `}
                            >
                                <input
                                    onChange={(e) => {
                                        onInputHandler(e);
                                    }}
                                    // ref={excelinputRef}
                                    type="file"
                                    name="Excel"
                                    accept=".png ,.jpg , .jpeg"
                                    autoFocus
                                    placeholder="Enter Folder Name Here..."
                                    className={`absolute w-[100%] top-[0px] bottom-0 right-0 left-[0px] cursor-pointer opacity-0 rounded-md`}
                                />
                                <p className="h-[100%] text-[#ABABC0] bg-[#1e1e29]  p-[8px] w-[100%] flex flex-col  py-[50px] justify-center rounded-md items-center font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px]">
                                    <img
                                        src={excel_upload}
                                        alt="video"
                                        className="mb-[15px]"
                                    />
                                    Upload Image
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>

            )}
        </div>
    )
}

export default UploadPanel